import React, { useEffect, useState } from 'react'

import CountTestPage from './Modals/CountTestPage'
import FalseDevice from './Modals/FalseDevicePage'
import { Modal } from 'react-bootstrap'
import ContinueWorkPage from './Modals/ContinueWorkPage'
import actions from 'redux/actions'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'

const TimeTracking = () => {
	const [devicePermission, setDevicePermission] = useState<boolean | null>(null)
	const [openTestPage, setOpenTestPage] = useState<boolean>(true)
	const [openContinue, setOpenContinue] = useState<boolean>(false)

	const { t } = useTranslation()
	const dispatch = useDispatch()

	useEffect(() => {
		let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

		if (isMobile) {
			setDevicePermission(false)
			setOpenTestPage(true)

		} else {
			setDevicePermission(true)
		}

	}, [])

	const onSuccessCounting = () => {
		setOpenTestPage(false)
		setOpenContinue(true)
	}

	const onContinue = () => {
		dispatch(actions.userActions.workWithSystem())
		toast.success(t('reduxToastSuccess_greetings') as string)
		setOpenContinue(false)
	}

	return (
		<div className="bluredContainer">
			<Modal show={true} backdrop={'static'} centered>
				{devicePermission === false && <FalseDevice />}
				{devicePermission && openTestPage && (
					<CountTestPage onSuccessCounting={onSuccessCounting} />
				)}
				{openContinue && <ContinueWorkPage onContinue={onContinue} />}
			</Modal>
		</div>
	)
}

export default TimeTracking
