import {
	createConceptVisitCheck,
	createProjectVisitCheck,
	fixProjectVisits,
	loadVisits
} from 'api/request'

import { toast } from 'react-toastify'
import actions from 'redux/actions'
import { t } from 'i18next'
import { ConceptVisitCreationData, FixCheckData, ProjectVisitCreationData } from 'types/visitCheck'

export const loadVisitsFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.visits.loadVisitsRequest())
	try {
		const loadedVisits = await loadVisits()
		return dispatch(actions.dataActions.visits.loadVisitsSuccess(loadedVisits))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.visits.loadVisitsError())
	}
}

export const createProjectVisitCheckFunc =
	(checkInfo: ProjectVisitCreationData, project: string) => async (dispatch) => {
		dispatch(actions.dataActions.visits.createProjectVisitCheckRequest())
		try {
			const updatedVisits = await createProjectVisitCheck(checkInfo)

			toast.success(t(`CheckVisitModal_${checkInfo.position}`) as string)
			return dispatch(
				actions.dataActions.visits.createProjectVisitCheckSuccess({
					visits: updatedVisits,
					project: project
				})
			)
		} catch (error: any) {
			toast.error(error.message)
			return dispatch(actions.dataActions.visits.createProjectVisitCheckError())
		}
	}

export const createConceptVisitCheckFunc =
	(checkInfo: ConceptVisitCreationData, concept: string) => async (dispatch) => {
		dispatch(actions.dataActions.visits.createConceptVisitCheckRequest())
		try {
			const updatedVisits = await createConceptVisitCheck(checkInfo)

			toast.success(t('CheckVisitModal_producer') as string)
			return dispatch(
				actions.dataActions.visits.createConceptVisitCheckSuccess({
					visits: updatedVisits,
					concept: concept
				})
			)
		} catch (error: any) {
			toast.error(error.response.data.message)
			return dispatch(actions.dataActions.visits.createConceptVisitCheckError())
		}
	}

export const fixProjectVisitsFunc = (fixCheckData: FixCheckData) => async (dispatch) => {
	dispatch(actions.dataActions.visits.fixProjectVisitsRequest())
	try {
		const fixedVisits = await fixProjectVisits(fixCheckData)

		toast.success(t(`reduxToastSuccess_fixSuccess`) as string)
		return dispatch(
			actions.dataActions.visits.fixProjectVisitsSuccess({
				visits: fixedVisits,
				project: fixCheckData.project_id
			})
		)
	} catch (error: any) {
		toast.error(error.message)
		return dispatch(actions.dataActions.visits.createProjectVisitCheckError())
	}
}
