/**
 * Rounds a number to a specified number of decimal places.
 * @param {number} number - The number to round.
 * @param {number} digits - The number of decimal places.
 * @returns {number} The rounded number.
 */
export function roundTo(number: number, digits: number): number {
	if (digits < 0) return number;

	const multiplier = 10 ** digits;
	return Math.round(number * multiplier) / multiplier;
}

/**
 * Formats a number with a thousands separator.
 * @param {number} number - The number to format.
 * @param {string} [separator=','] - The separator to use.
 * @returns {string} The formatted number.
 */
export function formatNumber(number: number, separator: string = ','): string {
	return number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${separator}`);
}
