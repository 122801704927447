import { createAction } from '@reduxjs/toolkit'
import * as types from '../../constants'
import Promotion from 'types/promotion'

export const loadPromotionsRequest = createAction(types.LOAD_PROMOTIONS_REQUEST)
export const loadPromotionsSuccess = createAction<Promotion[]>(types.LOAD_PROMOTIONS_SUCCESS)
export const loadPromotionsError = createAction(types.LOAD_PROMOTIONS_ERROR)

export const loadEmployeePromotionsRequest = createAction(types.LOAD_EMPLOYEE_PROMOTIONS_REQUEST)
export const loadEmployeePromotionsSuccess = createAction<Promotion[]>(
	types.LOAD_EMPLOYEE_PROMOTIONS_SUCCESS
)
export const loadEmployeePromotionsError = createAction(types.LOAD_EMPLOYEE_PROMOTIONS_ERROR)

export const createPromotionRequest = createAction(types.CREATE_PROMOTION_REQUEST)
export const createPromotionSuccess = createAction<Promotion>(types.CREATE_PROMOTION_SUCCESS)
export const createPromotionError = createAction(types.CREATE_PROMOTION_ERROR)

export const repeatPromotionRequest = createAction(types.REPEAT_PROMOTION_REQUEST)
export const repeatPromotionSuccess = createAction<Promotion>(types.REPEAT_PROMOTION_SUCCESS)
export const repeatPromotionError = createAction(types.REPEAT_PROMOTION_ERROR)

export const agreePromotionRequest = createAction(types.AGREE_PROMOTION_REQUEST)
export const agreePromotionSuccess = createAction<Promotion>(types.AGREE_PROMOTION_SUCCESS)
export const agreePromotionError = createAction(types.AGREE_PROMOTION_ERROR)

export const rejectPromotionRequest = createAction(types.REJECT_PROMOTION_REQUEST)
export const rejectPromotionSuccess = createAction<Promotion>(types.REJECT_PROMOTION_SUCCESS)
export const rejectPromotionError = createAction(types.REJECT_PROMOTION_ERROR)

export const sendRequestPromotionRequest = createAction(types.SEND_REQUEST_PROMOTION_REQUEST)
export const sendRequestPromotionSuccess = createAction<Promotion>(
	types.SEND_REQUEST_PROMOTION_SUCCESS
)
export const sendRequestPromotionError = createAction(types.SEND_REQUEST_PROMOTION_ERROR)

export const deletePromotionRequest = createAction(types.DELETE_PROMOTION_REQUEST)
export const deletePromotionSuccess = createAction<string>(types.DELETE_PROMOTION_SUCCESS)
export const deletePromotionError = createAction(types.DELETE_PROMOTION_ERROR)
