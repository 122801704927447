import { useEffect } from 'react';

/**
 * Custom hook to set the page title.
 * @param {string} title - The title to set for the page.
 */

const usePageTitle = (title: string) => {
	useEffect(() => {
		// Update the document title when the component mounts or when the title changes
		document.title = title;
	}, [title]);
};

export default usePageTitle;