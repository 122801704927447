import React, { useState, useCallback, useEffect, createContext, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Badge, Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FaArchive, FaChevronRight, FaEdit, FaPlus, FaTrashAlt, FaCheck, FaTimes } from 'react-icons/fa'
import { FaArrowRotateLeft } from 'react-icons/fa6'

export interface ListItem {
	_id: string;
	name?: string;
	enName?: string;
	ukName?: string;
	isArchived?: boolean;
	isNew?: boolean;
	children?: ListItem[];
	isOpen?: boolean;
}

const colorPallets = [
	['#f8d7da', '#d1e7dd', '#cfe2ff', '#fff3cd'],
	['#FFAD84', '#FFC47E', '#FFE382', '#FFF78A'],
	['#87A2FF', '#a5d6ff', '#C4D7FF', '#eff6ff'],
	['#c4e7ff', '#d4eaff', '#e5f2ff', '#f0f6fd'],
	['#CB9DF0', '#F0C1E1', '#FDDBBB', '#FFF9BF'],
	['#9dc2f0', '#cb9df0', '#f0cb9d', '#c2f09d'],
	['#cfffec', '#cfe2ff', '#ffcfe2', '#ffeccf'],
	['#ffcdd9', '#fff3cd', '#cdfff3', '#cdd9ff'],
	['#f5d7f8', '#f8d7da', '#daf8d7', '#d7f8f5'],
]

interface NestedListContextType {
	selectedItemId: string | null;
	editingItemId: string | null;
	showChildrenCount: boolean;
	useTranslations: boolean;
	useArchived: boolean;
	showArchived: boolean;
	handleSelectItem: (id: string) => void;
	handleToggle: (id: string) => void;
	handleRename: (id: string, newValues: Partial<ListItem>) => void;
	setEditingItemId: (id: string | null) => void;
	handleAddChild: (id: string) => void;
	handleArchiveToggle: (id: string) => void;
	handleDelete: (id: string) => void;
	colorSchema: number;
}

const NestedListContext = createContext<NestedListContextType | undefined>(undefined);

function useNestedListContext() {
	const context = useContext(NestedListContext);
	if (!context) {
		throw new Error('useNestedListContext must be used within a NestedListProvider');
	}
	return context;
}

function EditForm({ item }: { item: ListItem }) {
	const { useTranslations, handleRename, setEditingItemId } = useNestedListContext();
	const [editName, setEditName] = useState(item.name || "");
	const [editEnValue, setEditEnValue] = useState(item.enName || "");
	const [editUkValue, setEditUkValue] = useState(item.ukName || "");

	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault();
		handleRename(item._id, useTranslations ? { enName: editEnValue, ukName: editUkValue } : { name: editName });
	};

	return (
		<Form className="flex-grow-1 d-flex gap-2" onSubmit={handleSubmit} onClick={(e) => e.stopPropagation()}>
			{useTranslations ? (
				<>
					<div className='mr-2 align-content-center justifyCenter'>UK</div>
					<Form.Control
						type="text"
						value={editUkValue}
						onChange={(e) => setEditUkValue(e.target.value)}
						placeholder="Ukrainian"
						className="mr-2"
						style={{height: "28px", width: "45%"}}
					/>
					<div className='mr-2 align-content-center justifyCenter'>EN</div>
					<Form.Control
						type="text"
						value={editEnValue}
						onChange={(e) => setEditEnValue(e.target.value)}
						placeholder="English"
						autoFocus
						className="mr-2"
						style={{height: "28px", width: "45%"}}
					/>
				</>
			) : (
				<Form.Control
					type="text"
					value={editName}
					onChange={(e) => setEditName(e.target.value)}
					autoFocus
					className="mr-2"
					style={{height: "28px"}}
				/>
			)}
			<Button
				type="submit"
				size="sm"
				variant="success"
				className="d-flex align-items-center justify-content-center p-1 mr-2"
				style={{ width: "28px", height: "28px", borderRadius: "4px" }}
			>
				<FaCheck size={24} />
			</Button>
			<Button
				type="button"
				variant="danger"
				size="sm"
				className="d-flex align-items-center justify-content-center p-1"
				style={{ width: "28px", height: "28px", borderRadius: "4px" }}
				onClick={() => setEditingItemId(null)}
			>
				<FaTimes size={24} />
			</Button>
		</Form>
	);
}

function ItemContent({ item, isEditing }: { item: ListItem; isEditing: boolean }) {
	const { useTranslations } = useNestedListContext();
	const { i18n } = useTranslation();

	if (isEditing) {
		return <EditForm item={item} />;
	}

	return (
		<div className="d-flex align-items-center gap-2 flex-grow-1 ml-2">
      <span>
        {useTranslations ? (i18n.language === 'uk' ? item.ukName : item.enName) : item.name}
      </span>
		</div>
	);
}

function ItemActions({ item, level, parentArchived }: { item: ListItem; level: number; parentArchived: boolean }) {
	const { t } = useTranslation();
	const { useArchived, handleArchiveToggle, handleDelete, editingItemId, setEditingItemId, handleAddChild } = useNestedListContext();
	const isEditing = editingItemId === item._id;

	const renderTooltip = (content: string) => (props: any) => (
		<Tooltip id={`tooltip-${item._id}`} className={'custom-tooltip top'} {...props}>
			{content}
		</Tooltip>
	);

	return (
		<>
			{!item.isArchived && level < 4 && !isEditing && (
				<OverlayTrigger placement="top" overlay={renderTooltip(t('add_child'))}>
					<Button
						variant="outline-success"
						size="sm"
						className="d-flex align-items-center justify-content-center p-1"
						style={{ width: "28px", height: "28px", borderRadius: "4px" }}
						onClick={(e) => {
							e.stopPropagation();
							handleAddChild(item._id);
						}}
						aria-label={t('add_child')}
					>
						<FaPlus size={18} />
					</Button>
				</OverlayTrigger>
			)}

			{!item.isArchived && !isEditing && (
				<OverlayTrigger placement="top" overlay={renderTooltip(t('rename'))}>
					<Button
						variant="outline-primary"
						size="sm"
						className="d-flex align-items-center justify-content-center p-1"
						style={{ width: "28px", height: "28px", borderRadius: "4px" }}
						onClick={(e) => {
							e.stopPropagation();
							setEditingItemId(item._id);
						}}
						aria-label={t('rename')}
					>
						<FaEdit size={18} />
					</Button>
				</OverlayTrigger>
			)}

			{useArchived && !item.isNew && !(item.isArchived && parentArchived) && (
				<OverlayTrigger placement="top" overlay={renderTooltip(item.isArchived ? t('unarchive') : t('archive'))}>
					<Button
						variant={item.isArchived ? "outline-success" : "outline-danger"}
						size="sm"
						className="d-flex align-items-center justify-content-center p-1"
						style={{ width: "28px", height: "28px", borderRadius: "4px" }}
						onClick={(e) => {
							e.stopPropagation();
							handleArchiveToggle(item._id);
						}}
						aria-label={item.isArchived ? t('unarchive') : t('archive')}
					>
						{item.isArchived ? <FaArrowRotateLeft size={18} /> : <FaArchive size={24} />}
					</Button>
				</OverlayTrigger>
			)}

			{(item.isNew || !useArchived) && (
				<OverlayTrigger placement="top" overlay={renderTooltip(t('delete'))}>
					<Button
						variant="outline-danger"
						size="sm"
						className="d-flex align-items-center justify-content-center p-1"
						style={{ width: "28px", height: "28px", borderRadius: "4px" }}
						onClick={(e) => {
							e.stopPropagation();
							handleDelete(item._id);
						}}
						aria-label={t('delete')}
					>
						<FaTrashAlt size={18} />
					</Button>
				</OverlayTrigger>
			)}
		</>
	);
}

function ListItemElement({ item, level, parentArchived }: { item: ListItem; level: number; parentArchived: boolean }) {
	const { selectedItemId, editingItemId, showChildrenCount, showArchived, handleSelectItem, handleToggle, colorSchema } = useNestedListContext();
	const isSelected = selectedItemId === item._id;
	const isEditing = editingItemId === item._id;
	const childrenCount = item.children?.length || 0;

	const colorIndex = colorSchema < colorPallets.length ? colorSchema : 0
	const backgroundColor = item.isArchived ? '#e9ecef' : (colorPallets[colorIndex][level - 1] || '#cfe2ff');

	if (!showArchived && item.isArchived) return null;

	return (
		<li key={item._id} className="mb-2 list-unstyled">
			<div
				className={`d-flex align-items-center rounded p-2`}
				style={{
					cursor: 'pointer',
					height: '40px',
					border: '1px solid #dee2e6',
					backgroundColor: isSelected ? `${backgroundColor}CC` : backgroundColor,
					opacity: isSelected ? 0.8 : 1,
				}}
				onClick={() => handleSelectItem(item._id)}
			>
				<div className="d-flex align-items-center gap-2 flex-grow-1">
					{item.children && item.children.length > 0 && (
						<Button
							variant="ghost"
							size="sm"
							className="d-flex align-items-center justify-content-center p-1 me-2"
							style={{ width: "28px", height: "28px" }}
							onClick={(e) => {
								e.stopPropagation()
								handleToggle(item._id)
							}}
							aria-expanded={item.isOpen}
							aria-label={item.isOpen ? "Collapse" : "Expand"}
						>
							<FaChevronRight size={12} style={{ transform: item.isOpen ? 'rotate(90deg)' : 'none' }} />
						</Button>
					)}
					<ItemContent item={item} isEditing={isEditing} />
				</div>
				<div className="d-flex" style={{gap: "8px", marginLeft: "8px"}}>
					{isSelected && <ItemActions item={item} level={level} parentArchived={parentArchived} />}
					{!isSelected && showChildrenCount && childrenCount > 0 && (
						<Badge
							bg="ghost"
							className="d-flex align-items-center justify-content-center"
							style={{ width: "28px", height: "28px", borderRadius: "4px", padding: "0", color: '#495057' }}
						>
							{childrenCount}
						</Badge>
					)}
				</div>
			</div>
			{item.children && item.children.length > 0 && item.isOpen && (
				<ul className="ps-4 mt-2">
					{item.children.map((child) => (
						<ListItemElement
							key={child._id}
							item={child}
							level={level + 1}
							parentArchived={item.isArchived || parentArchived}
						/>
					))}
				</ul>
			)}
		</li>
	)
}

interface NestedListProps {
	showChildrenCount?: boolean;
	initialData: ListItem[];
	onDataChange: (data: ListItem[]) => void;
	useTranslations?: boolean;
	useArchived?: boolean;
	colorSchema?: number;
}

export default function NestedList({
									   showChildrenCount = true,
									   initialData = [],
									   onDataChange,
									   useTranslations = false,
									   useArchived = false,
									   colorSchema = 0
								   }: NestedListProps) {
	const { t } = useTranslation()

	const [data, setData] = useState<ListItem[]>(() => initialData);
	const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
	const [editingItemId, setEditingItemId] = useState<string | null>(null);
	const [isAllExpanded, setIsAllExpanded] = useState(false);
	const [showArchived, setShowArchived] = useState(false)

	useEffect(() => {
		if (selectedItemId !== editingItemId) {
			setEditingItemId(null)
		}
	}, [selectedItemId, editingItemId])

	const updateItem = useCallback((items: ListItem[], itemId: string, updateFn: (item: ListItem) => ListItem): ListItem[] => {
		return items.map((item) => {
			if (item._id === itemId) {
				return updateFn(item)
			}
			if (item.children) {
				return { ...item, children: updateItem(item.children, itemId, updateFn) }
			}
			return item
		})
	}, [])

	const deleteItem = useCallback((items: ListItem[], itemId: string): ListItem[] => {
		return items.filter((item) => {
			if  (item._id === itemId) {
				return false
			}
			if (item.children) {
				item.children = deleteItem(item.children, itemId)
			}
			return true
		})
	}, [])

	const handleRename = useCallback((itemId: string, newValues: Partial<ListItem>) => {
		setData((prevData) => {
			const newData = updateItem(prevData, itemId, (item) => ({
				...item,
				...newValues,
			}));
			onDataChange(newData);
			return newData;
		});
		setEditingItemId(null);
	}, [updateItem, onDataChange]);

	const handleToggle = useCallback((itemId: string) => {
		setData((prevData) =>
			updateItem(prevData, itemId, (item) => ({
				...item,
				isOpen: !item.isOpen,
			}))
		)
	}, [updateItem])

	const handleAddChild = useCallback((parentId: string) => {
		const newId = `${parentId}-${Date.now()}`
		setData((prevData) => {
			const newData = updateItem(prevData, parentId, (item) => ({
				...item,
				isOpen: true,
				children:
					[...(item.children || []), (useTranslations ?
						{ _id: newId, enName: "New Item", ukName: 'Новий пункт', isNew: true } :
						{ _id: newId, name: "New Item", isNew: true })],
			}))
			onDataChange(newData)
			return newData
		})
		setSelectedItemId(newId)
	}, [updateItem, useTranslations, onDataChange])

	const toggleAllItems = () => {
		setIsAllExpanded(prevExpanded => {
			const newExpanded = !prevExpanded
			setData(prevData => {
				const toggleItems = (items: ListItem[]): ListItem[] => {
					return items.map(item => ({
						...item,
						isOpen: newExpanded,
						children: item.children ? toggleItems(item.children) : undefined
					}))
				}
				return toggleItems(prevData)
			})
			return newExpanded
		})
	}

	const handleSelectItem = useCallback((id: string) => {
		if (selectedItemId === id) {
			setSelectedItemId(null)
		} else setSelectedItemId(id)
	}, [selectedItemId])

	const findItem = useCallback((items: ListItem[], itemId: string): ListItem | null => {
		for (const item of items) {
			if (item._id === itemId) {
				return item;
			}
			if (item.children) {
				const found = findItem(item.children, itemId);
				if (found) return found;
			}
		}
		return null;
	}, []);

	const handleArchiveToggle = useCallback((itemId: string) => {
		if (!useArchived) return;

		const archiveRecursively = (item: ListItem, newArchiveState: boolean): ListItem => {
			return {
				...item,
				isArchived: newArchiveState,
				children: item.children?.map(child => archiveRecursively(child, newArchiveState))
			};
		};

		setData((prevData) => {
			const newData = updateItem(prevData, itemId, (item) => {
				if (item.isNew) {
					return item; // New items can't be archived
				}
				const newArchiveState = !item.isArchived;
				return archiveRecursively(item, newArchiveState);
			});
			onDataChange(newData);
			return newData;
		});
	}, [updateItem, useArchived, onDataChange]);

	const handleDelete = useCallback((itemId: string) => {
		setData((prevData) => {
			if (useArchived) {
				const itemToDelete = findItem(prevData, itemId);
				if (!itemToDelete) return prevData;

				if (!itemToDelete.isArchived && !itemToDelete.isNew) {
					alert("Cannot delete item that is not archived and not new.");
					return prevData;
				}
			}

			const newData = deleteItem(prevData, itemId);
			onDataChange(newData);
			return newData;
		});
		setSelectedItemId(null);
	}, [deleteItem, findItem, useArchived, onDataChange]);

	const contextValue: NestedListContextType = {
		selectedItemId,
		editingItemId,
		showChildrenCount,
		useTranslations,
		useArchived,
		showArchived,
		handleSelectItem,
		handleToggle,
		handleRename,
		setEditingItemId,
		handleAddChild,
		handleArchiveToggle,
		handleDelete,
		colorSchema
	};

	return (
		<NestedListContext.Provider value={contextValue}>
			<div className="p-4">
				<div className="d-flex justify-content-start align-items-center mb-3">
					<Form.Check
						type="checkbox"
						id="expand-all-checkbox"
						label={''}
						checked={isAllExpanded}
						onChange={toggleAllItems}
						className='ml-2'
						style={{
							transform: 'scale(1.3)',
						}}
					/>
					<div>{isAllExpanded ? t('Checkbox_collapse') : t('Checkbox_expand')}</div>
					{useArchived && (
						<>
							<Form.Check
								type="checkbox"
								id="show-archived-checkbox"
								label={''}
								checked={showArchived}
								onChange={(e) => setShowArchived(e.target.checked)}
								className='ml-3'
								style={{
									transform: 'scale(1.3)',
								}}
							/>
							<div>{showArchived ? t('archived_hide') : t('archived_show')}</div>
						</>
					)}
				</div>
				{data.length > 0 ? (
					<ul className="list-unstyled mb-4">
						{data.map((item) => (
							<ListItemElement
								key={item._id}
								item={item}
								level={1}
								parentArchived={false}
							/>
						))}
					</ul>
				) : (
					<h3 className='mx-1 my-4'>{t('no_items_to_display')}</h3>
				)}
			</div>
		</NestedListContext.Provider>
	)
}

