import { createTrainingProjects, endTrainingProjects, getTrainingProjects } from 'api/request'

import { toast } from 'react-toastify'
import actions from 'redux/actions'
import { t } from 'i18next'
import TrainingProject, {
	TrainingProjectCreationData,
	TrainingProjectEndData
} from 'types/trraining.project'

export const loadTrainingProjectsFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.trainingProjects.loadTrainingProjectsRequest())
	try {
		const loadedTrainingProjects: TrainingProject[] = await getTrainingProjects()
		return dispatch(
			actions.dataActions.trainingProjects.loadTrainingProjectsSuccess(loadedTrainingProjects)
		)
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.trainingProjects.loadTrainingProjectsError())
	}
}

export const createTrainingProjectFunc =
	(creationData: TrainingProjectCreationData) => async (dispatch) => {
		dispatch(actions.dataActions.trainingProjects.createTrainingProjectRequest())
		try {
			const createdTrainingProject = await createTrainingProjects(creationData)
			toast.success(t('reduxToastSuccess_trainingProjectCreated') as string)
			return dispatch(
				actions.dataActions.trainingProjects.createTrainingProjectSuccess(
					createdTrainingProject
				)
			)
		} catch (error: any) {
			toast.error(error.response.data.message)
			return dispatch(actions.dataActions.trainingProjects.createTrainingProjectError())
		}
	}

export const endTrainingProjectFunc = (endData: TrainingProjectEndData) => async (dispatch) => {
	dispatch(actions.dataActions.trainingProjects.endTrainingProjectRequest())
	try {
		const endedTrainingProject = await endTrainingProjects(endData)
		toast.success(t('reduxToastSuccess_trainingProjectEnded') as string)
		return dispatch(
			actions.dataActions.trainingProjects.endTrainingProjectSuccess(endedTrainingProject)
		)
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.trainingProjects.endTrainingProjectError())
	}
}
