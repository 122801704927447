import React from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'react-bootstrap';
import { IconType } from 'react-icons';

type SidebarCategoryProps = {
  name: string;
  icon: IconType;
  isOpen: boolean;
  children: React.ReactNode;
  onClick: () => void;
  to: string;
  isActive: boolean;
};

export const SidebarCategory: React.FC<SidebarCategoryProps> = React.memo(({ name, icon: Icon, isOpen, children, onClick, isActive }) => {
  const { t } = useTranslation();

  return (
    <li className={`sidebar-item ${isActive ? 'active' : ''}`}>
      <span
        data-toggle="collapse"
        className={`sidebar-link ${!isOpen ? 'collapsed' : ''}`}
        onClick={onClick}
        aria-expanded={isOpen}
      >
        <Icon size={18} className="align-middle mr-3" />
        <span className="align-middle">{t(name)}</span>
      </span>
      <Collapse in={isOpen}>
        <ul className="sidebar-dropdown list-unstyled">
          {children}
        </ul>
      </Collapse>
    </li>
  );
});

