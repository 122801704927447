import React from 'react'
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { FaSyncAlt } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import selectors from 'redux/selectors'
import operations from 'redux/operations'

export enum ArchivedEntitiesEnum {
	concepts = 'concepts',
	ideas = 'ideas'
}

function LoadAchivedButton(props: { entities: ArchivedEntitiesEnum }) {
	const { t } = useTranslation()
	const dispatch = useDispatch()
	const loading = useSelector(selectors.getLoading)

	const load = () => {
		switch (props.entities) {
			case ArchivedEntitiesEnum.concepts:
				return dispatch(operations.dataOperations.concepts.getAllArchivedConceptsFunc())

			case ArchivedEntitiesEnum.ideas:
				return dispatch(operations.dataOperations.ideas.loadArchivedIdeasFunc())

			default:
				return
		}
	}

	return (
		<Button variant="outline-primary" disabled={loading} onClick={load}>
			<FaSyncAlt className={`mb-1 mr-1 ${loading ? 'lest_spin' : ''}`} />{' '}
			{t('LoadAllArchivedConceptsButton_text')}
		</Button>
	)
}

export default LoadAchivedButton
