import { createAction } from '@reduxjs/toolkit'
import Visits from 'types/visitCheck'
import * as types from '../../constants'

export const loadVisitsRequest = createAction(types.LOAD_VISITS_REQUEST)
export const loadVisitsSuccess = createAction<Visits[]>(types.LOAD_VISITS_SUCCESS)
export const loadVisitsError = createAction(types.LOAD_VISITS_ERROR)

export const createProjectVisitCheckRequest = createAction(types.CREATE_PROJECT_VISIT_CHECK_REQUEST)
export const createProjectVisitCheckSuccess = createAction<{ visits: Visits; project: string }>(
	types.CREATE_PROJECT_VISIT_CHECK_SUCCESS
)
export const createProjectVisitCheckError = createAction(types.CREATE_PROJECT_VISIT_CHECK_ERROR)

export const createConceptVisitCheckRequest = createAction(types.CREATE_CONCEPT_VISIT_CHECK_REQUEST)
export const createConceptVisitCheckSuccess = createAction<{ visits: Visits; concept: string }>(
	types.CREATE_CONCEPT_VISIT_CHECK_SUCCESS
)
export const createConceptVisitCheckError = createAction(types.CREATE_CONCEPT_VISIT_CHECK_ERROR)

export const fixProjectVisitsRequest = createAction(types.FIX_PROJECT_VISITS_REQUEST)
export const fixProjectVisitsSuccess = createAction<{ visits: Visits; project: string }>(
	types.FIX_PROJECT_VISITS_SUCCESS
)
export const fixProjectVisitsError = createAction(types.FIX_PROJECT_VISITS_ERROR)
