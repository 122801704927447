import type {BaseQueryFn} from "@reduxjs/toolkit/query";
import type {AxiosError, AxiosRequestConfig} from "axios";
import api from './axios'

export const axiosBaseQuery =
    ({ baseUrl = '' }: { baseUrl?: string } = {}): BaseQueryFn<
        {
            url: string;
            method?: AxiosRequestConfig['method'];
            data?: AxiosRequestConfig['data'];
            params?: AxiosRequestConfig['params'];
            headers?: AxiosRequestConfig['headers'];
            download?: boolean;
        },
        unknown,
        unknown
    > =>
        async ({ url, method = 'GET', data, params, headers, download = false }) => {
            try {
                const axiosInstance = api(download);
                const result = await axiosInstance({
                    url: baseUrl + url,
                    method,
                    data,
                    params,
                    headers,
                });
                return { data: result.data };
            } catch (error) {
                const axiosError = error as AxiosError;
                return {
                    error: {
                        status: axiosError.response?.status,
                        data: axiosError.response?.data || axiosError.message,
                    },
                };
            }
        };