import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IconType } from 'react-icons/lib'
import { RolesEnum } from 'types/roles'
import { PositionsEnum } from 'types/positions'
import { Button, Modal, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Placement } from 'react-bootstrap/types'
import RoleWrapper from './RoleWrapper'
import PositionWrapper from './PositionWrapper'

interface ModalViewProps {
	className?: string

	title: any
	titleExtendet?: any
	children?: any
	size?: 'sm' | 'lg' | 'xl'

	noLeftMargin?: boolean // 1
	noButton?: boolean // 14 (all finance)
	show?: boolean // my
	setShow?: (state: boolean) => void // my

	requaredRoles?: RolesEnum[]
	requaredPositions?: PositionsEnum[] // 1 AddDirectExpenseModal

	buttonText: string
	tooltipText?: string
	tooltipPlacement?: Placement // wll be usefull
	id?: string
	numberButtonSize?: number // 0
	buttonSize?: 'sm' | 'lg' // 2 lg 3 sm
	buttonIcon?: IconType
	buttonColor?: 'primary' | 'success' | 'warning' | 'danger' | string
	buttonOutline?: boolean // 2
	buttonSmall?: boolean // 11
	buttonAsText?: boolean // 2

	acceptButtonText?: string
	acceptButtonColor?: 'primary' | 'success' | 'warning' | 'danger' | string
	onAccept?: any
	deleteModal?: boolean // 8
	clearFunction?: any

	rejectButtonText?: string
	onReject?: any // 2
	rejectButtonColor?: 'primary' | 'success' | 'warning' | 'danger' | string // 3

	disabled?: boolean // 1
}

const ModalView = (props: ModalViewProps) => {
	const [isOpen, setIsOpen] = useState(false)
	const { t, i18n } = useTranslation()

	const toggle = (e) => {
		e.persist()
		e.preventDefault()

		if (props.setShow) {
			props.setShow(!isOpen);  // External toggle control
		} else {
			setIsOpen(!isOpen);  // Internal toggle control
		}
	}

	const onAccept = async (e) => {
		if (!props.onAccept) {
			toggle(e)

			return
		}

		e.persist()
		e.preventDefault()

		const success = await props.onAccept()

		if (props.deleteModal) {
			return
		}

		if (success) {
			toggle(e)
		}
	}

	const onReject = async (e) => {
		if (!props.onReject) {
			toggle(e)

			return
		}

		e.persist()
		e.preventDefault()

		const success = await props.onReject()

		if (props.deleteModal) {
			return
		}

		if (success) {
			toggle(e)
		}
	}

	const handleClose = () => {
		if (props.setShow) {
			props.setShow(false); // Close using external control
		} else {
			setIsOpen(false); // Default close action
		}
	};

	useEffect(() => {
		if (props.show && props.show !== isOpen) {
			setIsOpen(props.show);
		}
	}, [props.show, isOpen]);

	const tooltipId = 'Tooltip' + (props.tooltipText ?? '') + (props.id ?? '0')

	const linkItem =
		props.buttonAsText && !props.noButton ? (
			<a
				href="#/"
				className={`m-1 mb-2 text-${props.buttonColor ?? 'primary'} ${props.className}`}
				id={tooltipId}
				onClick={(e) => toggle(e)}
			>
				{props.buttonIcon && (
					<props.buttonIcon
						className={props.buttonSmall ? '' : 'mr-2'}
						size={props.numberButtonSize}
					/>
				)}
				{!props.buttonSmall && t(props.buttonText)}
			</a>
		) : (
			<></>
		)

	const buttonItem =
		!props.buttonAsText && !props.noButton ? (
			<Button
				id={tooltipId}
				variant={(props.buttonOutline ? 'outline-' : '') + (props.buttonColor ?? 'primary')}
				disabled={props.disabled}
				className={`shadow-sm mb-0 ${props.noLeftMargin ? '' : 'ml-2'} ${props.className}`}
				onClick={toggle}
				size={props.buttonSize}
			>
				{props.buttonIcon && (
					<props.buttonIcon
						className={props.buttonSmall ? '' : 'mr-2'}
						size={props.numberButtonSize}
					/>
				)}
				{!props.buttonSmall && (
					<span>
						{i18n.exists(props.buttonText) ? t(props.buttonText) : props.buttonText}
					</span>
				)}
			</Button>
		) : (
			<></>
		)

	const modalView = (
		<>
			{props.tooltipText ? (
				<OverlayTrigger
					overlay={
						<Tooltip
							id={tooltipId}
							className={`custom-tooltip ${props.tooltipPlacement ?? 'left'}`}
						>
							{t(props.tooltipText)}
						</Tooltip>
					}
					placement={props.tooltipPlacement ?? 'left'}
				>
					{buttonItem}
				</OverlayTrigger>
			) : (
				buttonItem
			)}

			{props.tooltipText ? (
				<OverlayTrigger
					overlay={
						<Tooltip
							id={tooltipId}
							className={`custom-tooltip ${props.tooltipPlacement ?? 'left'}`}
						>
							{t(props.tooltipText)}
						</Tooltip>
					}
					placement={props.tooltipPlacement ?? 'left'}
				>
					{linkItem}
				</OverlayTrigger>
			) : (
				linkItem
			)}

			<Modal
				show={isOpen}
				onHide={handleClose}
				centered
				size={props.size}
				onExited={props.clearFunction}
			>
				<Modal.Header closeButton closeLabel="Close">
					<div className="d-flex align-items-start w-100 pe-4">
						<div>{i18n.exists(props.title) ? t(props.title) : props.title}</div>
						{props.titleExtendet}
					</div>
				</Modal.Header>

				{props.children && <Modal.Body className="m-1">{props.children}</Modal.Body>}

				{(props.rejectButtonText || props.acceptButtonText) && (
					<Modal.Footer>
						{props.rejectButtonText && (
							<Button
								variant={`${props.rejectButtonColor ?? 'secondary'}`}
								onClick={onReject}
							>
								{t(props.rejectButtonText)}
							</Button>
						)}{' '}
						{props.acceptButtonText && (
							<Button
								variant={`${props.acceptButtonColor ?? 'primary'}`}
								onClick={onAccept}
							>
								{t(props.acceptButtonText)}
							</Button>
						)}
					</Modal.Footer>
				)}
			</Modal>
		</>
	)

	if (props.requaredRoles)
		return <RoleWrapper requared_role={props.requaredRoles}>{modalView}</RoleWrapper>

	if (props.requaredPositions)
		return (
			<PositionWrapper requared_positions={props.requaredPositions}>
				{modalView}
			</PositionWrapper>
		)

	return <>{modalView}</>
}

export default ModalView
