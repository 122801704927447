import {
	getAllSubscriptions,
	createSubscription,
	updateSubscription,
	archiveSubscription,
	deleteSubscription,
	unarchiveSubscription
} from 'api/request'

import { toast } from 'react-toastify'
import actions from 'redux/actions'
import { t } from 'i18next'
import { CreateSubscriptionData, Subscription, UpdateSubscriptionData } from 'types/subscription'

export const loadSubscriptionsFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.subscriptions.loadSubscriptionsRequest())
	try {
		const subscriptions: Subscription[] = await getAllSubscriptions()
		dispatch(actions.dataActions.subscriptions.loadSubscriptionsSuccess(subscriptions))
	} catch (error: any) {
		toast.error(error.response.data.message)
		dispatch(actions.dataActions.subscriptions.loadSubscriptionsError())
	}
}

export const createNewSubscriptionFunc =
	(creationData: CreateSubscriptionData) => async (dispatch) => {
		dispatch(actions.dataActions.subscriptions.createSubscriptionRequest())
		try {
			const newSubscription: Subscription = await createSubscription(creationData)
			toast.success(t('reduxToastSuccess_subscriptionCreated') as string)
			dispatch(actions.dataActions.subscriptions.createSubscriptionSuccess(newSubscription))
		} catch (error: any) {
			toast.error(error.response.data.message)
			dispatch(actions.dataActions.subscriptions.createSubscriptionError())
		}
	}

export const updateSubscriptionFunc =
	(updatingData: UpdateSubscriptionData) => async (dispatch) => {
		dispatch(actions.dataActions.subscriptions.updateSubscriptionRequest())
		try {
			const updatedSubscription: Subscription = await updateSubscription(updatingData)
			toast.success(t('reduxToastSuccess_subscriptionUpdated') as string)
			dispatch(
				actions.dataActions.subscriptions.updateSubscriptionSuccess(updatedSubscription)
			)
		} catch (error: any) {
			toast.error(error.response.data.message)
			dispatch(actions.dataActions.subscriptions.updateSubscriptionError())
		}
	}

export const archiveSubscriptionFunc = (id: string) => async (dispach) => {
	dispach(actions.dataActions.subscriptions.archiveSubscriptionRequest())
	try {
		const archivedSubscription: Subscription = await archiveSubscription(id)
		toast.success(t('reduxToastSuccess_subscriptionArchived') as string)
		return dispach(
			actions.dataActions.subscriptions.archiveSubscriptionSuccess(archivedSubscription)
		)
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispach(actions.dataActions.subscriptions.archiveSubscriptionError())
	}
}

export const unarchiveSubscriptionFunc = (id: string) => async (dispach) => {
	dispach(actions.dataActions.subscriptions.unarchiveSubscriptionRequest())
	try {
		const archivedSubscription: Subscription = await unarchiveSubscription(id)
		toast.success(t('reduxToastSuccess_subscriptionUnArchived') as string)
		return dispach(
			actions.dataActions.subscriptions.unarchiveSubscriptionSuccess(archivedSubscription)
		)
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispach(actions.dataActions.subscriptions.unarchiveSubscriptionError())
	}
}

export const deleteSubscriptionFunc = (id: string) => async (dispatch) => {
	dispatch(actions.dataActions.subscriptions.deleteSubscriptionRequest())
	try {
		const deletedSubscription: Subscription = await deleteSubscription(id)
		toast.success(t('reduxToastSuccess_subscriptionDeleted') as string)
		return dispatch(
			actions.dataActions.subscriptions.deleteSubscriptionSuccess(deletedSubscription)
		)
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.subscriptions.deleteSubscriptionError())
	}
}
