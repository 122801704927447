import {
	addConcept,
	rejectConcept,
	approveConcept,
	pinConcept,
	archiveConcept,
	unarchiveConcept,
	getConcepts,
	getEmployeeConcepts,
	getAllArchivedConcepts,
	getConcept,
	getPinnedConcepts
} from 'api/request'

import { toast } from 'react-toastify'
import actions from 'redux/actions'
import { t } from 'i18next'
import Concept, { ConceptCreationData, PinnedConceptData } from 'types/concept'
import operations from '..'

export const loadConceptsFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.concepts.loadConceptsRequest())
	try {
		const loadedConcepts: Concept[] = await getConcepts()
		return dispatch(actions.dataActions.concepts.loadConceptsSuccess(loadedConcepts))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.concepts.loadConceptsError())
	}
}

export const loadEmployeeConceptsFunc = (id: string) => async (dispatch) => {
	dispatch(actions.dataActions.concepts.loadEmployeeConceptsRequest())
	try {
		const loadedConcepts: Concept[] = await getEmployeeConcepts(id)
		return dispatch(actions.dataActions.concepts.loadEmployeeConceptsSuccess(loadedConcepts))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.concepts.loadEmployeeConceptsError())
	}
}

export const loadPinnedConceptsFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.concepts.loadConceptsRequest())
	try {
		const loadedConcepts: Concept[] = await getPinnedConcepts()
		return dispatch(actions.dataActions.concepts.loadConceptsSuccess(loadedConcepts))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.concepts.loadConceptsError())
	}
}

export const getAllArchivedConceptsFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.concepts.getAllArchivedConceptsRequest())
	try {
		const archivedConcepts: Concept[] = await getAllArchivedConcepts()
		return dispatch(
			actions.dataActions.concepts.getAllArchivedConceptsSuccess(archivedConcepts)
		)
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.concepts.getAllArchivedConceptsError())
	}
}

export const getConceptFunc = (id: string) => async (dispatch) => {
	dispatch(actions.dataActions.concepts.getConceptRequest())
	try {
		const concept = await getConcept(id)
		return dispatch(actions.dataActions.concepts.getConceptSuccess(concept))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.concepts.getConceptError())
	}
}

export const createConceptFunc = (creationData: ConceptCreationData) => async (dispatch) => {
	dispatch(actions.dataActions.concepts.createConceptRequest())
	try {
		const createdConcept: Concept = await addConcept(creationData)
		if (creationData.idea) {
			dispatch(operations.dataOperations.ideas.useIdeaFunc(creationData.idea))
		}
		toast.success(t('reduxToastSuccess_conceptCreated', { name: creationData.name }) as string)
		return dispatch(actions.dataActions.concepts.createConceptSuccess(createdConcept))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.concepts.createConceptError())
	}
}

export const rejectConceptFunc = (id) => async (dispatch) => {
	dispatch(actions.dataActions.concepts.rejectConceptRequest())
	try {
		const rejectedConcept = await rejectConcept(id)
		toast.success(t('reduxToastSuccess_conceptRejected', { name: rejectedConcept.name }) as string)
		return dispatch(actions.dataActions.concepts.rejectConceptSuccess(rejectedConcept))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.concepts.rejectConceptError())
	}
}

export const approveConceptFunc = (id) => async (dispatch) => {
	dispatch(actions.dataActions.concepts.approveConceptRequest())
	try {
		const approvedConcept = await approveConcept(id)
		toast.success(t('reduxToastSuccess_conceptApproved', { name: approvedConcept.name }) as string)
		return dispatch(actions.dataActions.concepts.approveConceptSuccess(approvedConcept))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.concepts.approveConceptError())
	}
}

export const pinConceptFunc = (pinnedData: PinnedConceptData) => async (dispatch) => {
	dispatch(actions.dataActions.concepts.pinConceptRequest())
	try {
		const pinnedConcept = await pinConcept(pinnedData)
		toast.success(t('reduxToastSuccess_conceptPinned', { name: pinnedConcept.name }) as string)
		dispatch(actions.dataActions.concepts.pinConceptSuccess(pinnedConcept))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.concepts.pinConceptError())
	}
}

export const archiveConceptFunc = (id) => async (dispatch) => {
	dispatch(actions.dataActions.concepts.archiveConceptRequest())
	try {
		const archivedConcept: Concept = await archiveConcept(id)
		toast.success(t('reduxToastSuccess_conceptArchived', { name: archivedConcept.name }) as string)
		dispatch(actions.dataActions.concepts.archiveConceptSuccess(archivedConcept))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.concepts.archiveConceptError())
	}
}

export const unarchiveConceptFunc = (id) => async (dispatch) => {
	dispatch(actions.dataActions.concepts.unarchiveConceptRequest())
	try {
		const unarchivedConcept: Concept = await unarchiveConcept(id)
		toast.success(t('reduxToastSuccess_conceptUnarchived', { name: unarchivedConcept.name }) as string)
		return dispatch(actions.dataActions.concepts.unarchiveConceptSuccess(unarchivedConcept))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.concepts.unarchiveConceptError())
	}
}
