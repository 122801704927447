import React, { useState } from 'react'
import { Form, Modal, Button, Row, Col } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import operations from 'redux/operations'
import { useTranslation } from 'react-i18next'

const firstExpression = {
	firstNumber: Math.floor(Math.random() * 10),
	secondNumber: Math.floor(Math.random() * 10)
}

const secondExpression = {
	firstNumber: Math.floor(Math.random() * 10),
	secondNumber: Math.floor(Math.random() * 10)
}

const thirdExpression = {
	firstNumber: Math.floor(Math.random() * 10),
	secondNumber: Math.floor(Math.random() * 10),
	thirdNumber: Math.floor(Math.random() * 10)
}

const CountTestPage = (props: { onSuccessCounting }) => {
	const [firstCount, setFirstCount] = useState<number>(0)
	const [secondCount, setSecondCount] = useState<number>(0)
	const [thirdCount, setThirdCount] = useState<number>(0)
	const [offButton, setOffButton] = useState<boolean>(false)

	const { t } = useTranslation()

	const dispatch = useDispatch()

	const resultChecker = async (e) => {
		e.preventDefault()

		if (
			firstCount === firstExpression.firstNumber + firstExpression.secondNumber &&
			secondCount === secondExpression.firstNumber * secondExpression.secondNumber &&
			thirdCount ===
				thirdExpression.firstNumber +
					thirdExpression.secondNumber +
					thirdExpression.thirdNumber
		) {
			setOffButton(true)

			const result = await dispatch(operations.userOperations.checkin.addCheckinFunc())

			if (!result) {
				setOffButton(false)
				return
			}

			return props.onSuccessCounting()
		}

		toast.error(t('Wrong answer') as string)
		return
	}

	const onEnter = (e) => {
		if (e.key === 'Enter') {
			resultChecker(e)
		}
	}

	return (
		<div
			style={{
				height: '30em',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				textAlign: 'center'
			}}
			onKeyDown={onEnter}
		>
			<Modal.Header>
				<Modal.Title as='h2'>{t('CountTestPage_header')}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<Row>
					<Col sm={8}>
						<p
							className="h1"
							style={{
								fontSize: '4em',
								margin: 0
							}}
						>
							{`${firstExpression.firstNumber} + ${firstExpression.secondNumber} =`}
						</p>
					</Col>
					<Col sm={4} className="text-left">
						<Form.Control
							className="text-center"
							name={'first expression'}
							type={'number'}
							size="lg"
							autoFocus={true}
							style={{
								display: 'inline-block',
								width: '6em',
								height: '3.5em',
								marginRight: 3
							}}
							onChange={(e) => setFirstCount(Number(e.target.value))}
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={8}>
						<p
							className="h1"
							style={{
								fontSize: '4em',
								margin: 0
							}}
						>{`${secondExpression.firstNumber} * ${secondExpression.secondNumber} =`}</p>
					</Col>
					<Col sm={4} className="text-left">
						<Form.Control
							className="text-center"
							name={'second expression'}
							type={'number'}
							size="lg"
							style={{
								display: 'inline-block',
								width: '6em',
								height: '3.5em',
								marginRight: 3
							}}
							onChange={(e) => setSecondCount(Number(e.target.value))}
						/>
					</Col>
				</Row>
				<Row>
					<Col sm={8}>
						<p
							className="h1 "
							style={{
								fontSize: '4em',
								margin: 0
							}}
						>{`${thirdExpression.firstNumber} + ${thirdExpression.secondNumber} + ${thirdExpression.thirdNumber} =`}</p>
					</Col>
					<Col sm={4} className="text-left">
						<Form.Control
							className="text-center"
							name={'third expression'}
							type={'number'}
							size="lg"
							style={{
								display: 'inline-block',
								width: '6em',
								height: '3.5em',
								marginRight: 3
							}}
							onChange={(e) => setThirdCount(Number(e.target.value))}
						/>
					</Col>
				</Row>
				<Row className="mt-4">
					<Col>
						<Button
							variant="primary"
							style={{ width: '10em', height: '3em', fontSize: '1.25em' }}
							size="lg"
							onClick={resultChecker}
							disabled={offButton}
						>
							{t('CountTestPage_button')}
						</Button>
					</Col>
				</Row>
			</Modal.Body>
		</div>
	)
}

export default CountTestPage
