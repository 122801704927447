import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
	Dropdown,
	Form,
	Navbar,
	Nav
} from 'react-bootstrap'

import NavbarLanguages from './NavbarLanguages'
import selectors from 'redux/selectors'
import Employee from 'types/employee'
import avatar from '../assets/img/pngwing.png'
import AdminPanel from './AdminPanel'
import Messages from './Messages'
import { useSignOut } from '../routes'
import useTransliteration from '../hooks/useTransliteration'
import { useSidebar } from './Contexts/SidebarContext'

const UserPhoto = () => {
	const employee: Employee = useSelector(selectors.getUserEmployee)

	return (
		<img
			src={employee.profile_photo ?? avatar}
			onError={(e) => {
				const target = e.target as HTMLImageElement
				target.onerror = null
				target.src = avatar
			}}
			width="36"
			height="36"
			className="rounded-circle mr-2"
			referrerPolicy="no-referrer"
			alt="User"
		/>
	)
}


const NavbarComponent = () => {
	const employee: Employee = useSelector(selectors.getUserEmployee)

	const { toggleSidebar } = useSidebar();

	const signOut = useSignOut()
	const { t } = useTranslation()
	const { transliterate } = useTransliteration();

	const handleSignOut = async () => {
		await signOut()
	}

	return (
		<Navbar bg="white" expand="lg">
			<Navbar.Brand className="sidebar-toggle d-flex mr-2" onClick={() => toggleSidebar()}>
				<i className="hamburger align-self-center" />
			</Navbar.Brand>

			<Form className="d-flex">
				{/*need to add some text*/}
			</Form>

			<Navbar.Collapse>
				<Nav className="ml-auto">
					<Messages />

					<Dropdown align='end'>
						<Dropdown.Toggle as="div" className="d-flex align-items-center mr-2">
							<UserPhoto />
							<span className="text-dark mr-1">
								{transliterate(employee.first_name + ' ' + employee.last_name)}
              				</span>
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item onClick={handleSignOut}>{t('exit_button')}</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>

					<NavbarLanguages />
					<AdminPanel />
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	)
}

export default NavbarComponent
