import Employee from './employee'

export enum NotificationTypeEnum {
	info = 'info',
	warning = 'warning',
	danger = 'danger'
}

export enum NotificationReceiverStatus {
	waiting = 'waiting',
	read = 'read',
	done = 'done'
}

export interface NotificationReceiver {
	_id: string
	employee: Employee
	status: NotificationReceiverStatus
}

export interface SystemNotification {
	_id: string
	date: string
	type: NotificationTypeEnum
	sender: string
	receivers: NotificationReceiver
	text: string
	link?: string
}
