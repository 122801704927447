import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const ukToEnMap = new Map<string, string>([
	['А', 'A'], ['Б', 'B'], ['В', 'V'], ['Г', 'H'], ['Ґ', 'G'], ['Д', 'D'], ['Е', 'E'],
	['Ж', 'Zh'], ['З', 'Z'], ['И', 'Y'], ['І', 'I'], ['Ї', 'Yi'], ['Й', 'Y'],
	['К', 'K'], ['Л', 'L'], ['М', 'M'], ['Н', 'N'], ['О', 'O'], ['П', 'P'], ['Р', 'R'],
	['С', 'S'], ['Т', 'T'], ['У', 'U'], ['Ф', 'F'], ['Х', 'Kh'], ['Ц', 'Ts'],
	['Ч', 'Ch'], ['Ш', 'Sh'], ['Щ', 'Shch'], ['Ю', 'Yu'], ['Я', 'Ya'],
]);

// Add lowercase entries
[...ukToEnMap.entries()].forEach(([key, value]) => {
	if (key !== key.toLowerCase()) {
		ukToEnMap.set(key.toLowerCase(), value.toLowerCase());
	}
});

// Special cases for position-dependent characters
const specialCases = new Map<string, { start: string; other: string }>([
	['є', { start: 'Ye', other: 'ie' }],
	['ї', { start: 'Yi', other: 'i' }],
	['й', { start: 'Y', other: 'i' }],
	['ю', { start: 'Yu', other: 'iu' }],
	['я', { start: 'Ya', other: 'ia' }],
]);

const ukrainianRegex = /^[\u0400-\u04FF\s'-]+$/;

const isUkrainian = (text: string): boolean => ukrainianRegex.test(text);

const transliterateUkToEn = (name: string): string => {
	const words = name.split(/\s+/);
	return words.map(word => {
		let result = '';
		for (let i = 0; i < word.length; i++) {
			const threeChars = word.slice(i, i + 3);
			const twoChars = word.slice(i, i + 2);
			const char = word[i];
			const lowerChar = char.toLowerCase();

			if (threeChars.toLowerCase() === 'зг') {
				result += char === 'З' ? 'Zgh' : 'zgh';
				i += 2;
			} else if (ukToEnMap.has(twoChars)) {
				result += ukToEnMap.get(twoChars);
				i += 1;
			} else if (specialCases.has(lowerChar)) {
				const { start, other } = specialCases.get(lowerChar)!;
				const transliterated = i === 0 ? start : other;
				result += char === char.toUpperCase() ? transliterated.toUpperCase() : transliterated;
			} else if (ukToEnMap.has(char)) {
				result += ukToEnMap.get(char);
			} else if (char !== 'ь' && char !== `'`) {
			result += char;
		}
	}
	return result;
}).join(' ');
};

export const useTransliteration = () => {
	const { i18n } = useTranslation();

	return useMemo(() => ({
		transliterate: (name: string): string => {
			if (isUkrainian(name) && i18n.language !== 'uk') {
				return transliterateUkToEn(name);
			}
			return name;
		}
	}), [i18n.language]);
};

export default useTransliteration;

