import { createAction } from '@reduxjs/toolkit'
import * as types from '../../constants'
import { SystemNotification } from 'types/notification'

export const loadEmployeeNotificationsRequest = createAction(
	types.LOAD_EMPLOYEE_NOTIFICATIONS_REQUEST
)
export const loadEmployeeNotificationsSuccess = createAction<SystemNotification[]>(
	types.LOAD_EMPLOYEE_NOTIFICATIONS_SUCCESS
)
export const loadEmployeeNotificationsError = createAction(types.LOAD_EMPLOYEE_NOTIFICATIONS_ERROR)

export const updateNotificationStatusRequest = createAction(
	types.UPDATE_NOTIFICATION_STATUS_REQUEST
)
export const updateNotificationStatusSuccess = createAction<SystemNotification>(
	types.UPDATE_NOTIFICATION_STATUS_SUCCESS
)
export const updateNotificationStatusError = createAction(types.UPDATE_NOTIFICATION_STATUS_ERROR)
