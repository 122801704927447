import moment from 'moment'
import { Col, Dropdown, ListGroup, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import selectors from 'redux/selectors'
import { NotificationTypeEnum, SystemNotification } from 'types/notification'
import { Link } from 'react-router-dom'
import ModalView from './ModalView'
import { FaTimes } from 'react-icons/fa'
import operations from 'redux/operations'
import { FiMessageCircle } from 'react-icons/fi'
import React from 'react'
import { MdErrorOutline, MdInfoOutline, MdWarningAmber } from 'react-icons/md'

const MarkAsReadModal = (props: { message: SystemNotification }) => {
	const dispatch = useDispatch()

	const read = () => {
		dispatch(
			operations.dataOperations.notifications.updateNotificationStatusFunc(props.message._id)
		)
		return true
	}

	if (props.message.type === NotificationTypeEnum.info) return null

	return (
		<ModalView
			title="MarkAsReadModal_title"
			buttonAsText
			buttonText="MarkAsReadModal_buttonText"
			buttonColor="muted"
			buttonSmall
			noLeftMargin
			buttonOutline
			className="small scaled_button"
			buttonIcon={FaTimes}
			acceptButtonText="MarkAsReadModal_acceptButtonText"
			acceptButtonColor="danger"
			rejectButtonText="MarkAsReadModal_rejectButtonText"
			onAccept={read}
		/>
	)
}

function MessageLabel(prop: { type: NotificationTypeEnum }) {
	const iconSize = 30

	switch (prop.type) {
		case NotificationTypeEnum.danger:
			return <MdErrorOutline className="text-danger" size={iconSize} />

		case NotificationTypeEnum.warning:
			return <MdWarningAmber className="text-warning" size={iconSize} />

		case NotificationTypeEnum.info:
			return <MdInfoOutline className="text-info" size={iconSize} />

		default:
			return null
	}
}

const Message = (props: { message: SystemNotification }) => {
	const { i18n, t } = useTranslation()
	const { type, sender, date, text, link } = props.message

	return (
		<ListGroup.Item>
			<Row className="align-items-center g-0">
				<Col xs={2}>
					<MessageLabel type={type} />
				</Col>
				<Col xs={6} className={'pl-2'}>
					<div className="text-dark">{sender}</div>
					<div className="text-muted small mt-1">
						{i18n.exists(text) ? t(text) : text}
					</div>
					<div className="text-muted small mt-1">
						{moment(date).calendar().toLowerCase()}
					</div>
				</Col>
				<Col xs={4} className="pr-0">
					<div className="text-right">
						<MarkAsReadModal message={props.message} />
					</div>

					<br />
					{link && (
						<Link className="text-primary small" to={link}>
							{i18n.exists(`Message_${link}`) ? t(`Message_${link}`) : 'to solve'}
						</Link>
					)}
				</Col>
			</Row>
		</ListGroup.Item>
	)
}

export default function Messages() {
	const messages: SystemNotification[] = useSelector(selectors.getEmployeeNotifications)
	const { t, i18n } = useTranslation()
	moment.locale(i18n.language)

	if (!messages.length) return null

	return (
		<Dropdown className="me-2 mt-1 mr-3 nav-item" align="end">
			<Dropdown.Toggle as="a" className="nav-link nav-icon dropdown-toggle ">
				<div className="position-relative">
					<FiMessageCircle className='align-middle' size={18}/>
					<span className="danger_indicator"> {messages.length} </span>
				</div>
			</Dropdown.Toggle>
			<Dropdown.Menu className="dropdown-menu-lg py-0 message_dropdown_menu">
				<div className="dropdown-menu-header position-relative">
					{t('Message', { count: messages.length })}
				</div>
				<ListGroup>
					{messages.map((message: SystemNotification) => (
						<Message key={message._id} message={message} />
					))}
				</ListGroup>
				<Dropdown.Header className="dropdown-menu-footer">
					<span className="text-muted">{}</span>
				</Dropdown.Header>
			</Dropdown.Menu>
		</Dropdown>
	)
}
