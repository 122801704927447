import React from 'react'
import { Link } from 'react-router-dom'
import { rainbowColorNameAtPosition } from 'utils/rainbowColors'

import UserAvailabilityStatus from 'components/UserAvailabilityStatus'

import {
	FaConnectdevelop,
	FaCubes,
	FaImage,
	FaJsSquare,
	FaPalette,
	FaUnity,
	FaUserTie
} from 'react-icons/fa'

import { PositionsEnum } from 'types/positions'
import { ShortEmployee } from 'types/employee'
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap'
import useTransliteration from '../../hooks/useTransliteration'

const AuthorBadge = (props: {
	employee?: ShortEmployee
	size?: 'sm' | 'md' | 'full'
	colorOff?: boolean
	linkOff?: boolean
	showAvailability?: boolean
	today?: boolean
	className?: string
	kommaAfterBadge?: boolean
}) => {
	const { transliterate } = useTransliteration();

	// to fix
	if (!props.employee || typeof props.employee === 'string') return <></>

	const { _id, first_name, last_name } = props.employee

	if (!first_name || !last_name) return <></>

	const color = rainbowColorNameAtPosition(props.employee.position, props.employee.user_roles)
	let text = ''

	switch (props.size) {
		case 'sm':
			text = first_name.charAt(0)
			break
		case 'full':
			text = `${first_name} ${last_name}`
			break
		default:
			text = `${first_name} ${last_name.charAt(0)}`
	}

	const UserIcon = () => {
		if (!props.employee || (props.size && props.size === 'sm'))
			/* return <FaUser className="mr-1" style={{ padding: '0.05rem !important' }} /> */
			return null

		const { position } = props.employee

		/* if (user_roles.includes(RolesEnum.mentor) || user_roles.includes(RolesEnum.art_mentor))
			return <FaUserNinja className="mr-1" style={{ padding: '0.05rem !important' }} /> */

		switch (position) {
			case PositionsEnum.full_stack_developer:
				return (
					<FaConnectdevelop className="mr-1" style={{ padding: '0.05rem !important' }} />
				)

			case PositionsEnum.unity_developer:
				return <FaUnity className="mr-1" style={{ padding: '0.05rem !important' }} />

			case PositionsEnum.javascript_developer:
				return <FaJsSquare className="mr-1" style={{ padding: '0.05rem !important' }} />

			case PositionsEnum.three_d_generalist:
				return <FaPalette className="mr-1" style={{ padding: '0.05rem !important' }} />

			case PositionsEnum.three_d_artist:
				return <FaCubes className="mr-1" style={{ padding: '0.05rem !important' }} />

			case PositionsEnum.concept_artist:
				return <FaImage className="mr-1" style={{ padding: '0.05rem !important' }} />

			case PositionsEnum.cpo:
			case PositionsEnum.first_game_producer:
			case PositionsEnum.second_game_producer:
			case PositionsEnum.associate_producer:
			case PositionsEnum.project_manager:
				return <FaUserTie className="mr-1" style={{ padding: '0.05rem !important' }} />

			default:
				return null
		}

		/* return <FaUser className="mr-1" style={{ padding: '0.05rem !important' }} /> */
	}

	const tooltipText = `${first_name} ${last_name}`

	const renderTooltip = (props) => (
		<Tooltip id="tooltip-right" className={'custom-tooltip right'} {...props}>
			{tooltipText}
		</Tooltip>
	)

	const badgeColor = color && !props.colorOff ? color : 'blue'

	const CustomBadge = (
		<Link to={props.linkOff ? '#/' : `/employee/${_id}`}>
			<Badge
				id={'TooltipComponent' + _id}
				className={`badge-pill ${props.kommaAfterBadge ? '' : 'mr-1'} ${props.className} ${
					'badge-' + badgeColor
				}`}
				style={{ borderRadius: '0.3rem', backgroundColor: badgeColor }}
			>
				<UserIcon />
				{props.showAvailability && (
					<UserAvailabilityStatus employee_id={_id} today={props.today} />
				)}
				{transliterate(text)}
			</Badge>

			{props.kommaAfterBadge ? (
				<span className="mr-1" style={{ whiteSpace: 'nowrap' }}>
					,
				</span>
			) : null}
		</Link>
	)

	return props.size !== 'full' ? (
		<OverlayTrigger placement="right" overlay={renderTooltip}>
			{CustomBadge}
		</OverlayTrigger>
	) : (
		CustomBadge
	)
}

export default AuthorBadge
