import { toast } from 'react-toastify'
import actions from 'redux/actions'
import { t } from 'i18next'
import {
	approveFinanceOperation,
	checkFinanceOperation,
	createFinanceOperations,
	createInterBalanceTransaction,
	deleteExpenseFile,
	deleteFinanceOperation,
	distibuteFoundersDividends,
	downloadExpenseFile,
	getAdvanceOperationsByMonth,
	getBalanceByMonth,
	getDividends,
	getFinanceOperationsByMonth,
	getFinanceOperationsByYear,
	rejectFinanceOperation,
	uploadExpenseFile,
	withdrawDividends
} from 'api/request'
import FinanceOperation, {
	DividendsInfo,
	FinanceOperationCheckData,
	FinanceOperationCreationData
} from 'types/financeOperation'
import { BalanceInfo, InterBalanceTransactionCreationData } from 'types/balance'
import { DistributionData, Founder, WithdrawDividendsData } from 'types/founder'

export const loadFinanceOperationsByMonthfunc = (month: Date) => async (dispatch) => {
	dispatch(actions.dataActions.financeOperations.loadFinanceOperationsRequest())
	try {
		const financeOperationsByMonth = await getFinanceOperationsByMonth(month)
		return dispatch(
			actions.dataActions.financeOperations.loadFinanceOperationsSuccess(
				financeOperationsByMonth
			)
		)
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.financeOperations.loadFinanceOperationsError())
	}
}

export const loadAdvanceOperationsByMonthfunc = (month: string) => async (dispatch) => {
	dispatch(actions.dataActions.financeOperations.loadAdvanceOperationsRequest())
	try {
		const advanceOperationsByMonth = await getAdvanceOperationsByMonth(month)
		return dispatch(
			actions.dataActions.financeOperations.loadAdvanceOperationsSuccess(
				advanceOperationsByMonth
			)
		)
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.financeOperations.loadAdvanceOperationsError())
	}
}

export const loadFinanceOperationsByYearFunc = (year: Date) => async (dispatch) => {
	dispatch(actions.dataActions.financeOperations.loadFinanceOperationsByYearRequest())
	try {
		const financeOperationsByYear: FinanceOperation[] = await getFinanceOperationsByYear(year)

		return dispatch(
			actions.dataActions.financeOperations.loadFinanceOperationsByYearSuccess(
				financeOperationsByYear
			)
		)
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.financeOperations.loadFinanceOperationsByYearError())
	}
}

export const createFinanceOperationfunc =
	(creationData: FinanceOperationCreationData, file?: File) => async (dispatch) => {
		dispatch(actions.dataActions.financeOperations.createFinanceOperationRequest())
		try {
			let createdFinanceOperation: FinanceOperation = await createFinanceOperations(
				creationData
			)

			if (file) {
				createdFinanceOperation = await uploadExpenseFile(createdFinanceOperation._id, file)
			}

			toast.success(t('reduxToastSuccess_succesfullyCreated') as string)

			return dispatch(
				actions.dataActions.financeOperations.createFinanceOperationSuccess(
					createdFinanceOperation
				)
			)
		} catch (error: any) {
			toast.error(error.response.data.message)
			return dispatch(actions.dataActions.financeOperations.createFinanceOperationError())
		}
	}

export const approveFinanceOperationfunc = (id) => async (dispatch) => {
	dispatch(actions.dataActions.financeOperations.approveFinanceOperationRequest())
	try {
		const approvedFinanceOperation = await approveFinanceOperation(id)
		toast.success(t('reduxToastSuccess_approveExpense') as string)
		return dispatch(
			actions.dataActions.financeOperations.approveFinanceOperationSuccess(
				approvedFinanceOperation
			)
		)
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.financeOperations.approveFinanceOperationError())
	}
}

export const rejectFinanceOperationfunc = (id) => async (dispatch) => {
	dispatch(actions.dataActions.financeOperations.rejectFinanceOperationRequest())
	try {
		const rejectedFinanceOperation = await rejectFinanceOperation(id)
		toast.success(t('reduxToastSuccess_rejectExpense') as string)
		return dispatch(
			actions.dataActions.financeOperations.rejectFinanceOperationSuccess(
				rejectedFinanceOperation
			)
		)
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.financeOperations.rejectFinanceOperationError())
	}
}

export const checkFinanceOperationfunc =
	(checkData: FinanceOperationCheckData) => async (dispatch) => {
		dispatch(actions.dataActions.financeOperations.checkFinanceOperationRequest())
		try {
			const checkedFinanceOperation = await checkFinanceOperation(checkData)
			toast.success(t('reduxToastSuccess_checkExpense') as string)
			return dispatch(
				actions.dataActions.financeOperations.checkFinanceOperationSuccess(
					checkedFinanceOperation
				)
			)
		} catch (error: any) {
			toast.error(error.response.data.message)
			return dispatch(actions.dataActions.financeOperations.checkFinanceOperationError())
		}
	}

export const deleteFinanceOperationfunc = (id: string) => async (dispatch) => {
	dispatch(actions.dataActions.financeOperations.deleteFinanceOperationsRequest())
	try {
		const deletedFinanceOperation = await deleteFinanceOperation(id)
		toast.success(t('reduxToastSuccess_expenseDeleted') as string)
		dispatch(
			actions.dataActions.financeOperations.deleteFinanceOperationsSuccess(
				deletedFinanceOperation
			)
		)
	} catch (error: any) {
		toast.error(error.response.data.message)
		dispatch(actions.dataActions.financeOperations.deleteFinanceOperationsError())
	}
}

//expense file
export const uploadExpenseFileFunc = (expense_id: string, file: File) => async (dispatch) => {
	dispatch(actions.dataActions.financeOperations.uploadExpenseFileRequest())
	try {
		const updatedExpense: FinanceOperation = await uploadExpenseFile(expense_id, file)
		toast.success(t('reduxToastSuccess_expenseFileUploaded') as string)
		return dispatch(
			actions.dataActions.financeOperations.uploadExpenseFileSuccess(updatedExpense)
		)
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.financeOperations.uploadExpenseFileError())
	}
}

export const downloadExpenseFileFunc = (file_id: string) => async (dispatch) => {
	dispatch(actions.dataActions.financeOperations.downloadExpenseFileRequest())
	try {
		await downloadExpenseFile(file_id)

		toast.success(t('reduxToastSuccess_expenseFileDownloaded') as string)
		return dispatch(actions.dataActions.financeOperations.downloadExpenseFileSuccess())
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.financeOperations.downloadExpenseFileError())
	}
}

export const deleteExpenseFileFunc = (expense_id: string, file_id: string) => async (dispatch) => {
	dispatch(actions.dataActions.financeOperations.deleteExpenseFileRequest())
	try {
		const updatedExpense: FinanceOperation = await deleteExpenseFile(expense_id, file_id)
		toast.success(t('reduxToastSuccess_invoiceFileDeleted') as string)
		return dispatch(
			actions.dataActions.financeOperations.deleteExpenseFileSuccess(updatedExpense)
		)
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.financeOperations.deleteExpenseFileError())
	}
}

// Balance

export const loadBalanceByMonthfunc = (month: string) => async (dispatch) => {
	dispatch(actions.dataActions.financeOperations.loadBalanceByMonthRequest())
	try {
		const balanceByMonth: BalanceInfo = await getBalanceByMonth(month)
		return dispatch(
			actions.dataActions.financeOperations.loadBalanceByMonthSuccess(balanceByMonth)
		)
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.financeOperations.loadBalanceByMonthError())
	}
}

export const createInterBalanceTransactionFunc =
	(creationData: InterBalanceTransactionCreationData) => async (dispatch) => {
		dispatch(actions.dataActions.financeOperations.createInterBalanceTransactionRequest())
		try {
			const interBalanceData: BalanceInfo = await createInterBalanceTransaction(creationData)

			toast.success(t('reduxToastSuccess_interBalanceTransaction') as string)
			return dispatch(
				actions.dataActions.financeOperations.createInterBalanceTransactionSuccess(
					interBalanceData
				)
			)
		} catch (error: any) {
			console.log(error)
			toast.error( error.response.data.message)
			return dispatch(
				actions.dataActions.financeOperations.createInterBalanceTransactionError()
			)
		}
	}

// Dividends

export const loadDividendsfunc = () => async (dispatch) => {
	dispatch(actions.dataActions.financeOperations.loadDividendsRequest())

	try {
		const dividends: DividendsInfo = await getDividends()
		return dispatch(actions.dataActions.financeOperations.loadDividendsSuccess(dividends))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.financeOperations.loadDividendsError())
	}
}

export const distributeFoundersDividendsFunc =
	(distributionData: DistributionData) => async (dispatch) => {
		dispatch(actions.dataActions.financeOperations.distributeFoundersDividendsRequest())

		try {
			const updatedFounders: Founder[] = await distibuteFoundersDividends(distributionData)

			toast.success(t('reduxToastSuccess_dividendsDistibution') as string)
			return dispatch(
				actions.dataActions.financeOperations.distributeFoundersDividendsSuccess(
					updatedFounders
				)
			)
		} catch (error: any) {
			return dispatch(
				actions.dataActions.financeOperations.distributeFoundersDividendsError()
			)
		}
	}

export const withdrawDividendsFunc = (withdrawData: WithdrawDividendsData) => async (dispatch) => {
	dispatch(actions.dataActions.financeOperations.addDividendsRequest())

	try {
		const updatedDividends: DividendsInfo = await withdrawDividends(withdrawData)

		return dispatch(actions.dataActions.financeOperations.addDividendsSuccess(updatedDividends))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.financeOperations.addDividendsError())
	}
}
