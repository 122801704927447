import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

import UniversalTable from 'components/UniversalTable';

import {
	useArchiveContractTypeMutation, useGetContractTypesQuery,
	useUnarchiveContractTypeMutation
} from '../../../../../redux/slices/financeDictionaries'
import { Button, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap'
import { FaArchive, FaEdit } from 'react-icons/fa'
import { FaArrowRotateLeft } from 'react-icons/fa6'
import { ContractType } from '../../../../../types/finance/contract'
import UpdateContractTypeModal from '../Modals/UpdateContractTypeModal'
import TableError from '../../../../../components/TableError'

const columns = [
	{
		dataField: 'order_number',
		text: '№',
		sort: false,
		width: '10%',
		className: 'text-center'
	},
	{
		dataField: 'name',
		text: 'name',
		sort: false,
		width: '70%',
		className: 'text-left',
	},
	{
		dataField: 'actions',
		text: 'actions',
		sort: false,
		width: '20%',
		className: 'text-center',
	}
];

interface ContractTypeRowProps {
	contractType: ContractType;
	handlers: any;
	index: number;
}

function ContractTypeRow({ contractType, handlers, index }: ContractTypeRowProps) {
	const { t, i18n } = useTranslation();

	const [showUpdate, setShowUpdate] = useState(false)

	const [archiveContractType, unarchiveContractType] = handlers

	const renderTooltip = (content: string) => (props: any) => (
		<Tooltip id={`tooltip-${contractType._id}`} className={'custom-tooltip top'} {...props}>
			{content}
		</Tooltip>
	);

	return (
		<tr>
			<td className="text-center">{index + 1}</td>
			<td className="text-left">{(i18n.language === 'uk' ? contractType.ukName : contractType.enName)}</td>
			<td className="text-center">
				<div className='d-flex align-middle justify-content-center'>
					{
						!contractType.isArchived && (
							<>
								<UpdateContractTypeModal
									key={contractType.enName + Math.random()}
									contractType={contractType}
									show={showUpdate}
									setShow={setShowUpdate}
								/>
								<OverlayTrigger
									placement="top"
									overlay={renderTooltip(t('edit'))}
								>
									<Button
										variant="outline-primary"
										size="sm"
										className="d-flex align-items-center justify-content-center p-0 mr-2"
										style={{ width: '28px', height: '28px', borderRadius: '4px' }}
										onClick={(e) => {
											e.stopPropagation()
											setShowUpdate(true)
										}}
										aria-label={t('edit')}
									>
										<FaEdit size={18} />
									</Button>
								</OverlayTrigger>
							</>
						)
					}
					{
						contractType.isArchived ? (
							<OverlayTrigger
								placement="top"
								overlay={renderTooltip(t('unarchive'))}
							>
								<Button
									variant="outline-success"
									size="sm"
									className="d-flex align-items-center justify-content-center p-0"
									style={{ width: '28px', height: '28px', borderRadius: '4px' }}
									onClick={(e) => {
										e.stopPropagation()
										unarchiveContractType(contractType._id)
									}}
									aria-label={t('unarchive')}
								>
									<FaArrowRotateLeft size={18} />
								</Button>
							</OverlayTrigger>
						) : (
							<OverlayTrigger
								placement="top"
								overlay={renderTooltip(t('archive'))}
							>
								<Button
									variant="outline-danger"
									size="sm"
									className="d-flex align-items-center justify-content-center p-0"
									style={{ width: '28px', height: '28px', borderRadius: '4px' }}
									onClick={(e) => {
										e.stopPropagation()
										archiveContractType(contractType._id)
									}}
									aria-label={t('archive')}
								>
									<FaArchive size={18} />
								</Button>
							</OverlayTrigger>
						)
					}
				</div>
			</td>
		</tr>
	);
}

function ContractTypesTable() {
	const [archiveContractType] = useArchiveContractTypeMutation()
	const [unarchiveContractType] = useUnarchiveContractTypeMutation()

	const { data: contractTypesData, isLoading, isError } = useGetContractTypesQuery();

	if (isLoading) return <Spinner animation="grow" variant={'primary'} className="me-2" />;
	if (isError) return <TableError>Error loading data</TableError>;

	const unarchivedContractTypesData = (contractTypesData && contractTypesData.filter(entry => !entry.isArchived)) || []
	const archivedContractTypesData = (contractTypesData && contractTypesData.filter(entry => entry.isArchived)) || []

	const handlers = [archiveContractType, unarchiveContractType]

	return <>
		{
			unarchivedContractTypesData && unarchivedContractTypesData.length > 0 ?
				<UniversalTable
					name={'ContractTypeTable_tableName'}
					id={'contractsTable ContractTypeTable_tableName'}
					columns={columns}
					data={unarchivedContractTypesData}
					row={
						(contractType, i) =>
							<ContractTypeRow handlers={handlers} key={i + 'contractTypeRow'} contractType={contractType} index={i}/>
					}
					scrollable
					defaultOpen={false}
				/> :
				<TableError>No contract types data.</TableError>
		}
		{
			archivedContractTypesData && archivedContractTypesData.length > 0 ?
				<UniversalTable
					name={'ContractTypeTable_tableNameArchived'}
					id={'contractsTable ContractTypeTable_tableNameArchived'}
					columns={columns}
					data={archivedContractTypesData}
					row={
						(contractType, i) =>
							<ContractTypeRow handlers={handlers} key={i + 'contractTypeRow'} contractType={contractType} index={i}/>
					}
					scrollable
					defaultOpen={false}
				/> :
				null
		}
	</>
}

export default ContractTypesTable;
