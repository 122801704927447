import React from 'react'
import { useTranslation } from 'react-i18next'
import { Dropdown } from 'react-bootstrap'

import uaFlag from '../assets/img/flags/ua.png'
import ruFlag from '../assets/img/flags/ru.png'
import enFlag from '../assets/img/flags/gb-nir.png'

const languageOptions = {
	uk: {
		icon: uaFlag,
		name: 'Українська'
	},
	en: {
		icon: enFlag,
		name: 'English'
	},
	ru: {
		icon: ruFlag,
		name: 'Русский'
	}
}

const languageOptionsLimited = {
	uk: {
		icon: uaFlag,
		name: 'Українська'
	},
	en: {
		icon: enFlag,
		name: 'English'
	}
}

const NavbarLanguages = () => {
	const { i18n } = useTranslation()

	let options = languageOptionsLimited

	return (
		<Dropdown className="nav-item mr-1" align='end'>
			<Dropdown.Toggle as="a" className="nav-link nav-flag">
				<img
					src={languageOptions[i18n.language].icon}
					alt={languageOptions[i18n.language].name}
				/>
			</Dropdown.Toggle>
			<Dropdown.Menu className='languageFix'>
				{Object.keys(options).map((language) => (
					<Dropdown.Item key={language} onClick={() => i18n.changeLanguage(language)}>
						<img
							src={languageOptions[language].icon}
							alt="English"
							width="20"
							className="align-middle mr-1"
						/>
						<span className="align-middle">{languageOptions[language].name}</span>
					</Dropdown.Item>
				))}
			</Dropdown.Menu>
		</Dropdown>
	)
}

export default NavbarLanguages
