import {
	registerEmployee,
	sendEmployeeInfo,
	archiveEmployee,
	getEmployees,
	uploadProfilePhoto,
	deleteProfilePhoto,
	updatePromotionInfo,
	getShortEmployees,
	getEmployee
} from 'api/request'

import { toast } from 'react-toastify'
import actions from 'redux/actions'
import { t } from 'i18next'
import Employee, {
	EmployeeArchiveData,
	EmployeeCreationData,
	EmployeeUpdatingData,
	PromotionUpdatingData
} from 'types/employee'

export const loadEmployeesFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.employees.loadEmployeesRequest())
	try {
		const updatedEmployee: Employee[] = await getEmployees()
		return dispatch(actions.dataActions.employees.loadEmployeesSuccess(updatedEmployee))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.employees.loadEmployeesError())
	}
}

export const getEmployeeFunc = (id: string) => async (dispatch) => {
	dispatch(actions.dataActions.employees.getEmployeeRequest())
	try {
		const employee: Employee = await getEmployee(id)
		return dispatch(actions.dataActions.employees.getEmployeeSuccess(employee))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.employees.getEmployeeError())
	}
}

export const loadShortEmployeesFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.employees.loadShortEmployeesRequest())
	try {
		const updatedEmployee: Employee[] = await getShortEmployees()
		return dispatch(actions.dataActions.employees.loadShortEmployeesSuccess(updatedEmployee))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.employees.loadShortEmployeesError())
	}
}

export const registerEmployeeFunc = (creationData: EmployeeCreationData) => async (dispatch) => {
	dispatch(actions.dataActions.employees.registerEmployeeRequest())
	try {
		const registeredEmployee: Employee = await registerEmployee(creationData)
		toast.success(t('reduxToastSuccess_newEmployeeRegistered') as string)
		return dispatch(actions.dataActions.employees.registerEmployeeSuccess(registeredEmployee))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.employees.registerEmployeeError())
	}
}

export const updateInfoEmployeeFunc = (updatingData: EmployeeUpdatingData) => async (dispatch) => {
	dispatch(actions.dataActions.employees.updateInfoEmployeeRequest())
	try {
		const updatedEmployee: Employee = await sendEmployeeInfo(updatingData)
		toast.success(t('reduxToastSuccess_employeeInfoUpdated') as string)
		return dispatch(actions.dataActions.employees.updateInfoEmployeeSuccess(updatedEmployee))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.employees.updateInfoEmployeeError())
	}
}

export const updatePromotionInfoEmployeeFunc =
	(updatingData: PromotionUpdatingData) => async (dispatch) => {
		dispatch(actions.dataActions.employees.updatePromotionInfoEmployeeRequest())
		try {
			const updatedEmployee: Employee = await updatePromotionInfo(updatingData)
			toast.success(t('reduxToastSuccess_employeeInfoUpdated') as string)
			return dispatch(
				actions.dataActions.employees.updatePromotionInfoEmployeeSuccess(updatedEmployee)
			)
		} catch (error: any) {
			return dispatch(actions.dataActions.employees.updatePromotionInfoEmployeeError())
		}
	}

export const uploadEmployeeProfilePhotoFunc = (file: File) => async (dispatch) => {
	dispatch(actions.dataActions.employees.uploadEmployeeProfilePhotoRequest())
	try {
		const updatedEmployee: Employee = await uploadProfilePhoto(file)
		toast.success(t('reduxToastSuccess_uploadProfilePhoto') as string)
		return dispatch(
			actions.dataActions.employees.uploadEmployeeProfilePhotoSuccess(updatedEmployee)
		)
	} catch (error: any) {
		return dispatch(actions.dataActions.employees.uploadEmployeeProfilePhotoError())
	}
}

export const deleteEmployeeProfilePhotoFunc = (id: string) => async (dispatch) => {
	dispatch(actions.dataActions.employees.deleteEmployeeProfilePhotoRequest())
	try {
		const updatedEmployee: Employee = await deleteProfilePhoto(id)
		toast.success(t('reduxToastSuccess_deleteProfilePhoto') as string)
		return dispatch(
			actions.dataActions.employees.deleteEmployeeProfilePhotoSuccess(updatedEmployee)
		)
	} catch (error: any) {
		return dispatch(actions.dataActions.employees.deleteEmployeeProfilePhotoError())
	}
}

export const archiveEmployeeFunc = (archiveData: EmployeeArchiveData) => async (dispatch) => {
	dispatch(actions.dataActions.employees.archiveEmployeeRequest())
	try {
		const archivedEmployee: Employee = await archiveEmployee(archiveData)
		toast.success(t('reduxToastSuccess_employeeArchived') as string)
		return dispatch(actions.dataActions.employees.archiveEmployeeSuccess(archivedEmployee))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.employees.archiveEmployeeError())
	}
}
