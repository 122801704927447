import { createAction } from '@reduxjs/toolkit'
import * as types from '../../constants'
import Project from 'types/project'
import FinanceCollection from 'types/finance'

export const getFinanceCollectionRequest = createAction(types.FINANCE_IN_MONTH_REQUEST)
export const getFinanceCollectionSuccess = createAction<FinanceCollection>(
	types.FINANCE_IN_MONTH_SUCCESS
)
export const getFinanceCollectionError = createAction(types.FINANCE_IN_MONTH_ERROR)

export const updateProjectSummRequest = createAction(types.PROTOTYPES_LIST_UPDATE_SUMM_REQUEST)
export const updateProjectSummSuccess = createAction<Project>(
	types.PROTOTYPES_LIST_UPDATE_SUMM_SUCCESS
)
export const updateProjectSummError = createAction(types.PROTOTYPES_LIST_UPDATE_SUMM_ERROR)

export const recalculateFinanceRequest = createAction(types.FINANCE_IN_MONTH_RECALCULATE_REQUEST)
export const recalculateFinanceSuccess = createAction<FinanceCollection>(
	types.FINANCE_IN_MONTH_RECALCULATE_SUCCESS
)
export const recalculateFinanceError = createAction(types.FINANCE_IN_MONTH_RECALCULATE_ERROR)
