import { combineReducers } from 'redux'

import theme from './themeReducer'
import data from './data'
import user from './userReducer'

import { financeDictionariesApi } from '../slices/financeDictionaries';
import { projectsApi } from '../slices/projects'
import { publishersApi } from '../slices/publishers'
import { operationStatementsApi } from '../slices/operationStatements'
import { invoicesApi } from '../slices/invoices'
import { salariesTableApi } from '../slices/salariesTable'

const rootReducer = combineReducers({
	theme,
	data,
	user,

	[financeDictionariesApi.reducerPath]: financeDictionariesApi.reducer,
	[projectsApi.reducerPath]: projectsApi.reducer,
	[publishersApi.reducerPath]: publishersApi.reducer,
	[operationStatementsApi.reducerPath]: operationStatementsApi.reducer,
	[invoicesApi.reducerPath]: invoicesApi.reducer,
	[salariesTableApi.reducerPath]: salariesTableApi.reducer
})

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;