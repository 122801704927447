import { RolesEnum } from '../../../../../types/roles'
import ModalView from '../../../../../components/ModalView'
import React from 'react'

interface DeleteContractModalProps {
	show: boolean
	setShow: (state: boolean) => void
	onAccept: (id: string) => void
}

function DeleteContractModal({
	show,
	setShow,
	onAccept,
}: DeleteContractModalProps) {
	return (
		<ModalView
			id='deleteContract'
			title="DeleteContractModal_title"
			buttonText=""
			show={show}
			setShow={setShow}
			noButton={true}
			onAccept={onAccept}
			acceptButtonText="DeleteContractModal_title"
			acceptButtonColor="danger"
			requaredRoles={[RolesEnum.admin, RolesEnum.finances]}
			key={Math.random()}
		/>
	)
}

export default DeleteContractModal