import React from 'react'
import { FaPlayCircle } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import { useSelector } from 'react-redux'
import selectors from 'redux/selectors'
import WorkCheckin from 'types/workCheckin'
import { Modal, Button } from 'react-bootstrap'

const ContinueWorkPage = (props: { onContinue: () => void }) => {
	const { t } = useTranslation()

	const lastCheckin: WorkCheckin = useSelector(selectors.getLastCheckin)
	const continueHandler = () => {
		props.onContinue()
	}

	return (
		<Modal.Body
			className="text-center"
			style={{
				height: '30em',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center'
			}}
		>
			{lastCheckin ? (
				<h4 className="font-weight-bold mb-5">
					{t('ContinueWorkPage_checkinTime')}{' '}
					<Moment format="HH:mm:ss" locale={t('locales')} date={lastCheckin.date} />
				</h4>
			) : null}

			<Button
				variant="primary"
				onClick={continueHandler}
				style={{
					width: '15em',
					height: '15em',
					borderColor: 'white',
					borderRadius: '50%'
				}}
			>
				{<FaPlayCircle style={{ width: '100%', height: '100%' }} />}
			</Button>

			<h3 className="font-weight-bold mt-5">{t('ContinueWorkPage_header')}</h3>
		</Modal.Body>
	)
}

export default ContinueWorkPage
