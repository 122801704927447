import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../api/axios.queryBuilder'
import {
	CreateOperationStatementDto,
	FilterOperationStatementDto,
	OperationStatement,
	UpdateOperationStatementDto
} from '../../types/finance/operationStatement'

export const operationStatementsApi = createApi({
	reducerPath: 'operationStatementsApi',
	baseQuery: axiosBaseQuery({
		baseUrl: `finance`,
	}),
	tagTypes: ['OperationStatements'],
	endpoints: (builder) => ({
		createOperationStatement: builder.mutation<OperationStatement, CreateOperationStatementDto>({
			query: (body) => ({
				url: `/operation-statement`,
				method: 'POST',
				data: body,
			}),
			invalidatesTags: ['OperationStatements'],
		}),

		updateOperationStatement: builder.mutation<
			OperationStatement,
			{ id: string; changes: UpdateOperationStatementDto }
		>({
			query: ({ id, changes }) => ({
				url: `/operation-statement/${id}`,
				method: 'PATCH',
				data: changes,
			}),
			invalidatesTags: ['OperationStatements'],
		}),

		executeOperationStatement: builder.mutation<void, string>({
			query: (id) => ({
				url: `/operation-statement/${id}/execute`,
				method: 'PATCH',
			}),
			invalidatesTags: ['OperationStatements'],
		}),

		undoExecuteOperationStatement: builder.mutation<void, string>({
			query: (id) => ({
				url: `/operation-statement/${id}/undo-execute`,
				method: 'PATCH',
			}),
			invalidatesTags: ['OperationStatements'],
		}),

		deleteOperationStatement: builder.mutation<void, string>({
			query: (id) => ({
				url: `/operation-statement/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['OperationStatements'],
		}),

		getOperationStatements: builder.query<
			{ data: OperationStatement[]; total: number },
			FilterOperationStatementDto
		>({
			query: (params) => ({
				url: `/operation-statements`,
				method: 'GET',
				params,
			}),
			providesTags: ['OperationStatements'],
		}),

		getOperationStatementsDocumentNumbers: builder.query<string[], void>({
			query: () => ({
				url: `/operation-numbers`,
				method: 'GET',
			}),
			providesTags: ['OperationStatements'],
		}),
	}),
});

export const {
	useCreateOperationStatementMutation,
	useUpdateOperationStatementMutation,
	useExecuteOperationStatementMutation,
	useUndoExecuteOperationStatementMutation,
	useDeleteOperationStatementMutation,
	useGetOperationStatementsQuery,
	useGetOperationStatementsDocumentNumbersQuery
} = operationStatementsApi;
