import { MonthsOptions } from 'components/ProjectsByMonthsSelector'
import Concept, { ConceptStatusEnum } from 'types/concept'
import Employee from 'types/employee'
import Idea, { IdeaStatusEnum } from 'types/idea'
import Invoice from 'types/invoice'
import { Party } from 'types/party'
import Project, {
	DevStateEnum,
	ProjectCount,
	ProjectEmployees,
	ProjectTypeEnum
} from 'types/project'
import Promotion from 'types/promotion'
import Publisher from 'types/publisher'
import PublishersInvoicePayTime from 'types/publishersInvoicePayTime'
import Sickness from 'types/sickness'
import { CompanyStatistics, EmployeeStatisticsByYear } from 'types/statistics'
import { PartySurvey, ProjectSurvey, Survey } from 'types/survey'
import Todolist from 'types/todolist'
import TrainingProject from 'types/trraining.project'
import Vacation from 'types/vacation'
import VacationLockedPeriods from 'types/vacation.locked.periods'
import Visits from 'types/visitCheck'
import WorkCheckin from 'types/workCheckin'
import * as types from '../../constants'
import { SystemSettings } from 'types/systemSettings'
import { KPICardType } from 'types/kpiCard'
import { SystemNotification } from 'types/notification'
import { MentorsStatistic } from 'types/mentor'
import { Subscription } from 'types/subscription'

export type DataState = {
	isLoad: boolean
	loading: boolean
	error: boolean
	system_settings: SystemSettings
	notifications: SystemNotification[]

	publishers: Publisher[]
	employees: Employee[]
	projects: Project[]
	employee_projects: Project[]
	projects_employees: ProjectEmployees[]
	project_count: {
		developer: ProjectCount[]
		artist: ProjectCount[]
	}
	in_testing_projects_month: MonthsOptions
	tested_projects_month: MonthsOptions
	in_testing_projects_loading: boolean
	tested_projects_loading: boolean
	concepts: Concept[]
	employee_concepts: Concept[]
	invoices: Invoice[]
	subscriptions: Subscription[]
	publishersInvoicePayTime: PublishersInvoicePayTime[]
	employeePromotions: Promotion[]
	promotions: Promotion[]
	trainingProjects: TrainingProject[]

	vacations: Vacation[]
	todayVacations: Vacation[]
	vacationLockedPeriods: VacationLockedPeriods[]
	sicknesses: Sickness[]
	todaySicknesses: Sickness[]
	companyStat: CompanyStatistics[]
	employeeYearStatistic: EmployeeStatisticsByYear[]
	mentorsStat: MentorsStatistic
	employeeCheckins: WorkCheckin[]
	workchekins: WorkCheckin[]
	visits: Visits[]
	ideas: Idea[]
	todolists: Todolist[]
	kpi_cards: KPICardType[]

	parties: Party[]
	memberParties: Party[]

	weekly_monthly_surveys: Survey[]
	project_surveys: ProjectSurvey[]
	project_surveys_loading: boolean
	project_surveys_month: MonthsOptions
	employee_project_surveys: ProjectSurvey[]
	party_surveys: PartySurvey[]
}

const initialState = {
	isLoad: false,
	loading: false,
	error: false,
	system_settings: undefined,
	notifications: [],

	publishers: [],
	employees: [],
	projects: [],
	employee_projects: [],
	projects_employees: [],
	project_count: {
		developer: [],
		artist: []
	},
	in_testing_projects_month: MonthsOptions.twoMonths,
	tested_projects_month: MonthsOptions.twoMonths,
	in_testing_projects_loading: false,
	tested_projects_loading: false,
	concepts: [],
	employee_concepts: [],
	invoices: [],
	subscriptions: [],
	publishersInvoicePayTime: [],
	employeePromotions: [],
	promotions: [],
	trainingProjects: [],
	vacations: [],
	todayVacations: [],
	vacationLockedPeriods: [],
	sicknesses: [],
	todaySicknesses: [],
	employeeCheckins: [],
	workchekins: [],
	visits: [],
	ideas: [],
	parties: [],
	memberParties: [],
	todolists: [],
	kpi_cards: [],
	surveys: [],
	weekly_monthly_surveys: [],
	project_surveys: [],
	employee_project_surveys: [],
	project_surveys_loading: false,
	project_surveys_month: MonthsOptions.twoMonths,
	party_surveys: [],
	companyStat: undefined,
	employeeYearStatistic: undefined,
	mentorsStat: { mentors: [], art_mentors: [] }
}

export default function reducer(state = initialState, actions) {
	switch (actions.type) {
		case types.SET_DEFAULT_STATE:
			return { ...initialState, notifications: state.notifications }

		case types.LOAD_EMPLOYEES_REQUEST:
		case types.GET_EMPLOYEE_REQUEST:
		case types.LOAD_PROMOTIONS_REQUEST:
		case types.LOAD_EMPLOYEE_PROMOTIONS_REQUEST:
		case types.LOAD_CONCEPTS_REQUEST:
		case types.LOAD_TRAINING_PROJECTS_REQUEST:
		case types.LOAD_PROJECTS_REQUEST:
		case types.GET_MEMBERS_PROJECT_COUNT_REQUEST:
		case types.LOAD_PUBLISHER_PROJECTS_REQUEST:
		case types.LOAD_PROJECT_SURVEY_REQUEST:
		case types.LOAD_PUBLISHERS_REQUEST:
		case types.GET_PUBLISHER_REQUEST:
		case types.PROTOTYPES_LIST_UPDATE_SUMM_REQUEST:
		case types.LOAD_FINANCE_OPERATIONS_BY_MONTH_REQUEST:
		case types.LOAD_FINANCE_OPERATIONS_BY_YEAR_REQUEST:
		case types.LOAD_BALANCE_BY_MONTH_REQUEST:
		case types.CREATE_INTER_BALANCE_TRANSACTION_REQUEST:
		case types.LOAD_DIVIDENDS_REQUEST:
		case types.LOAD_VACATIONS_REQUEST:
		case types.LOAD_VACATION_PERIODS_REQUEST:
		case types.LOAD_SICKNESSES_REQUEST:
		case types.LOAD_EMPLOYEE_YEAR_STATISTIC_REQUEST:
		case types.LOAD_EMPLOYEES_YEAR_STATISTIC_REQUEST:
		case types.FORCE_EMPLOYEE_STAT_REQUEST:
		case types.LOAD_COMPANY_STAT_REQUEST:
		case types.LOAD_WORK_CHECKINS_REQUEST:
		case types.LOAD_MONTH_CHECKINS_REQUEST:
		case types.LOAD_EMPLOYEE_WORK_CHECKINS_REQUEST:
		case types.LOAD_VISITS_REQUEST:
		case types.LOAD_IDEAS_REQUEST:
		case types.LOAD_EMPLOYEE_IDEAS_REQUEST:
		case types.LOAD_WEEKLY_MONTHLY_SURVEYS_REQUEST:
		case types.LOAD_PARTY_SURVEYS_REQUEST:
		case types.LOAD_PARTIES_REQUEST:
		case types.LOAD_MEMBER_PARTIES_REQUEST:
		case types.GET_PROJECT_REQUEST:
		case types.GET_CONCEPT_REQUEST:
		case types.LOAD_TODAY_SICKNESSES_REQUEST:
		case types.LOAD_TODAY_VACATIONS_REQUEST:
		case types.LOAD_EMPLOYEE_VACATIONS_REQUEST:
		case types.LOAD_SHORT_EMPLOYEES_REQUEST:
		case types.LOAD_TODOLISTS_REQUEST:
		case types.LOAD_SYSTEM_SETTINGS_REQUEST:
		case types.LOAD_EMPLOYEE_NOTIFICATIONS_REQUEST:
		case types.UPDATE_NOTIFICATION_STATUS_REQUEST:
		case types.LOAD_SUBSCRIPTIONS_REQUEST:
			return {
				...state,
				isLoad: false
			}

		case types.LOAD_MENTORS_STAT_REQUEST:
		case types.FORCE_COMPANY_STAT_REQUEST:
		case types.LOAD_ARCHIVED_IDEAS_REQUEST:
		case types.GET_ARCHIVED_CONCEPTS_REQUEST:
		case types.REGISTER_EMPLOYEE_REQUEST:
		case types.UPDATE_INFO_EMPLOYEE_REQUEST:
		case types.UPDATE_PROMOTION_INFO_EMPLOYEE_REQUEST:
		case types.UPLOAD_EMPLOYEE_PROFILE_PHOTO_REQUEST:
		case types.DELETE_EMPLOYEE_PROFILE_PHOTO_REQUEST:
		case types.ARCHIVE_EMPLOYEE_REQUEST:
		case types.CREATE_PROMOTION_REQUEST:
		case types.REPEAT_PROMOTION_REQUEST:
		case types.SEND_REQUEST_PROMOTION_REQUEST:
		case types.AGREE_PROMOTION_REQUEST:
		case types.REJECT_PROMOTION_REQUEST:
		case types.DELETE_PROMOTION_REQUEST:
		case types.LOAD_EMPLOYEE_CONCEPTS_REQUEST:
		case types.CREATE_CONCEPT_REQUEST:
		case types.APPROVE_CONCEPT_REQUEST:
		case types.REJECT_CONCEPT_REQUEST:
		case types.PIN_CONCEPT_REQUEST:
		case types.ARCHIVE_CONCEPT_REQUEST:
		case types.UNARCHIVE_CONCEPT_REQUEST:
		case types.CREATE_TRAINING_PROJECT_REQUEST:
		case types.END_TRAINING_PROJECT_REQUEST:
		case types.SETUP_CONCEPT_ART_REQUEST:
		case types.UNSET_CONCEPT_ART_REQUEST:
		case types.TAKE_CONCEPT_ART_REQUEST:
		case types.UPLOAD_CONCEPT_ART_REQUEST:
		case types.DONE_CONCEPT_ART_REQUEST:
		case types.DELETE_CONCEPT_ART_FILES_REQUEST:
		case types.LOAD_EMPLOYEE_PROJECTS_REQUEST:
		case types.LOAD_PROJECTS_EMPLOYEES_REQUEST:
		case types.CREATE_PROJECT_REQUEST:
		case types.UPDATE_PROJECT_REQUEST:
		case types.ADD_GDD_PROJECT_REQUEST:
		case types.TECH_SETUP_PROJECT_REQUEST:
		case types.ADD_RATING_PROJECT_REQUEST:
		case types.MVP_DONE_PROJECT_REQUEST:
		case types.QUALITY_APPROVE_PROJECT_REQUEST:
		case types.RELEASE_DONE_PROJECT_REQUEST:
		case types.PAUSE_BURN_RATE_PROJECT_REQUEST:
		case types.CONTINUE_BURN_RATE_PROJECT_REQUEST:
		case types.DELETE_PROJECT_REQUEST:
		case types.CREATE_PROJECT_VISIT_CHECK_REQUEST:
		case types.CREATE_CONCEPT_VISIT_CHECK_REQUEST:
		case types.FIX_PROJECT_VISITS_REQUEST:
		case types.ADD_PUBLISHER_REQUEST:
		case types.UPDATE_PUBLISHERS_EMPLOYEES_REQUEST:
		case types.ARCHIVE_PUBLISHER_REQUEST:
		case types.SEND_PROJECT_RESULTS_REQUEST:
		case types.CREATE_FINANCE_OPERATION_REQUEST:
		case types.APPROVE_FINANCE_OPERATION_REQUEST:
		case types.REJECT_FINANCE_OPERATION_REQUEST:
		case types.CHECK_FINANCE_OPERATION_REQUEST:
		case types.DELETE_FINANCE_OPERATION_REQUEST:
		case types.UPLOAD_EXPENSE_FILE_REQUEST:
		case types.DOWNLOAD_EXPENSE_FILE_REQUEST:
		case types.DELETE_EXPENSE_FILE_REQUEST:
		case types.WITHDRAW_DIVIDENDS_REQUEST:
		case types.DISTRIBUTE_FOUNDERS_DIVIDENDS_REQUEST:
		case types.CREATE_VACATION_REQUEST:
		case types.UPDATE_VACATION_REQUEST:
		case types.DELETE_VACATION_REQUEST:
		case types.CREATE_VACATION_PERIOD_REQUEST:
		case types.DELETE_VACATION_PERIOD_REQUEST:
		case types.RESOLVE_CONFLICTED_VACATIONS_REQUEST:
		case types.CREATE_SICKNESS_REQUEST:
		case types.LOAD_EMPLOYEE_SICKNESSES_REQUEST:
		case types.UPDATE_SICKNESS_REQUEST:
		case types.DELETE_SICKNESS_REQUEST:
		case types.UPLOAD_SICKNESS_FILE_REQUEST:
		case types.DELETE_SICKNESS_FILE_REQUEST:
		case types.LOAD_INVOICES_REQUEST:
		case types.ADD_PROJECTS_TO_INVOICE_REQUEST:
		case types.CREATE_INVOICE_REQUEST:
		case types.FORMAT_INVOICE_REQUEST:
		case types.SEND_INVOICE_REQUEST:
		case types.PAY_INVOICE_REQUEST:
		case types.DELETE_INVOICE_REQUEST:
		case types.UPLOAD_INVOICE_FILE_REQUEST:
		case types.DOWNLOAD_INVOICE_FILE_REQUEST:
		case types.DELETE_INVOICE_FILE_REQUEST:
		case types.ADD_WORK_CHECKIN_REQUEST:
		case types.UPDATE_WORK_CHECKIN_REQUEST:
		case types.ARCHIVE_IDEA_REQUEST:
		case types.UNARCHIVE_IDEA_REQUEST:
		case types.DELETE_IDEA_REQUEST:
		case types.CREATE_PARTY_REQUEST:
		case types.CONFIRM_PARTY_REQUEST:
		case types.UPDATE_PARTY_REQUEST:
		case types.SEND_INVITATION_ANSWER_REQUEST:
		case types.DONE_PARTY_REQUEST:
		case types.DELETE_PARTY_REQUEST:
		case types.GET_EMPLOYEE_TODOLIST_REQUEST:
		case types.UPDATE_TODO_STATUS_REQUEST:
		case types.ADD_TODO_REQUEST:
		case types.NO_TODOS_REQUEST:
		case types.LOAD_KPI_REQUEST:
		case types.LOAD_EMPLOYEE_KPI_REQUEST:
		case types.LOAD_DEDLINED_KPI_REQUEST:
		case types.CREATE_KPI_REQUEST:
		case types.CHECK_KPI_REQUEST:
		case types.DONE_KPI_REQUEST:
		case types.DELETE_KPI_REQUEST:
		case types.UPDATE_SYSTEM_SETTINGS_REQUEST:
		case types.CREATE_SUBSCRIPTION_REQUEST:
		case types.UPDATE_SUBSCRIPTION_REQUEST:
		case types.ARCHIVE_SUBSCRIPTION_REQUEST:
		case types.UNARCHIVE_SUBSCRIPTION_REQUEST:
		case types.DELETE_SUBSCRIPTION_REQUEST:
			return {
				...state,
				loading: true ?? false
			}

		//SUCCESS

		//employees
		case types.LOAD_EMPLOYEES_SUCCESS:
		case types.LOAD_SHORT_EMPLOYEES_SUCCESS:
			return {
				...state,
				employees: actions.payload ?? [],
				loading: false,
				isLoad: true
			}

		case types.GET_EMPLOYEE_SUCCESS:
			return {
				...state,
				employees: [actions.payload],
				loading: false,
				isLoad: true
			}

		case types.REGISTER_EMPLOYEE_SUCCESS:
			return {
				...state,
				employees: [actions.payload, ...state.employees],
				loading: false,
				isLoad: true
			}

		case types.UPDATE_INFO_EMPLOYEE_SUCCESS:
			return {
				...state,
				employees: state.employees.map((employee: Employee) => {
					if (employee._id === actions.payload._id) {
						return actions.payload
					}
					return employee
				}),
				loading: false,
				isLoad: true
			}

		case types.UPDATE_PROMOTION_INFO_EMPLOYEE_SUCCESS:
		case types.UPLOAD_EMPLOYEE_PROFILE_PHOTO_SUCCESS:
		case types.DELETE_EMPLOYEE_PROFILE_PHOTO_SUCCESS:
		case types.ARCHIVE_EMPLOYEE_SUCCESS:
			return {
				...state,
				employees: state.employees.map((employee: Employee) => {
					if (employee._id === actions.payload._id) {
						return actions.payload
					}
					return employee
				}),
				loading: false,
				isLoad: true
			}

		//promotion
		case types.LOAD_PROMOTIONS_SUCCESS:
			return {
				...state,
				promotions: actions.payload ?? [],
				loading: false,
				isLoad: true
			}

		case types.LOAD_EMPLOYEE_PROMOTIONS_SUCCESS:
			return {
				...state,
				employeePromotions: actions.payload ?? [],
				loading: false,
				isLoad: true
			}

		case types.CREATE_PROMOTION_SUCCESS:
			return {
				...state,
				promotions: [actions.payload, ...state.promotions],
				employees: state.employees.map((employee: Employee) => {
					if (employee._id === actions.payload.user._id) {
						return {
							...employee,
							salary: actions.payload.new_salary
						}
					}
					return employee
				}),
				loading: false,
				isLoad: true
			}

		case types.REPEAT_PROMOTION_SUCCESS:
			return {
				...state,
				employeePromotions: state.employeePromotions.map((promotion: Promotion) => {
					if (promotion._id === actions.payload._id) {
						return actions.payload
					}
					return promotion
				}),
				loading: false,
				isLoad: true
			}

		case types.SEND_REQUEST_PROMOTION_SUCCESS:
			return {
				...state,
				employeePromotions: [actions.payload, ...state.employeePromotions],
				loading: false,
				isLoad: true
			}

		case types.AGREE_PROMOTION_SUCCESS:
		case types.REJECT_PROMOTION_SUCCESS:
			return {
				...state,
				promotions: state.promotions.map((promotion: Promotion) => {
					if (promotion._id === actions.payload._id) {
						return actions.payload
					}
					return promotion
				}),
				loading: false,
				isLoad: true
			}

		case types.DELETE_PROMOTION_SUCCESS:
			return {
				...state,
				promotions: state.promotions.filter(
					(promotion: Promotion) => promotion._id !== actions.payload
				),
				loading: false,
				isLoad: true
			}

		//concepts
		case types.LOAD_CONCEPTS_SUCCESS:
			return {
				...state,
				concepts: actions.payload ?? [],
				loading: false,
				isLoad: true
			}

		case types.LOAD_EMPLOYEE_CONCEPTS_SUCCESS:
			return {
				...state,
				employee_concepts: actions.payload ?? [],
				loading: false
			}

		case types.GET_ARCHIVED_CONCEPTS_SUCCESS:
			const filteredActiveConcept = state.concepts.filter(
				(concept: Concept) =>
					concept.status !== ConceptStatusEnum.archived
			)

			return {
				...state,
				concepts: [...filteredActiveConcept, ...actions.payload],
				loading: false,
				isLoad: true
			}

		case types.GET_CONCEPT_SUCCESS:
			return {
				...state,
				concepts: [actions.payload],
				loading: false,
				isLoad: true
			}

		case types.CREATE_CONCEPT_SUCCESS:
			return {
				...state,
				concepts: [actions.payload, ...state.concepts],
				loading: false,
				isLoad: true
			}

		case types.APPROVE_CONCEPT_SUCCESS:
		case types.REJECT_CONCEPT_SUCCESS:
		case types.PIN_CONCEPT_SUCCESS:
		case types.ARCHIVE_CONCEPT_SUCCESS:
		case types.UNARCHIVE_CONCEPT_SUCCESS:
			return {
				...state,
				concepts: state.concepts.map((concept: Concept) => {
					if (concept._id === actions.payload._id) {
						return actions.payload
					}
					return concept
				}),
				loading: false,
				isLoad: true
			}

		// training projects
		case types.LOAD_TRAINING_PROJECTS_SUCCESS:
			return {
				...state,
				trainingProjects: actions.payload ?? [],
				loading: false,
				isLoad: true
			}

		case types.CREATE_TRAINING_PROJECT_SUCCESS:
			return {
				...state,
				trainingProjects: [...state.trainingProjects, actions.payload],
				loading: false,
				isLoad: true
			}

		case types.END_TRAINING_PROJECT_SUCCESS:
			return {
				...state,
				trainingProjects: state.trainingProjects.map((trainingProject: TrainingProject) => {
					if (trainingProject._id === actions.payload._id) {
						return actions.payload
					}
					return trainingProject
				}),
				loading: false,
				isLoad: true
			}

		//concept-art
		case types.SETUP_CONCEPT_ART_SUCCESS:
		case types.UNSET_CONCEPT_ART_SUCCESS:
		case types.TAKE_CONCEPT_ART_SUCCESS:
			return {
				...state,
				concepts: state.concepts.map((concept: Concept) => {
					if (actions.payload._id === concept.art_info._id) {
						concept.art_info = actions.payload
					}

					return concept
				}),
				projects: state.projects.map((project: Project) => {
					if (project.art_info._id === actions.payload._id) {
						project.concept_artist = actions.payload.concept_artist
						project.art_info = actions.payload
					}

					return project
				}),
				loading: false,
				isLoad: true
			}

		case types.UPLOAD_CONCEPT_ART_SUCCESS:
		case types.DELETE_CONCEPT_ART_FILES_SUCCESS:
		case types.DONE_CONCEPT_ART_SUCCESS:
			return {
				...state,
				concepts: state.concepts.map((concept: Concept) => {
					if (concept.art_info._id === actions.payload._id) {
						concept.art_info = actions.payload
					}
					return concept
				}),

				projects: state.projects.map((project: Project) => {
					if (project.art_info._id === actions.payload._id) {
						project.art_info = actions.payload
						project.concept_artist = actions.payload.concept_artist
					}
					return project
				}),
				loading: false,
				isLoad: true
			}

		//projects
		case types.LOAD_PROJECTS_SUCCESS:
		case types.LOAD_PUBLISHER_PROJECTS_SUCCESS:
			return {
				...state,
				projects: actions.payload ?? [],
				loading: false,
				isLoad: true
			}

		case types.LOAD_EMPLOYEE_PROJECTS_SUCCESS:
			return {
				...state,
				employee_projects: actions.payload ?? [],
				loading: false
			}

		case types.GET_PROJECT_SUCCESS:
			return {
				...state,
				concepts: [],
				projects: [actions.payload],
				loading: false,
				isLoad: true
			}

		case types.GET_IN_TESTING_PROJECTS_REQUEST:
			return {
				...state,
				in_testing_projects_loading: true
			}
		case types.GET_TESTED_PROJECTS_REQUEST:
			return {
				...state,
				tested_projects_loading: true
			}

		case types.GET_IN_TESTING_PROJECTS_SUCCESS:
			const notInTestingProjects = state.projects.filter(
				(project: Project) => project.dev_state !== DevStateEnum.in_testing
			)

			return {
				...state,
				in_testing_projects_loading: false,
				projects: [...notInTestingProjects, ...actions.payload.projects],
				in_testing_projects_month: actions.payload.months,
				loading: false,
				isLoad: true
			}

		case types.GET_TESTED_PROJECTS_SUCCESS:
			const notTestedProjects = state.projects.filter(
				(project: Project) => project.dev_state !== DevStateEnum.tested
			)

			return {
				...state,
				tested_projects_loading: false,
				projects: [...notTestedProjects, ...actions.payload.projects],
				tested_projects_month: actions.payload.months,
				loading: false,
				isLoad: true
			}

		case types.GET_IN_TESTING_PROJECTS_ERROR:
			return {
				...state,
				in_testing_projects_loading: false,
				error: true
			}

		case types.GET_TESTED_PROJECTS_ERROR:
			return {
				...state,
				tested_projects_loading: false,
				error: true
			}

		case types.CREATE_PROJECT_SUCCESS:
			return {
				...state,
				concepts: state.concepts.map((concept: Concept) => {
					if (concept._id === actions.payload.conceptId) {
						return {
							...concept,
							status: ConceptStatusEnum.used
						}
					}
					return concept
				}),

				projects: actions.payload.previousProjectId
					? state.projects.map((project: Project) => {
							if (project._id === actions.payload.previousProjectId) {
								const updates =
									actions.payload.newProject.project_type ===
									ProjectTypeEnum.subtask
										? {
												subtasks: [
													...(project.subtasks ? project.subtasks : []),
													actions.payload.newProject
												]
										  }
										: {
												next_iteration:
													actions.payload.previousProject.next_iteration
										  }

								return {
									...project,
									...updates
								}
							}
							return project
					  })
					: [actions.payload.newProject, ...state.projects],

				loading: false,
				isLoad: true
			}

		case types.UPDATE_PROJECT_SUCCESS:
		case types.ADD_GDD_PROJECT_SUCCESS:
		case types.TECH_SETUP_PROJECT_SUCCESS:
		case types.ADD_RATING_PROJECT_SUCCESS:
		case types.MVP_DONE_PROJECT_SUCCESS:
		case types.QUALITY_APPROVE_PROJECT_SUCCESS:
		case types.RELEASE_DONE_PROJECT_SUCCESS:
		case types.PAUSE_BURN_RATE_PROJECT_SUCCESS:
		case types.CONTINUE_BURN_RATE_PROJECT_SUCCESS:
		case types.SEND_PROJECT_RESULTS_SUCCESS:
		case types.PROTOTYPES_LIST_UPDATE_SUMM_SUCCESS:
			return {
				...state,
				projects: state.projects.map((project: Project) => {
					if (project._id === actions.payload._id) {
						return actions.payload
					}

					return project
				}),
				loading: false,
				isLoad: true
			}

		case types.DELETE_PROJECT_SUCCESS:
			return {
				...state,
				projects: state.projects.filter(
					(project: Project) => project._id !== actions.payload
				),
				loading: false,
				isLoad: true
			}

		case types.LOAD_SHORT_PROJECTS_REQUEST:
			return {
				...state,
				loading: true,
				projects: actions.payload
			}

		case types.LOAD_SHORT_PROJECTS_SUCCESS:
			return {
				...state,
				loading: false,
				projects: actions.payload
			}
		case types.LOAD_SHORT_PROJECTS_ERROR:
			return {
				...state,
				loading: false,
				error: true
			}

		//visits
		case types.CREATE_PROJECT_VISIT_CHECK_SUCCESS:
		case types.FIX_PROJECT_VISITS_SUCCESS:
			return {
				...state,
				projects: state.projects.map((project: Project) => {
					if (project._id === actions.payload.project) {
						project.visits = actions.payload.visits
					}

					return project
				}),
				loading: false,
				isLoad: true
			}

		case types.CREATE_CONCEPT_VISIT_CHECK_SUCCESS:
			return {
				...state,
				concepts: state.concepts.map((concept: Concept) => {
					if (concept._id === actions.payload.concept) {
						concept.visits = actions.payload.visits
					}

					return concept
				}),
				loading: false,
				isLoad: true
			}

		//project survey
		case types.LOAD_EMPLOYEE_PROJECT_SURVEYS_REQUEST:
			return {
				...state,
				loading: true
			}

		case types.LOAD_EMPLOYEE_PROJECT_SURVEYS_SUCCESS:
			return {
				...state,
				employee_project_surveys: actions.payload,
				loading: false
			}

		case types.LOAD_EMPLOYEE_PROJECT_SURVEYS_ERROR:
			return {
				...state,
				loading: false
			}

		case types.LOAD_PROJECT_SURVEY_SUCCESS:
			return {
				...state,
				projects: state.projects.map((project: Project) => {
					if (project.survey?._id === actions.payload._id) {
						project.survey = actions.payload
					}
					return project
				}),
				loading: false,
				isLoad: true
			}

		//publishers
		case types.LOAD_PUBLISHERS_SUCCESS:
			return {
				...state,
				publishers: actions.payload,
				loading: false,
				isLoad: true
			}

		case types.GET_PUBLISHER_SUCCESS:
			return {
				...state,
				publishers: [actions.payload],
				loading: false,
				isLoad: true
			}

		case types.LOAD_SHORT_PUBLISHERS_REQUEST:
			return {
				...state,
				loading: true
			}

		case types.LOAD_SHORT_PUBLISHERS_SUCCESS:
			return {
				...state,
				publishers: actions.payload,
				loading: false
			}

		case types.LOAD_SHORT_PUBLISHERS_ERROR:
			return {
				...state,
				loading: false
			}

		case types.ADD_PUBLISHER_SUCCESS:
			return {
				...state,
				publishers: [actions.payload, ...state.publishers],
				loading: false,
				isLoad: true
			}

		case types.UPDATE_PUBLISHERS_EMPLOYEES_SUCCESS:
			return {
				...state,
				publishers: state.publishers.map((publisher: Publisher) => {
					if (publisher._id === actions.payload.publisher._id) {
						return actions.payload.publisher
					}

					return publisher
				}),
				notifications: [...actions.payload.notifications, ...state.notifications],
				loading: false,
				isLoad: true
			}

		case types.ARCHIVE_PUBLISHER_SUCCESS:
			return {
				...state,
				publishers: state.publishers.map((publisher: Publisher) => {
					if (publisher._id === actions.payload._id) {
						return actions.payload
					}

					return publisher
				}),
				loading: false,
				isLoad: true
			}

		case types.STORE_PUBLISHERS_INVOICE_PAY_TIME:
			return {
				...state,
				publishersInvoicePayTime: actions.payload ?? []
			}

		//Vacations

		case types.LOAD_VACATIONS_SUCCESS:
		case types.LOAD_EMPLOYEE_VACATIONS_SUCCESS:
		case types.RESOLVE_CONFLICTED_VACATIONS_SUCCESS:
			return {
				...state,
				vacations: actions.payload ?? [],
				loading: false,
				isLoad: true
			}

		case types.LOAD_TODAY_VACATIONS_SUCCESS:
			return {
				...state,
				todayVacations: actions.payload ?? [],
				loading: false,
				isLoad: true
			}

		case types.CREATE_VACATION_SUCCESS:
			return {
				...state,
				vacations: [actions.payload, ...state.vacations],
				loading: false,
				isLoad: true
			}

		case types.UPDATE_VACATION_SUCCESS:
			return {
				...state,
				vacations: state.vacations.map((vacation: Vacation) => {
					if (vacation._id === actions.payload._id) {
						return actions.payload
					}
					return vacation
				}),
				loading: false,
				isLoad: true
			}

		case types.DELETE_VACATION_SUCCESS:
			return {
				...state,
				vacations: state.vacations.filter(
					(vacation: Vacation) => vacation._id !== actions.payload._id
				),
				loading: false,
				isLoad: true
			}

		//vacation periods

		case types.LOAD_VACATION_PERIODS_SUCCESS:
			return {
				...state,
				vacationLockedPeriods: actions.payload ?? [],
				loading: false,
				isLoad: true
			}

		case types.CREATE_VACATION_PERIOD_SUCCESS:
			return {
				...state,
				vacationLockedPeriods: [actions.payload, ...state.vacationLockedPeriods],
				loading: false,
				isLoad: true
			}

		case types.DELETE_VACATION_PERIOD_SUCCESS:
			return {
				...state,
				vacations: state.vacationLockedPeriods.filter(
					(period: VacationLockedPeriods) => period._id !== actions.payload._id
				),
				loading: false,
				isLoad: true
			}

		//sicknesses

		case types.LOAD_SICKNESSES_SUCCESS:
		case types.LOAD_EMPLOYEE_SICKNESSES_SUCCESS:
			return {
				...state,
				sicknesses: actions.payload ?? [],
				loading: false,
				isLoad: true
			}

		case types.LOAD_TODAY_SICKNESSES_SUCCESS:
			return {
				...state,
				todaySicknesses: actions.payload ?? [],
				loading: false,
				isLoad: true
			}

		case types.CREATE_SICKNESS_SUCCESS:
			return {
				...state,
				sicknesses: [actions.payload, ...state.sicknesses],
				loading: false,
				isLoad: true
			}

		case types.UPDATE_SICKNESS_SUCCESS:
		case types.UPLOAD_SICKNESS_FILE_SUCCESS:
		case types.DELETE_SICKNESS_FILE_SUCCESS:
			return {
				...state,
				sicknesses: state.sicknesses.map((sickness: Sickness) => {
					if (sickness._id === actions.payload._id) {
						return actions.payload
					}
					return sickness
				}),
				loading: false,
				isLoad: true
			}

		case types.DELETE_SICKNESS_SUCCESS:
			return {
				...state,
				sicknesses: state.sicknesses.filter(
					(sickness: Sickness) => sickness._id !== actions.payload._id
				),
				loading: false,
				isLoad: true
			}

		//invoices

		case types.LOAD_INVOICES_SUCCESS:
			return {
				...state,
				invoices: actions.payload ?? [],
				loading: false,
				isLoad: true
			}

		case types.CREATE_INVOICE_SUCCESS:
			return {
				...state,
				invoices: [actions.payload, ...state.invoices],
				loading: false,
				isLoad: true
			}

		case types.ADD_PROJECTS_TO_INVOICE_SUCCESS:
		case types.FORMAT_INVOICE_SUCCESS:
		case types.SEND_INVOICE_SUCCESS:
		case types.PAY_INVOICE_SUCCESS:
		case types.UPLOAD_INVOICE_FILE_SUCCESS:
		case types.DELETE_INVOICE_FILE_SUCCESS:
			return {
				...state,
				invoices: state.invoices.map((invoice: Invoice) => {
					if (invoice._id === actions.payload._id) {
						return actions.payload
					}
					return invoice
				}),
				loading: false,
				isLoad: true
			}

		case types.DELETE_INVOICE_SUCCESS:
			return {
				...state,
				invoices: state.invoices.filter(
					(invoice: Invoice) => invoice._id !== actions.payload
				),
				loading: false,
				isLoad: true
			}

		case types.DOWNLOAD_INVOICE_FILE_SUCCESS:
			return {
				...state,
				loading: false,
				isLoad: true
			}

		//Subscriptions
		case types.LOAD_SUBSCRIPTIONS_SUCCESS:
			return {
				...state,
				loading: false,
				isLoad: true,
				subscriptions: actions.payload ?? []
			}

		case types.CREATE_SUBSCRIPTION_SUCCESS:
			return {
				...state,
				subscriptions: [actions.payload, ...state.subscriptions],
				loading: false,
				isLoad: true
			}

		case types.UPDATE_SUBSCRIPTION_SUCCESS:
		case types.ARCHIVE_SUBSCRIPTION_SUCCESS:
		case types.UNARCHIVE_SUBSCRIPTION_SUCCESS:
			return {
				...state,
				subscriptions: state.subscriptions.map((subscription: Subscription) => {
					if (subscription._id === actions.payload._id) {
						return actions.payload
					}
					return subscription
				}),
				loading: false,
				isLoad: true
			}

		case types.DELETE_SUBSCRIPTION_SUCCESS:
			return {
				...state,
				subscriptions: state.subscriptions.filter(
					(subscription: Subscription) => subscription._id !== actions.payload._id
				),
				loading: false,
				isLoad: true
			}

		//Statistic

		case types.FORCE_EMPLOYEE_STAT_SUCCESS:
		case types.LOAD_EMPLOYEES_YEAR_STATISTIC_SUCCESS:
		case types.LOAD_EMPLOYEE_YEAR_STATISTIC_SUCCESS:
			return {
				...state,
				loading: false,
				isLoad: true,
				employeeYearStatistic: actions.payload ?? []
			}

		case types.LOAD_COMPANY_STAT_SUCCESS:
			return {
				...state,
				isLoad: true,
				loading: false,
				companyStat: actions.payload
			}

		case types.LOAD_MENTORS_STAT_SUCCESS:
			return {
				...state,
				isLoad: true,
				loading: false,
				mentorsStat: actions.payload
			}

		case types.FORCE_COMPANY_STAT_SUCCESS:
			return {
				...state,
				loading: false,
				isLoad: true,
				companyStat: []
			}

		// Time tracking

		case types.LOAD_WORK_CHECKINS_SUCCESS:
		case types.LOAD_MONTH_CHECKINS_SUCCESS:
			return {
				...state,
				workchekins: actions.payload ?? [],
				loading: false,
				isLoad: true
			}

		case types.LOAD_EMPLOYEE_WORK_CHECKINS_SUCCESS:
			return {
				...state,
				employeeCheckins: actions.payload ?? [],
				loading: false,
				isLoad: true
			}

		case types.ADD_WORK_CHECKIN_SUCCESS:
			return {
				...state,
				workchekins: [actions.payload, ...state.workchekins],
				loading: false,
				isLoad: true
			}

		case types.UPDATE_WORK_CHECKIN_SUCCESS:
			return {
				...state,
				workchekins: state.workchekins.map((checkin: WorkCheckin) => {
					if (actions.payload._id === checkin._id) {
						return actions.payload
					}
					return checkin
				}),
				loading: false,
				isLoad: true
			}

		//Visits

		case types.LOAD_VISITS_SUCCESS:
			return {
				...state,
				visits: actions.payload ?? [],
				loading: false,
				isLoad: true
			}

		//Ideas

		case types.LOAD_IDEAS_SUCCESS:
		case types.LOAD_EMPLOYEE_IDEAS_SUCCESS:
			return {
				...state,
				ideas: actions.payload ?? [],
				loading: false,
				isLoad: true
			}

		case types.LOAD_ARCHIVED_IDEAS_SUCCESS:
			const filteredActiveIdeas = state.ideas.filter(
				(idea: Idea) => idea.status === IdeaStatusEnum.active
			)

			return {
				...state,
				ideas: [...filteredActiveIdeas, ...actions.payload],
				loading: false
			}

		case types.CREATE_IDEA_REQUEST:
		case types.USE_IDEA_REQUEST:
			return {
				...state,
				loading: true
			}

		case types.CREATE_IDEA_SUCCESS:
			return {
				...state,
				ideas: [...state.ideas, actions.payload],
				loading: false,
				isLoad: true
			}

		case types.CREATE_IDEA_ERROR:
		case types.USE_IDEA_ERROR:
			return {
				...state,
				loading: false,
				error: true
			}

		case types.USE_IDEA_SUCCESS:
		case types.ARCHIVE_IDEA_SUCCESS:
		case types.UNARCHIVE_IDEA_SUCCESS:
			return {
				...state,
				ideas: state.ideas.map((idea: Idea) => {
					if (idea._id === actions.payload._id) {
						return actions.payload
					}
					return idea
				}),
				loading: false,
				isLoad: true
			}

		case types.DELETE_IDEA_SUCCESS:
			return {
				...state,
				ideas: state.ideas.filter((idea: Idea) => idea._id !== actions.payload._id),
				loading: false,
				isLoad: true
			}

		//surveys
		case types.LOAD_PROJECT_SURVEYS_REQUEST:
			return {
				...state,
				project_surveys_loading: true
			}

		case types.LOAD_PROJECT_SURVEYS_SUCCESS:
			return {
				...state,
				project_surveys: actions.payload.surveys,
				project_surveys_month: actions.payload.months,
				project_surveys_loading: false
			}

		case types.LOAD_PROJECT_SURVEYS_ERROR:
			return {
				project_surveys_loading: false
			}

		case types.LOAD_WEEKLY_MONTHLY_SURVEYS_SUCCESS:
			return {
				...state,
				weekly_monthly_surveys: actions.payload,
				loading: false,
				isLoad: true
			}

		case types.LOAD_PARTY_SURVEYS_SUCCESS:
			return {
				...state,
				party_surveys: actions.payload,
				loading: false,
				isLoad: true
			}

		// Parties

		case types.LOAD_PARTIES_SUCCESS:
			return {
				...state,
				parties: actions.payload ?? [],
				loading: false,
				isLoad: true
			}

		case types.LOAD_MEMBER_PARTIES_SUCCESS:
			return {
				...state,
				memberParties: actions.payload ?? [],
				loading: false,
				isLoad: true
			}

		case types.CREATE_PARTY_SUCCESS:
			return {
				...state,
				parties: [actions.payload, ...state.parties],
				loading: false,
				isLoad: true
			}

		case types.CONFIRM_PARTY_SUCCESS:
		case types.UPDATE_PARTY_SUCCESS:
		case types.DONE_PARTY_SUCCESS:
			return {
				...state,
				parties: state.parties.map((party: Party) => {
					if (party._id === actions.payload._id) {
						return actions.payload
					}
					return party
				}),
				loading: false,
				isLoad: true
			}

		case types.SEND_INVITATION_ANSWER_SUCCESS:
			return {
				...state,
				memberParties: state.memberParties.map((party: Party) => {
					if (party._id === actions.payload._id) {
						return actions.payload
					}
					return party
				}),
				loading: false,
				isLoad: true
			}

		case types.DELETE_PARTY_SUCCESS:
			return {
				...state,
				parties: state.parties.filter((party: Party) => party._id !== actions.payload._id),
				loading: false,
				isLoad: true
			}

		//todolists
		case types.LOAD_TODOLISTS_SUCCESS:
			return {
				...state,
				todolists: actions.payload,
				loading: false,
				isLoad: true
			}

		case types.GET_EMPLOYEE_TODOLIST_SUCCESS:
		case types.NO_TODOS_SUCCESS:
			return {
				...state,
				todolists: [actions.payload],
				loading: false,
				isLoad: true
			}

		case types.ADD_TODO_SUCCESS:
			return {
				...state,
				todolists: state.todolists.map((todolist: Todolist) => {
					if (todolist._id === actions.payload._id) {
						return actions.payload
					}
					return todolist
				}),
				loading: false,
				isLoad: true
			}

		case types.UPDATE_TODO_STATUS_SUCCESS:
			return {
				...state,
				todolists: state.todolists.map((todolist: Todolist) => {
					if (todolist._id === actions.payload._id) {
						return actions.payload
					}
					return todolist
				}),
				loading: false,
				isLoad: true
			}

		//kpi
		case types.LOAD_KPI_SUCCESS:
		case types.LOAD_EMPLOYEE_KPI_SUCCESS:
		case types.LOAD_DEDLINED_KPI_SUCCESS:
			return {
				...state,
				kpi_cards: actions.payload,
				loading: false
			}
		case types.CREATE_KPI_SUCCESS:
			return {
				...state,
				kpi_cards: [...state.kpi_cards, actions.payload],
				loading: false
			}
		case types.CHECK_KPI_SUCCESS:
		case types.DONE_KPI_SUCCESS:
			return {
				...state,
				kpi_cards: state.kpi_cards.map((kpi_card: KPICardType) => {
					if (kpi_card._id === actions.payload._id) {
						return actions.payload
					}

					return kpi_card
				}),
				loading: false
			}
		case types.DELETE_KPI_SUCCESS:
			return {
				...state,
				kpi_cards: state.kpi_cards.filter(
					(kpi_card: KPICardType) => kpi_card._id !== actions.payload._id
				),
				loading: false
			}

		// project count

		case types.GET_MEMBERS_PROJECT_COUNT_SUCCESS:
			return {
				...state,
				project_count: {
					...state.project_count,
					[actions.payload.role]: actions.payload.data
				}
			}

		//system  settings

		case types.LOAD_SYSTEM_SETTINGS_SUCCESS:
		case types.UPDATE_SYSTEM_SETTINGS_SUCCESS:
			return {
				...state,
				system_settings: actions.payload,
				loading: false,
				isLoad: true
			}

		//notifications

		case types.LOAD_EMPLOYEE_NOTIFICATIONS_SUCCESS:
			return {
				...state,
				loading: false,
				notifications: actions.payload
			}

		case types.UPDATE_NOTIFICATION_STATUS_SUCCESS:
			return {
				...state,
				loading: false,
				notifications: state.notifications.filter(
					(notification: SystemNotification) => actions.payload._id !== notification._id
				)
			}

		//projects employees
		case types.LOAD_PROJECTS_EMPLOYEES_SUCCESS:
			return {
				...state,
				isLoad: true,
				projects_employees: actions.payload
			}

		case types.LOAD_EMPLOYEE_YEAR_STATISTIC_ERROR:
		case types.GET_EMPLOYEE_ERROR:
		case types.LOAD_ARCHIVED_IDEAS_ERROR:
		case types.LOAD_EMPLOYEES_ERROR:
		case types.REGISTER_EMPLOYEE_ERROR:
		case types.UPDATE_INFO_EMPLOYEE_ERROR:
		case types.UPDATE_PROMOTION_INFO_EMPLOYEE_ERROR:
		case types.UPLOAD_EMPLOYEE_PROFILE_PHOTO_ERROR:
		case types.DELETE_EMPLOYEE_PROFILE_PHOTO_ERROR:
		case types.ARCHIVE_EMPLOYEE_ERROR:
		case types.LOAD_PROMOTIONS_ERROR:
		case types.LOAD_EMPLOYEE_PROMOTIONS_ERROR:
		case types.CREATE_PROMOTION_ERROR:
		case types.REPEAT_PROMOTION_ERROR:
		case types.SEND_REQUEST_PROMOTION_ERROR:
		case types.AGREE_PROMOTION_ERROR:
		case types.REJECT_PROMOTION_ERROR:
		case types.DELETE_PROMOTION_ERROR:
		case types.LOAD_CONCEPTS_ERROR:
		case types.LOAD_EMPLOYEE_CONCEPTS_ERROR:
		case types.CREATE_CONCEPT_ERROR:
		case types.APPROVE_CONCEPT_ERROR:
		case types.REJECT_CONCEPT_ERROR:
		case types.PIN_CONCEPT_ERROR:
		case types.ARCHIVE_CONCEPT_ERROR:
		case types.UNARCHIVE_CONCEPT_ERROR:
		case types.LOAD_TRAINING_PROJECTS_ERROR:
		case types.CREATE_TRAINING_PROJECT_ERROR:
		case types.END_TRAINING_PROJECT_ERROR:
		case types.SETUP_CONCEPT_ART_ERROR:
		case types.UNSET_CONCEPT_ART_ERROR:
		case types.TAKE_CONCEPT_ART_ERROR:
		case types.UPLOAD_CONCEPT_ART_ERROR:
		case types.DONE_CONCEPT_ART_ERROR:
		case types.DELETE_CONCEPT_ART_FILES_ERROR:
		case types.LOAD_PROJECTS_ERROR:
		case types.LOAD_EMPLOYEE_PROJECTS_ERROR:
		case types.LOAD_PROJECTS_EMPLOYEES_ERROR:
		case types.LOAD_PUBLISHER_PROJECTS_ERROR:
		case types.CREATE_PROJECT_ERROR:
		case types.UPDATE_PROJECT_ERROR:
		case types.ADD_GDD_PROJECT_ERROR:
		case types.TECH_SETUP_PROJECT_ERROR:
		case types.ADD_RATING_PROJECT_ERROR:
		case types.MVP_DONE_PROJECT_ERROR:
		case types.QUALITY_APPROVE_PROJECT_ERROR:
		case types.RELEASE_DONE_PROJECT_ERROR:
		case types.PAUSE_BURN_RATE_PROJECT_ERROR:
		case types.CONTINUE_BURN_RATE_PROJECT_ERROR:
		case types.DELETE_PROJECT_ERROR:
		case types.CREATE_PROJECT_VISIT_CHECK_ERROR:
		case types.CREATE_CONCEPT_VISIT_CHECK_ERROR:
		case types.FIX_PROJECT_VISITS_ERROR:
		case types.LOAD_PROJECT_SURVEY_ERROR:
		case types.LOAD_PUBLISHERS_ERROR:
		case types.GET_PUBLISHER_ERROR:
		case types.ADD_PUBLISHER_ERROR:
		case types.UPDATE_PUBLISHERS_EMPLOYEES_ERROR:
		case types.ARCHIVE_PUBLISHER_ERROR:
		case types.SEND_PROJECT_RESULTS_ERROR:
		case types.LOAD_VACATIONS_ERROR:
		case types.CREATE_VACATION_ERROR:
		case types.UPDATE_VACATION_ERROR:
		case types.DELETE_VACATION_ERROR:
		case types.LOAD_VACATION_PERIODS_ERROR:
		case types.CREATE_VACATION_PERIOD_ERROR:
		case types.DELETE_VACATION_PERIOD_ERROR:
		case types.RESOLVE_CONFLICTED_VACATIONS_ERROR:
		case types.LOAD_SICKNESSES_ERROR:
		case types.LOAD_EMPLOYEE_SICKNESSES_ERROR:
		case types.CREATE_SICKNESS_ERROR:
		case types.UPDATE_SICKNESS_ERROR:
		case types.DELETE_SICKNESS_ERROR:
		case types.UPLOAD_SICKNESS_FILE_ERROR:
		case types.DELETE_SICKNESS_FILE_ERROR:
		case types.LOAD_INVOICES_ERROR:
		case types.ADD_PROJECTS_TO_INVOICE_ERROR:
		case types.CREATE_INVOICE_ERROR:
		case types.FORMAT_INVOICE_ERROR:
		case types.SEND_INVOICE_ERROR:
		case types.PAY_INVOICE_ERROR:
		case types.DELETE_INVOICE_ERROR:
		case types.UPLOAD_INVOICE_FILE_ERROR:
		case types.DOWNLOAD_INVOICE_FILE_ERROR:
		case types.DELETE_INVOICE_FILE_ERROR:
		case types.LOAD_EMPLOYEES_YEAR_STATISTIC_ERROR:
		case types.FORCE_EMPLOYEE_STAT_ERROR:
		case types.LOAD_COMPANY_STAT_ERROR:
		case types.LOAD_MENTORS_STAT_ERROR:
		case types.FORCE_COMPANY_STAT_ERROR:
		case types.LOAD_WORK_CHECKINS_ERROR:
		case types.LOAD_MONTH_CHECKINS_ERROR:
		case types.LOAD_EMPLOYEE_WORK_CHECKINS_ERROR:
		case types.ADD_WORK_CHECKIN_ERROR:
		case types.UPDATE_WORK_CHECKIN_ERROR:
		case types.LOAD_VISITS_ERROR:
		case types.LOAD_IDEAS_ERROR:
		case types.LOAD_EMPLOYEE_IDEAS_ERROR:
		case types.ARCHIVE_IDEA_ERROR:
		case types.UNARCHIVE_IDEA_ERROR:
		case types.DELETE_IDEA_ERROR:
		case types.LOAD_WEEKLY_MONTHLY_SURVEYS_ERROR:
		case types.LOAD_PARTY_SURVEYS_ERROR:
		case types.LOAD_PARTIES_ERROR:
		case types.LOAD_MEMBER_PARTIES_ERROR:
		case types.CREATE_PARTY_ERROR:
		case types.CONFIRM_PARTY_ERROR:
		case types.UPDATE_PARTY_ERROR:
		case types.SEND_INVITATION_ANSWER_ERROR:
		case types.DONE_PARTY_ERROR:
		case types.DELETE_PARTY_ERROR:
		case types.GET_ARCHIVED_CONCEPTS_ERROR:
		case types.GET_CONCEPT_ERROR:
		case types.LOAD_TODAY_SICKNESSES_ERROR:
		case types.LOAD_TODAY_VACATIONS_ERROR:
		case types.LOAD_EMPLOYEE_VACATIONS_ERROR:
		case types.LOAD_SHORT_EMPLOYEES_ERROR:
		case types.GET_EMPLOYEE_TODOLIST_ERROR:
		case types.UPDATE_TODO_STATUS_ERROR:
		case types.ADD_TODO_ERROR:
		case types.LOAD_TODOLISTS_ERROR:
		case types.NO_TODOS_ERROR:
		case types.LOAD_KPI_ERROR:
		case types.LOAD_EMPLOYEE_KPI_ERROR:
		case types.LOAD_DEDLINED_KPI_ERROR:
		case types.CREATE_KPI_ERROR:
		case types.CHECK_KPI_ERROR:
		case types.DONE_KPI_ERROR:
		case types.DELETE_KPI_ERROR:
		case types.GET_MEMBERS_PROJECT_COUNT_ERROR:
		case types.LOAD_SYSTEM_SETTINGS_ERROR:
		case types.UPDATE_SYSTEM_SETTINGS_ERROR:
		case types.LOAD_EMPLOYEE_NOTIFICATIONS_ERROR:
		case types.UPDATE_NOTIFICATION_STATUS_ERROR:
		case types.LOAD_SUBSCRIPTIONS_ERROR:
		case types.CREATE_SUBSCRIPTION_ERROR:
		case types.UPDATE_SUBSCRIPTION_ERROR:
		case types.ARCHIVE_SUBSCRIPTION_ERROR:
		case types.UNARCHIVE_SUBSCRIPTION_ERROR:
		case types.DELETE_SUBSCRIPTION_ERROR:
			return {
				...state,
				isLoad: true,
				loading: false,
				error: true ?? false
			}

		default:
			return state
	}
}
