import {
	createProject,
	techSetup,
	updateProject,
	deleteProject,
	addGdd,
	mvpDone,
	releaseProject,
	qualityApprove,
	getMainProjects,
	sendResults,
	pauseBurnRateProject,
	continueBurnRateProject,
	getProject,
	getEmployeeProjects,
	getAllProjects,
	getInTestingProjects,
	getTestedProjects,
	getShortProjects,
	getGantChartProjects,
	getPublisherProjects,
	getDevelopersProjectCount,
	getArtistsProjectCount,
	getProjectsEmployees
} from 'api/request'

import { toast } from 'react-toastify'
import actions from 'redux/actions'
import Project, {
	DoneProjectsPayload,
	PauseProjectData,
	ProjectCount,
	ProjectCreationData,
	ProjectEmployees,
	ProjectResultData,
	ProjectRolesEnum,
	ProjectTypeEnum,
	ProjectUpdatingData,
	ProjectWithIteration,
	ReleasedProject,
	TechSetupData
} from 'types/project'
import { t } from 'i18next'
import { MonthsOptions } from 'components/ProjectsByMonthsSelector'

export const loadProjectsForMainFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.projects.loadProjectsRequest())
	try {
		const loadedProjects: Project[] = await getMainProjects()
		return dispatch(actions.dataActions.projects.loadProjectsSuccess(loadedProjects))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.projects.loadProjectsError())
	}
}

export const loadShortProjectsFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.projects.loadShortProjectsRequest())
	try {
		const loadedProjects: Project[] = await getShortProjects()
		return dispatch(actions.dataActions.projects.loadShortProjectsSuccess(loadedProjects))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.projects.loadShortProjectsError())
	}
}

export const loadPublisherProjectsFunc = (id: string) => async (dispatch) => {
	dispatch(actions.dataActions.projects.loadPublisherProjectsRequest())
	try {
		const loadedProjects: Project[] = await getPublisherProjects(id)
		return dispatch(actions.dataActions.projects.loadPublisherProjectsSuccess(loadedProjects))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.projects.loadPublisherProjectsError())
	}
}

export const loadProjectsForProjectsFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.projects.loadProjectsRequest())
	try {
		const loadedProjects: Project[] = await getAllProjects()
		return dispatch(actions.dataActions.projects.loadProjectsSuccess(loadedProjects))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.projects.loadProjectsError())
	}
}

export const loadProjectsForGantChartFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.projects.loadProjectsRequest())
	try {
		const loadedProjects: Project[] = await getGantChartProjects()
		return dispatch(actions.dataActions.projects.loadProjectsSuccess(loadedProjects))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.projects.loadProjectsError())
	}
}

export const loadEmployeeProjectsFunc = (id: string) => async (dispatch) => {
	dispatch(actions.dataActions.projects.loadEmployeeProjectsRequest())
	try {
		const loadedProjects: Project[] = await getEmployeeProjects(id)
		return dispatch(actions.dataActions.projects.loadEmployeeProjectsSuccess(loadedProjects))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.projects.loadEmployeeProjectsError())
	}
}

export const loadProjectsEmployeesFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.projects.loadProjectsEmployeesRequest())
	try {
		const loadedProjects: ProjectEmployees[] = await getProjectsEmployees()
		return dispatch(actions.dataActions.projects.loadProjectsEmployeesSuccess(loadedProjects))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.projects.loadProjectsEmployeesError())
	}
}

export const getInTestingProjectsFunc =
	(months: number, monthsOption: MonthsOptions) => async (dispatch) => {
		dispatch(actions.dataActions.projects.getInTestingProjectsRequest())
		try {
			const loadedProjects: Project[] = await getInTestingProjects(months)

			const payload: DoneProjectsPayload = {
				projects: loadedProjects,
				months: monthsOption
			}

			return dispatch(actions.dataActions.projects.getInTestingProjectsSuccess(payload))
		} catch (error: any) {
			toast.error(error.response.data.message)
			return dispatch(actions.dataActions.projects.getInTestingProjectsError())
		}
	}

export const getTestedProjectsFunc =
	(months: number, monthsOption: MonthsOptions) => async (dispatch) => {
		dispatch(actions.dataActions.projects.getTestedProjectsRequest())
		try {
			const loadedProjects: Project[] = await getTestedProjects(months)

			const payload: DoneProjectsPayload = {
				projects: loadedProjects,
				months: monthsOption
			}

			return dispatch(actions.dataActions.projects.getTestedProjectsSuccess(payload))
		} catch (error: any) {
			toast.error(error.response.data.message)
			return dispatch(actions.dataActions.projects.getTestedProjectsError())
		}
	}

export const getProjectFunc = (id: string) => async (dispatch) => {
	dispatch(actions.dataActions.projects.getProjectRequest())
	try {
		const loadedProject: Project = await getProject(id)
		return dispatch(actions.dataActions.projects.getProjectSuccess(loadedProject))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.projects.getProjectError())
	}
}

export const getMembersProjectCountFunc = (role: ProjectRolesEnum) => async (dispatch) => {
	dispatch(actions.dataActions.projects.getMembersProjectCountRequest())
	try {
		const membersProjectCount: ProjectCount[] =
			role === ProjectRolesEnum.developer
				? await getDevelopersProjectCount()
				: await getArtistsProjectCount()
		return dispatch(
			actions.dataActions.projects.getMembersProjectCountSuccess({
				role: role,
				data: membersProjectCount
			})
		)
	} catch (error: any) {
		console.log(error)
		toast.error( 'Помилка у завантаженні кількості проєктів працівників')
		return dispatch(actions.dataActions.projects.getMembersProjectCountError())
	}
}

export const createProjectFunc = (creationData: ProjectCreationData) => async (dispatch) => {
	dispatch(actions.dataActions.projects.createProjectRequest())

	try {
		const createdProject: Project = await createProject(creationData)

		const payload: ProjectWithIteration = {
			previousProjectId:
				creationData.project_info.project_type === ProjectTypeEnum.playable
					? undefined
					: createdProject.priv_iteration ?? createdProject.parent_project,
			newProject: createdProject,
			conceptId: creationData.project_info.concept
		}

		toast.success(t('reduxToastSuccess_projectCreated', { name: payload.newProject.name }) as string)

		dispatch(actions.dataActions.projects.createProjectSuccess(payload))

		return payload.previousProjectId &&
			creationData.project_info.project_type !== ProjectTypeEnum.subtask
			? payload.newProject._id
			: undefined
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.projects.createProjectError())
	}
}

export const updateProjectFunc = (updatingData: ProjectUpdatingData) => async (dispatch) => {
	dispatch(actions.dataActions.projects.updateProjectRequest())
	try {
		const updatedProject = await updateProject(updatingData)
		toast.success(t('reduxToastSuccess_projectUpdated', { name: updatedProject.name }) as string)
		return dispatch(actions.dataActions.projects.updateProjectSuccess(updatedProject))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.projects.updateProjectError())
	}
}

export const addGDDProjectFunc = (GDDData) => async (dispatch) => {
	dispatch(actions.dataActions.projects.addGDDProjectRequest())
	try {
		const { id, link } = GDDData
		const updatedProject = await addGdd(id, link)
		toast.success(t('reduxToastSuccess_GDDAdded') as string)
		return dispatch(actions.dataActions.projects.addGDDProjectSuccess(updatedProject))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.projects.addGDDProjectError())
	}
}

export const techSetupProjectFunc = (setupData: TechSetupData) => async (dispatch) => {
	dispatch(actions.dataActions.projects.techSetupProjectRequest())
	try {
		const techSetupedProject = await techSetup(setupData)
		toast.success(t('reduxToastSuccess_techSetup') as string)
		return dispatch(actions.dataActions.projects.techSetupProjectSuccess(techSetupedProject))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.projects.techSetupProjectError())
	}
}

export const mvpDoneFunc = (mvpData) => async (dispatch) => {
	dispatch(actions.dataActions.projects.mvpDoneProjectRequest())
	try {
		const { id, date } = mvpData
		const mvpDoneProject = await mvpDone(id, date)
		toast.success(t('reduxToastSuccess_mvpDone', { name: mvpDoneProject.name }) as string)
		return dispatch(actions.dataActions.projects.mvpDoneProjectSuccess(mvpDoneProject))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.projects.mvpDoneProjectError())
	}
}

export const qualityApproveFunc = (id) => async (dispatch) => {
	dispatch(actions.dataActions.projects.qualityApproveProjectRequest())
	try {
		const approvedProject = await qualityApprove(id)
		toast.success(t('reduxToastSuccess_qualityApprove', { name: approvedProject.name }) as string)
		return dispatch(actions.dataActions.projects.qualityApproveProjectSuccess(approvedProject))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.projects.qualityApproveProjectError())
	}
}

export const releaseDoneFunc = (releaseData) => async (dispatch) => {
	dispatch(actions.dataActions.projects.releaseDoneProjectRequest())
	try {
		const { id, date } = releaseData
		const releaseDoneProject: ReleasedProject = await releaseProject(id, date)
		toast.success(t('reduxToastSuccess_releaseDone', { name: releaseDoneProject.project.name }) as string)

		dispatch(actions.userActions.loadSurveySuccess(releaseDoneProject.survey))

		return dispatch(
			actions.dataActions.projects.releaseDoneProjectSuccess(releaseDoneProject.project)
		)
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.projects.releaseDoneProjectError())
	}
}

export const sendProjectResultsFunc =
	(name: string, updatedData: ProjectResultData) => async (dispatch) => {
		dispatch(actions.dataActions.projects.sendProjectResultsRequest())

		try {
			const updatedProject: Project = await sendResults(updatedData)

			const messageFT = t('reduxToastSuccess_updateStoreProjects', { name: name })
			const messageUpdate = t('reduxToastSuccess_updateStoreProjectsNotFT', { name: name })

			updatedData.failed_test
				? toast.success( messageFT)
				: toast.success( messageUpdate)

			return dispatch(actions.dataActions.projects.sendProjectResultsSuccess(updatedProject))
		} catch (error) {
			const message = t('reduxToastError_updateStoreProjects', { name: name })
			toast.error(message)
			return dispatch(actions.dataActions.projects.sendProjectResultsError())
		}
	}

export const pauseBurnRateProjectFunc = (data: PauseProjectData) => async (dispatch) => {
	dispatch(actions.dataActions.projects.pauseBurnRateProjectRequest())
	try {
		const pausedProject = await pauseBurnRateProject(data)
		toast.success(t('reduxToastSuccess_pauseProject', { name: pausedProject.name }) as string)
		return dispatch(actions.dataActions.projects.pauseBurnRateProjectSuccess(pausedProject))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.projects.pauseBurnRateProjectError())
	}
}

export const continueBurnRateProjectFunc = (id, date) => async (dispatch) => {
	dispatch(actions.dataActions.projects.continueBurnRateProjectRequest())
	try {
		const continuedProject = await continueBurnRateProject(id, date)
		toast.success(t('reduxToastSuccess_continueProject', { name: continuedProject.name }) as string)
		return dispatch(
			actions.dataActions.projects.continueBurnRateProjectSuccess(continuedProject)
		)
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.projects.continueBurnRateProjectError())
	}
}

export const deleteProjectFunc = (id) => async (dispatch) => {
	dispatch(actions.dataActions.projects.deleteProjectRequest())
	try {
		const deletedProject = await deleteProject(id)
		toast.success(t('reduxToastSuccess_projectDeleted', { name: deletedProject.name }) as string)
		dispatch(actions.dataActions.projects.deleteProjectSuccess(deletedProject._id))
	} catch (error: any) {
		toast.error(error.response.data.message)
		dispatch(actions.dataActions.projects.deleteProjectError())
	}
}
