import { ShortEmployee } from './employee'
import { PositionsEnum } from './positions'

export enum VacationTypeEnum {
	inVacation = 'Vacations_typeInVacation'
}

export enum VacationStatusEnum {
	requested = 'requested',
	agreed = 'agreed',
	rejected = 'rejected'
}

export interface VacationCreationData {
	status: VacationStatusEnum
	employee: string
	start_date: Date
	end_date: Date
	days: number
	refund: boolean
}

export interface VacationLocedPeriodCreationData {
	start_date: Date
	end_date: Date
}

export interface VacationUpdatingData {
	id: string
	status: VacationStatusEnum
	start_date: Date
	end_date: Date
	days: number
	comment?: string
	refund: boolean
}

export interface VacationRejectingData {
	id: string
	comment: string
}

export interface MonthAndEmployeeForVacations {
	employee: string
	month: Date
}

export default interface Vacation {
	_id: string
	date: Date
	status: VacationStatusEnum
	employee: ShortEmployee
	start_date: Date
	end_date: Date
	days: number
	refund?: boolean
	comment?: string
	conflicts?: string[]
	checked_in_projects?: boolean
}

export interface EmployeeVacationAbilityDto {
	employee_id: string
	position: PositionsEnum
	start_date: Date
	end_date: Date
}

export interface VacationPeriodInfo {
	vacation_id: string
	start_date: Date
	end_date: Date
}
