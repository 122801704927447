import { Col, Row } from 'react-bootstrap'
import React from 'react'
import CreateAccountModal from './Modals/CreateAccountModal'
import AccountsTable from './Tables/AccountsTable'

function Accounts() {
	return (
		<>
			<Row className="mb-2 mb-xxl-4">
				<Col xs="auto" className="d-none d-sm-block">
					{/*<h3>{t('Accounts')}</h3>*/}
				</Col>

				<Col xs="auto" className="ml-auto text-right mt-n1">
					<CreateAccountModal />
				</Col>
			</Row>
			<AccountsTable/>
		</>
	)
}

export default Accounts