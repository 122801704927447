import { createAction } from '@reduxjs/toolkit'
import * as types from '../../constants'
import Concept from 'types/concept'

// import { AnyObject } from 'immer/dist/internal'
type AnyObject = { [key: string]: any };

export const loadConceptsRequest = createAction(types.LOAD_CONCEPTS_REQUEST)
export const loadConceptsSuccess = createAction<Concept[]>(types.LOAD_CONCEPTS_SUCCESS)
export const loadConceptsError = createAction(types.LOAD_CONCEPTS_ERROR)

export const loadEmployeeConceptsRequest = createAction(types.LOAD_EMPLOYEE_CONCEPTS_REQUEST)
export const loadEmployeeConceptsSuccess = createAction<Concept[]>(
	types.LOAD_EMPLOYEE_CONCEPTS_SUCCESS
)
export const loadEmployeeConceptsError = createAction(types.LOAD_EMPLOYEE_CONCEPTS_ERROR)

export const getAllArchivedConceptsRequest = createAction(types.GET_ARCHIVED_CONCEPTS_REQUEST)
export const getAllArchivedConceptsSuccess = createAction<Concept[]>(
	types.GET_ARCHIVED_CONCEPTS_SUCCESS
)
export const getAllArchivedConceptsError = createAction(types.GET_ARCHIVED_CONCEPTS_ERROR)

export const getConceptRequest = createAction(types.GET_CONCEPT_REQUEST)
export const getConceptSuccess = createAction<Concept>(types.GET_CONCEPT_SUCCESS)
export const getConceptError = createAction(types.GET_CONCEPT_ERROR)

export const createConceptRequest = createAction(types.CREATE_CONCEPT_REQUEST)
export const createConceptSuccess = createAction<AnyObject>(types.CREATE_CONCEPT_SUCCESS)
export const createConceptError = createAction(types.CREATE_CONCEPT_ERROR)

export const approveConceptRequest = createAction(types.APPROVE_CONCEPT_REQUEST)
export const approveConceptSuccess = createAction<AnyObject>(types.APPROVE_CONCEPT_SUCCESS)
export const approveConceptError = createAction(types.APPROVE_CONCEPT_ERROR)

export const rejectConceptRequest = createAction(types.REJECT_CONCEPT_REQUEST)
export const rejectConceptSuccess = createAction<AnyObject>(types.REJECT_CONCEPT_SUCCESS)
export const rejectConceptError = createAction(types.REJECT_CONCEPT_ERROR)

export const pinConceptRequest = createAction(types.PIN_CONCEPT_REQUEST)
export const pinConceptSuccess = createAction<AnyObject>(types.PIN_CONCEPT_SUCCESS)
export const pinConceptError = createAction(types.PIN_CONCEPT_ERROR)

export const archiveConceptRequest = createAction(types.ARCHIVE_CONCEPT_REQUEST)
export const archiveConceptSuccess = createAction<AnyObject>(types.ARCHIVE_CONCEPT_SUCCESS)
export const archiveConceptError = createAction(types.ARCHIVE_CONCEPT_ERROR)

export const unarchiveConceptRequest = createAction(types.UNARCHIVE_CONCEPT_REQUEST)
export const unarchiveConceptSuccess = createAction<AnyObject>(types.UNARCHIVE_CONCEPT_SUCCESS)
export const unarchiveConceptError = createAction(types.UNARCHIVE_CONCEPT_ERROR)
