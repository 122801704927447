// User
export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST'
export const FETCH_USER_TOKEN_SUCCESS = 'FETCH_USER_TOKEN_SUCCESS'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_ERROR = 'FETCH_USER_ERROR'

export const LOAD_SURVEY_REQUEST = 'LOAD_SURVEY_REQUEST'
export const LOAD_SURVEY_SUCCESS = 'LOAD_SURVEY_SUCCESS'
export const LOAD_SURVEY_ERROR = 'LOAD_SURVEY_ERROR'

export const SEND_ANSWER_REQUEST = 'SEND_ANSWER_REQUEST'
export const SEND_ANSWER_SUCCESS = 'SEND_ANSWER_SUCCESS'
export const SEND_ANSWER_ERROR = 'SEND_ANSWER_ERROR'

export const SAVE_EMAIL_REQUEST = 'SAVE_EMAIL_REQUEST'
export const SAVE_EMAIL_SUCCESS = 'SAVE_EMAIL_SUCCESS'
export const SAVE_EMAIL_ERROR = 'SAVE_EMAIL_ERROR'

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_ERROR = 'LOGOUT_ERROR'

export const WORK_WITH_SYSTEM = 'WORK_WITH_SYSTEM'
export const CLEAR_USER = 'CLEAR_USER'

//emploees
export const LOAD_EMPLOYEES_REQUEST = 'LOAD_EMPLOYEES_REQUEST'
export const LOAD_EMPLOYEES_SUCCESS = 'LOAD_EMPLOYEES_SUCCESS'
export const LOAD_EMPLOYEES_ERROR = 'LOAD_EMPLOYEES_ERROR'

export const GET_EMPLOYEE_REQUEST = 'GET_EMPLOYEE_REQUEST'
export const GET_EMPLOYEE_SUCCESS = 'GET_EMPLOYEE_SUCCESS'
export const GET_EMPLOYEE_ERROR = 'GET_EMPLOYEE_ERROR'

export const LOAD_SHORT_EMPLOYEES_REQUEST = 'LOAD_SHORT_EMPLOYEES_REQUEST'
export const LOAD_SHORT_EMPLOYEES_SUCCESS = 'LOAD_SHORT_EMPLOYEES_SUCCESS'
export const LOAD_SHORT_EMPLOYEES_ERROR = 'LOAD_SHORT_EMPLOYEES_ERROR'

export const REGISTER_EMPLOYEE_REQUEST = 'REGISTER_EMPLOYEE_REQUEST'
export const REGISTER_EMPLOYEE_SUCCESS = 'REGISTER_EMPLOYEE_SUCCESS'
export const REGISTER_EMPLOYEE_ERROR = 'REGISTER_EMPLOYEE_ERROR'

export const UPDATE_INFO_EMPLOYEE_REQUEST = 'UPDATE_INFO_EMPLOYEE_REQUEST'
export const UPDATE_INFO_EMPLOYEE_SUCCESS = 'UPDATE_INFO_EMPLOYEE_SUCCESS'
export const UPDATE_INFO_EMPLOYEE_ERROR = 'UPDATE_INFO_EMPLOYEE_ERROR'

export const UPDATE_PROMOTION_INFO_EMPLOYEE_REQUEST = 'UPDATE_PROMOTION_INFO_EMPLOYEE_REQUEST'
export const UPDATE_PROMOTION_INFO_EMPLOYEE_SUCCESS = 'UPDATE_PROMOTION_INFO_EMPLOYEE_SUCCESS'
export const UPDATE_PROMOTION_INFO_EMPLOYEE_ERROR = 'UPDATE_PROMOTION_INFO_EMPLOYEE_ERROR'

export const UPLOAD_EMPLOYEE_PROFILE_PHOTO_REQUEST = 'UPLOAD_EMPLOYEE_PROFILE_PHOTO_REQUEST'
export const UPLOAD_EMPLOYEE_PROFILE_PHOTO_SUCCESS = 'UPLOAD_EMPLOYEE_PROFILE_PHOTO_SUCCESS'
export const UPLOAD_EMPLOYEE_PROFILE_PHOTO_ERROR = 'UPLOAD_EMPLOYEE_PROFILE_PHOTO_ERROR'

export const DELETE_EMPLOYEE_PROFILE_PHOTO_REQUEST = 'DELETE_EMPLOYEE_PROFILE_PHOTO_REQUEST'
export const DELETE_EMPLOYEE_PROFILE_PHOTO_SUCCESS = 'DELETE_EMPLOYEE_PROFILE_PHOTO_SUCCESS'
export const DELETE_EMPLOYEE_PROFILE_PHOTO_ERROR = 'DELETE_EMPLOYEE_PROFILE_PHOTO_ERROR'

export const ARCHIVE_EMPLOYEE_REQUEST = 'ARCHIVE_EMPLOYEE_REQUEST'
export const ARCHIVE_EMPLOYEE_SUCCESS = 'ARCHIVE_EMPLOYEE_SUCCESS'
export const ARCHIVE_EMPLOYEE_ERROR = 'ARCHIVE_EMPLOYEE_ERROR'

//promotions
export const LOAD_PROMOTIONS_REQUEST = 'LOAD_PROMOTIONS_REQUEST'
export const LOAD_PROMOTIONS_SUCCESS = 'LOAD_PROMOTIONS_SUCCESS'
export const LOAD_PROMOTIONS_ERROR = 'LOAD_PROMOTIONS_ERROR'

export const LOAD_EMPLOYEE_PROMOTIONS_REQUEST = 'LOAD_EMPLOYEE_PROMOTIONS_REQUEST'
export const LOAD_EMPLOYEE_PROMOTIONS_SUCCESS = 'LOAD_EMPLOYEE_PROMOTIONS_SUCCESS'
export const LOAD_EMPLOYEE_PROMOTIONS_ERROR = 'LOAD_EMPLOYEE_PROMOTIONS_ERROR'

export const CREATE_PROMOTION_REQUEST = 'CREATE_PROMOTION_REQUEST'
export const CREATE_PROMOTION_SUCCESS = 'CREATE_PROMOTION_SUCCESS'
export const CREATE_PROMOTION_ERROR = 'CREATE_PROMOTION_ERROR'

export const SEND_REQUEST_PROMOTION_REQUEST = 'SEND_REQUEST_PROMOTION_REQUEST'
export const SEND_REQUEST_PROMOTION_SUCCESS = 'SEND_REQUEST_PROMOTION_SUCCESS'
export const SEND_REQUEST_PROMOTION_ERROR = 'SEND_REQUEST_PROMOTION_ERROR'

export const REPEAT_PROMOTION_REQUEST = 'REPEAT_PROMOTION_REQUEST'
export const REPEAT_PROMOTION_SUCCESS = 'REPEAT_PROMOTION_SUCCESS'
export const REPEAT_PROMOTION_ERROR = 'REPEAT_PROMOTION_ERROR'

export const AGREE_PROMOTION_REQUEST = 'AGREE_PROMOTION_REQUEST'
export const AGREE_PROMOTION_SUCCESS = 'AGREE_PROMOTION_SUCCESS'
export const AGREE_PROMOTION_ERROR = 'AGREE_PROMOTION_ERROR'

export const REJECT_PROMOTION_REQUEST = 'REJECT_PROMOTION_REQUEST'
export const REJECT_PROMOTION_SUCCESS = 'REJECT_PROMOTION_SUCCESS'
export const REJECT_PROMOTION_ERROR = 'REJECT_PROMOTION_ERROR'

export const DELETE_PROMOTION_REQUEST = 'DELETE_PROMOTION_REQUEST'
export const DELETE_PROMOTION_SUCCESS = 'DELETE_PROMOTION_SUCCESS'
export const DELETE_PROMOTION_ERROR = 'DELETE_PROMOTION_ERROR'

//Finance
export const PROTOTYPES_LIST_UPDATE_SUMM_REQUEST = 'PROTOTYPES_LIST_UPDATE_SUMM_REQUEST'
export const PROTOTYPES_LIST_UPDATE_SUMM_SUCCESS = 'PROTOTYPES_LIST_UPDATE_SUMM_SUCCESS'
export const PROTOTYPES_LIST_UPDATE_SUMM_ERROR = 'PROTOTYPES_LIST_UPDATE_SUMM_ERROR'

export const FINANCE_IN_MONTH_REQUEST = 'FINANCE_IN_MONTH_REQUEST'
export const FINANCE_IN_MONTH_SUCCESS = 'FINANCE_IN_MONTH_SUCCESS'
export const FINANCE_IN_MONTH_ERROR = 'FINANCE_IN_MONTH_ERROR'

export const FINANCE_IN_MONTH_RECALCULATE_REQUEST = 'FINANCE_IN_MONTH_RECALCULATE_REQUEST'
export const FINANCE_IN_MONTH_RECALCULATE_SUCCESS = 'FINANCE_IN_MONTH_RECALCULATE_SUCCESS'
export const FINANCE_IN_MONTH_RECALCULATE_ERROR = 'FINANCE_IN_MONTH_RECALCULATE_ERROR'

// Finance operations
export const LOAD_FINANCE_OPERATIONS_BY_MONTH_REQUEST = 'LOAD_FINANCE_OPERATIONS_BY_MONTH_REQUEST'
export const LOAD_FINANCE_OPERATIONS_BY_MONTH_SUCCESS = 'LOAD_FINANCE_OPERATIONS_BY_MONTH_SUCCESS'
export const LOAD_FINANCE_OPERATIONS_BY_MONTH_ERROR = 'LOAD_FINANCE_OPERATIONS_BY_MONTH_ERROR'

export const LOAD_ADVANCE_OPERATIONS_BY_MONTH_REQUEST = 'LOAD_ADVANCE_OPERATIONS_BY_MONTH_REQUEST'
export const LOAD_ADVANCE_OPERATIONS_BY_MONTH_SUCCESS = 'LOAD_ADVANCE_OPERATIONS_BY_MONTH_SUCCESS'
export const LOAD_ADVANCE_OPERATIONS_BY_MONTH_ERROR = 'LOAD_ADVANCE_OPERATIONS_BY_MONTH_ERROR'

export const LOAD_FINANCE_OPERATIONS_BY_YEAR_REQUEST = 'LOAD_FINANCE_OPERATIONS_BY_YEAR_REQUEST'
export const LOAD_FINANCE_OPERATIONS_BY_YEAR_SUCCESS = 'LOAD_FINANCE_OPERATIONS_BY_YEAR_SUCCESS'
export const LOAD_FINANCE_OPERATIONS_BY_YEAR_ERROR = 'LOAD_FINANCE_OPERATIONS_BY_YEAR_ERROR'

export const CREATE_FINANCE_OPERATION_REQUEST = 'CREATE_FINANCE_OPERATION_REQUEST'
export const CREATE_FINANCE_OPERATION_SUCCESS = 'CREATE_FINANCE_OPERATION_SUCCESS'
export const CREATE_FINANCE_OPERATION_ERROR = 'CREATE_FINANCE_OPERATION_ERROR'

export const APPROVE_FINANCE_OPERATION_REQUEST = 'APPROVE_FINANCE_OPERATION_REQUEST'
export const APPROVE_FINANCE_OPERATION_SUCCESS = 'APPROVE_FINANCE_OPERATION_SUCCESS'
export const APPROVE_FINANCE_OPERATION_ERROR = 'APPROVE_FINANCE_OPERATION_ERROR'

export const REJECT_FINANCE_OPERATION_REQUEST = 'REJECT_FINANCE_OPERATION_REQUEST'
export const REJECT_FINANCE_OPERATION_SUCCESS = 'REJECT_FINANCE_OPERATION_SUCCESS'
export const REJECT_FINANCE_OPERATION_ERROR = 'REJECT_FINANCE_OPERATION_ERROR'

export const CHECK_FINANCE_OPERATION_REQUEST = 'CHECK_FINANCE_OPERATION_REQUEST'
export const CHECK_FINANCE_OPERATION_SUCCESS = 'CHECK_FINANCE_OPERATION_SUCCESS'
export const CHECK_FINANCE_OPERATION_ERROR = 'CHECK_FINANCE_OPERATION_ERROR'

export const DELETE_FINANCE_OPERATION_REQUEST = 'DELETE_FINANCE_OPERATION_REQUEST'
export const DELETE_FINANCE_OPERATION_SUCCESS = 'DELETE_FINANCE_OPERATION_SUCCESS'
export const DELETE_FINANCE_OPERATION_ERROR = 'DELETE_FINANCE_OPERATION_ERROR'

// Expense file
export const UPLOAD_EXPENSE_FILE_REQUEST = 'UPLOAD_EXPENSE_FILE_REQUEST'
export const UPLOAD_EXPENSE_FILE_SUCCESS = 'UPLOAD_EXPENSE_FILE_SUCCESS'
export const UPLOAD_EXPENSE_FILE_ERROR = 'UPLOAD_EXPENSE_FILE_ERROR'

export const DOWNLOAD_EXPENSE_FILE_REQUEST = 'DWNLOAD_EXPENSE_FILE_REQUEST'
export const DOWNLOAD_EXPENSE_FILE_SUCCESS = 'DOWNLOAD_EXPENSE_FILE_SUCCESS'
export const DOWNLOAD_EXPENSE_FILE_ERROR = 'DWNLOAD_EXPENSE_FILE_ERROR'

export const DELETE_EXPENSE_FILE_REQUEST = 'DELETE_EXPENSE_FILE_REQUEST'
export const DELETE_EXPENSE_FILE_SUCCESS = 'DELETE_EXPENSE_FILE_SUCCESS'
export const DELETE_EXPENSE_FILE_ERROR = 'DELETE_EXPENSE_FILE_ERROR'

// Balance tab
export const LOAD_BALANCE_BY_MONTH_REQUEST = 'LOAD_BALANCE_BY_MONTH_REQUEST'
export const LOAD_BALANCE_BY_MONTH_SUCCESS = 'LOAD_BALANCE_BY_MONTH_SUCCESS'
export const LOAD_BALANCE_BY_MONTH_ERROR = 'LOAD_BALANCE_BY_MONTH_ERROR'

export const LOAD_CALC_BALANCES_REQUEST = 'LOAD_CALC_BALANCES_REQUEST'
export const LOAD_CALC_BALANCES_SUCCESS = 'LOAD_CALC_BALANCES_SUCCESS'
export const LOAD_CALC_BALANCES_ERROR = 'LOAD_CALC_BALANCES_ERROR'

export const CREATE_INTER_BALANCE_TRANSACTION_REQUEST = 'CREATE_INTER_BALANCE_TRANSACTION_REQUEST'
export const CREATE_INTER_BALANCE_TRANSACTION_SUCCESS = 'CREATE_INTER_BALANCE_TRANSACTION_SUCCESS'
export const CREATE_INTER_BALANCE_TRANSACTION_ERROR = 'CREATE_INTER_BALANCE_TRANSACTION_ERROR'

// Dividends tab
export const LOAD_DIVIDENDS_REQUEST = 'LOAD_DIVIDENDS_REQUEST'
export const LOAD_DIVIDENDS_SUCCESS = 'LOAD_DIVIDENDS_SUCCESS'
export const LOAD_DIVIDENDS_ERROR = 'LOAD_DIVIDENDS_ERROR'

export const WITHDRAW_DIVIDENDS_REQUEST = 'WITHDRAW_DIVIDENDS_REQUEST'
export const WITHDRAW_DIVIDENDS_SUCCESS = 'WITHDRAW_DIVIDENDS_SUCCESS'
export const WITHDRAW_DIVIDENDS_ERROR = 'WITHDRAW_DIVIDENDS_ERROR'

export const DISTRIBUTE_FOUNDERS_DIVIDENDS_REQUEST = 'DISTRIBUTE_FOUNDERS_DIVIDENDS_REQUEST'
export const DISTRIBUTE_FOUNDERS_DIVIDENDS_SUCCESS = 'DISTRIBUTE_FOUNDERS_DIVIDENDS_SUCCESS'
export const DISTRIBUTE_FOUNDERS_DIVIDENDS_ERROR = 'DISTRIBUTE_FOUNDERS_DIVIDENDS_ERROR'

//
export const STORE_PUBLISHERS_INVOICE_PAY_TIME = 'STORE_PUBLISHERS_INVOICE_PAY_TIME'

//Subscriptions
export const LOAD_SUBSCRIPTIONS_REQUEST = 'LOAD_SUBSCRIPTIONS_REQUEST'
export const LOAD_SUBSCRIPTIONS_SUCCESS = 'LOAD_SUBSCRIPTIONS_SUCCESS'
export const LOAD_SUBSCRIPTIONS_ERROR = 'LOAD_SUBSCRIPTIONS_ERROR'

export const CREATE_SUBSCRIPTION_REQUEST = 'CREATE_SUBSCRIPTION_REQUEST'
export const CREATE_SUBSCRIPTION_SUCCESS = 'CREATE_SUBSCRIPTION_SUCCESS'
export const CREATE_SUBSCRIPTION_ERROR = 'CREATE_SUBSCRIPTION_ERROR'

export const UPDATE_SUBSCRIPTION_REQUEST = 'UPDATE_SUBSCRIPTION_REQUEST'
export const UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS'
export const UPDATE_SUBSCRIPTION_ERROR = 'UPDATE_SUBSCRIPTION_ERROR'

export const ARCHIVE_SUBSCRIPTION_REQUEST = 'ARCHIVE_SUBSCRIPTION_REQUEST'
export const ARCHIVE_SUBSCRIPTION_SUCCESS = 'ARCHIVE_SUBSCRIPTION_SUCCESS'
export const ARCHIVE_SUBSCRIPTION_ERROR = 'ARCHIVE_SUBSCRIPTION_ERROR'

export const UNARCHIVE_SUBSCRIPTION_REQUEST = 'UNARCHIVE_SUBSCRIPTION_REQUEST'
export const UNARCHIVE_SUBSCRIPTION_SUCCESS = 'UNARCHIVE_SUBSCRIPTION_SUCCESS'
export const UNARCHIVE_SUBSCRIPTION_ERROR = 'UNARCHIVE_SUBSCRIPTION_ERROR'

export const DELETE_SUBSCRIPTION_REQUEST = 'DELETE_SUBSCRIPTION_REQUEST'
export const DELETE_SUBSCRIPTION_SUCCESS = 'DELETE_SUBSCRIPTION_SUCCESS'
export const DELETE_SUBSCRIPTION_ERROR = 'DELETE_SUBSCRIPTION_ERROR'

//concepts
export const LOAD_CONCEPTS_REQUEST = 'LOAD_CONCEPTS_REQUEST'
export const LOAD_CONCEPTS_SUCCESS = 'LOAD_CONCEPTS_SUCCESS'
export const LOAD_CONCEPTS_ERROR = 'LOAD_CONCEPTS_ERROR'

export const LOAD_EMPLOYEE_CONCEPTS_REQUEST = 'LOAD_EMPLOYEE_CONCEPTS_REQUEST'
export const LOAD_EMPLOYEE_CONCEPTS_SUCCESS = 'LOAD_EMPLOYEE_CONCEPTS_SUCCESS'
export const LOAD_EMPLOYEE_CONCEPTS_ERROR = 'LOAD_EMPLOYEE_CONCEPTS_ERROR'

export const GET_ARCHIVED_CONCEPTS_REQUEST = 'GET_ARCHIVED_CONCEPTS_REQUEST'
export const GET_ARCHIVED_CONCEPTS_SUCCESS = 'GET_ARCHIVED_CONCEPTS_SUCCESS'
export const GET_ARCHIVED_CONCEPTS_ERROR = 'GET_ARCHIVED_CONCEPTS_ERROR'

export const GET_CONCEPT_REQUEST = 'GET_CONCEPT_REQUEST'
export const GET_CONCEPT_SUCCESS = 'GET_CONCEPT_SUCCESS'
export const GET_CONCEPT_ERROR = 'GET_CONCEPT_ERROR'

export const CREATE_CONCEPT_REQUEST = 'CREATE_CONCEPT_REQUEST'
export const CREATE_CONCEPT_SUCCESS = 'CREATE_CONCEPT_SUCCESS'
export const CREATE_CONCEPT_ERROR = 'CREATE_CONCEPT_ERROR'

export const APPROVE_CONCEPT_REQUEST = 'APPROVE_CONCEPT_REQUEST'
export const APPROVE_CONCEPT_SUCCESS = 'APPROVE_CONCEPT_SUCCESS'
export const APPROVE_CONCEPT_ERROR = ' APPROVE_CONCEPT_ERROR'

export const REJECT_CONCEPT_REQUEST = 'REJECT_CONCEPT_REQUEST'
export const REJECT_CONCEPT_SUCCESS = 'REJECT_CONCEPT_SUCCESS'
export const REJECT_CONCEPT_ERROR = 'REJECT_CONCEPT_ERROR'

export const PIN_CONCEPT_REQUEST = 'PIN_CONCEPT_REQUEST'
export const PIN_CONCEPT_SUCCESS = 'PIN_CONCEPT_SUCCESS'
export const PIN_CONCEPT_ERROR = 'PIN_CONCEPT_ERROR'

export const ARCHIVE_CONCEPT_REQUEST = 'ARCHIVE_CONCEPT_REQUEST'
export const ARCHIVE_CONCEPT_SUCCESS = 'ARCHIVE_CONCEPT_SUCCESS'
export const ARCHIVE_CONCEPT_ERROR = 'ARCHIVE_CONCEPT_ERROR'

export const UNARCHIVE_CONCEPT_REQUEST = 'UNARCHIVE_CONCEPT_REQUEST'
export const UNARCHIVE_CONCEPT_SUCCESS = 'UNARCHIVE_CONCEPT_SUCCESS'
export const UNARCHIVE_CONCEPT_ERROR = 'UNARCHIVE_CONCEPT_ERROR'

//concept-art
export const LOAD_CONCEPT_ART_REQUEST = 'LOAD_CONCEPT_ART_REQUEST'
export const LOAD_CONCEPT_ART_SUCCESS = 'LOAD_CONCEPT_ART_SUCCESS'
export const LOAD_CONCEPT_ART_ERROR = 'LOAD_CONCEPT_ART_ERROR'

export const SETUP_CONCEPT_ART_REQUEST = 'SETUP_CONCEPT_ART_REQUEST'
export const SETUP_CONCEPT_ART_SUCCESS = 'SETUP_CONCEPT_ART_SUCCESS'
export const SETUP_CONCEPT_ART_ERROR = 'SETUP_CONCEPT_ART_ERROR'

export const UNSET_CONCEPT_ART_REQUEST = 'UNSET_CONCEPT_ART_REQUEST'
export const UNSET_CONCEPT_ART_SUCCESS = 'UNSET_CONCEPT_ART_SUCCESS'
export const UNSET_CONCEPT_ART_ERROR = 'UNSET_CONCEPT_ART_ERROR'

export const TAKE_CONCEPT_ART_REQUEST = 'TAKE_CONCEPT_ART_REQUEST'
export const TAKE_CONCEPT_ART_SUCCESS = 'TAKE_CONCEPT_ART_SUCCESS'
export const TAKE_CONCEPT_ART_ERROR = 'TAKE_CONCEPT_ART_ERROR'

export const UPLOAD_CONCEPT_ART_REQUEST = 'UPLOAD_CONCEPT_ART_REQUEST'
export const UPLOAD_CONCEPT_ART_SUCCESS = 'UPLOAD_CONCEPT_ART_SUCCESS'
export const UPLOAD_CONCEPT_ART_ERROR = 'UPLOAD_CONCEPT_ART_ERROR'

export const DONE_CONCEPT_ART_REQUEST = 'DONE_CONCEPT_ART_REQUEST'
export const DONE_CONCEPT_ART_SUCCESS = 'DONE_CONCEPT_ART_SUCCESS'
export const DONE_CONCEPT_ART_ERROR = 'DONE_CONCEPT_ART_ERROR'

export const DELETE_CONCEPT_ART_FILES_REQUEST = 'DELETE_CONCEPT_ART_FILE_REQUEST'
export const DELETE_CONCEPT_ART_FILES_SUCCESS = 'DELETE_CONCEPT_ART_FILE_SUCCESS'
export const DELETE_CONCEPT_ART_FILES_ERROR = 'DELETE_CONCEPT_ART_FILE_ERROR'

//projects
export const LOAD_PROJECTS_REQUEST = 'LOAD_PROJECTS_REQUEST'
export const LOAD_PROJECTS_SUCCESS = 'LOAD_PROJECTS_SUCCESS'
export const LOAD_PROJECTS_ERROR = 'LOAD_PROJECTS_ERROR'

export const LOAD_EMPLOYEE_PROJECTS_REQUEST = 'LOAD_EMPLOYEE_PROJECTS_REQUEST'
export const LOAD_EMPLOYEE_PROJECTS_SUCCESS = 'LOAD_EMPLOYEE_PROJECTS_SUCCESS'
export const LOAD_EMPLOYEE_PROJECTS_ERROR = 'LOAD_EMPLOYEE_PROJECTS_ERROR'

export const LOAD_PROJECTS_EMPLOYEES_REQUEST = 'LOAD_PROJECTS_EMPLOYEES_REQUEST'
export const LOAD_PROJECTS_EMPLOYEES_SUCCESS = 'LOAD_PROJECTS_EMPLOYEES_SUCCESS'
export const LOAD_PROJECTS_EMPLOYEES_ERROR = 'LOAD_PROJECTS_EMPLOYEES_ERROR'

export const LOAD_SHORT_PROJECTS_REQUEST = 'LOAD_SHORT_PROJECTS_REQUEST'
export const LOAD_SHORT_PROJECTS_SUCCESS = 'LOAD_SHORT_PROJECTS_SUCCESS'
export const LOAD_SHORT_PROJECTS_ERROR = 'LOAD_SHORT_PROJECTS_ERROR'

export const LOAD_PUBLISHER_PROJECTS_REQUEST = 'LOAD_PUBLISHER_PROJECTS_REQUEST'
export const LOAD_PUBLISHER_PROJECTS_SUCCESS = 'LOAD_PUBLISHER_PROJECTS_SUCCESS'
export const LOAD_PUBLISHER_PROJECTS_ERROR = 'LOAD_PUBLISHER_PROJECTS_ERROR'

export const GET_IN_TESTING_PROJECTS_REQUEST = 'GET_IN_TESTING_PROJECTS_REQUEST'
export const GET_IN_TESTING_PROJECTS_SUCCESS = 'GET_IN_TESTING_PROJECTS_SUCCESS'
export const GET_IN_TESTING_PROJECTS_ERROR = 'GET_IN_TESTING_PROJECTS_ERROR'

export const GET_TESTED_PROJECTS_REQUEST = 'GET_TESTED_PROJECTS_REQUEST'
export const GET_TESTED_PROJECTS_SUCCESS = 'GET_TESTED_PROJECTS_SUCCESS'
export const GET_TESTED_PROJECTS_ERROR = 'GET_TESTED_PROJECTS_ERROR'

export const GET_PROJECT_REQUEST = 'GET_PROJECT_REQUEST'
export const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS'
export const GET_PROJECT_ERROR = 'GET_PROJECT_ERROR'

export const GET_MEMBERS_PROJECT_COUNT_REQUEST = 'GET_DEVELOPERS_PROJECT_COUNT_REQUEST'
export const GET_MEMBERS_PROJECT_COUNT_SUCCESS = 'GET_DEVELOPERS_PROJECT_COUNT_SUCCESS'
export const GET_MEMBERS_PROJECT_COUNT_ERROR = 'GET_DEVELOPERS_PROJECT_COUNT_ERROR'

export const CREATE_PROJECT_REQUEST = 'CREATE_PROJECT_REQUEST'
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS'
export const CREATE_PROJECT_ERROR = 'CREATE_PROJECT_ERROR'

export const UPDATE_PROJECT_REQUEST = 'UPDATE_PROJECT_REQUEST'
export const UPDATE_PROJECT_SUCCESS = ' UPDATE_PROJECT_SUCCESS'
export const UPDATE_PROJECT_ERROR = 'UPDATE_PROJECT_ERROR'

export const ADD_GDD_PROJECT_REQUEST = 'ADD_GDD_PROJECT_REQUEST'
export const ADD_GDD_PROJECT_SUCCESS = 'ADD_GDD_PROJECT_SUCCESS'
export const ADD_GDD_PROJECT_ERROR = 'ADD_GDD_PROJECT_ERROR'

export const TECH_SETUP_PROJECT_REQUEST = 'TECH_SETUP_PROJECT_REQUEST'
export const TECH_SETUP_PROJECT_SUCCESS = 'TECH_SETUP_PROJECT_SUCCESS'
export const TECH_SETUP_PROJECT_ERROR = 'TECH_SETUP_PROJECT_ERROR'

export const ADD_RATING_PROJECT_REQUEST = 'ADD_RATING_PROJECT_REQUEST'
export const ADD_RATING_PROJECT_SUCCESS = 'ADD_RATING_PROJECT_SUCCESS'
export const ADD_RATING_PROJECT_ERROR = 'ADD_RATING_PROJECT_ERROR'

export const MVP_DONE_PROJECT_REQUEST = 'MVP_DONE_PROJECT_REQUEST'
export const MVP_DONE_PROJECT_SUCCESS = 'MVP_DONE_PROJECT_SUCCESS'
export const MVP_DONE_PROJECT_ERROR = 'MVP_DONE_PROJECT_ERROR'

export const QUALITY_APPROVE_PROJECT_REQUEST = 'QUALITY_APPROVE_PROJECT_REQUEST'
export const QUALITY_APPROVE_PROJECT_SUCCESS = 'QUALITY_APPROVE_PROJECT_SUCCESS'
export const QUALITY_APPROVE_PROJECT_ERROR = 'QUALITY_APPROVE_PROJECT_ERROR'

export const RELEASE_DONE_PROJECT_REQUEST = 'RELEASE_DONE_PROJECT_REQUEST'
export const RELEASE_DONE_PROJECT_SUCCESS = 'RELEASE_DONE_PROJECT_SUCCESS'
export const RELEASE_DONE_PROJECT_ERROR = 'RELEASE_DONE_PROJECT_ERROR'

export const PAUSE_BURN_RATE_PROJECT_REQUEST = 'PAUSE_BURN_RATE_PROJECT_REQUEST'
export const PAUSE_BURN_RATE_PROJECT_SUCCESS = 'PAUSE_BURN_RATE_PROJECT_SUCCESS'
export const PAUSE_BURN_RATE_PROJECT_ERROR = 'PAUSE_BURN_RATE_PROJECT_ERROR'

export const CONTINUE_BURN_RATE_PROJECT_REQUEST = 'CONTINUE_BURN_RATE_PROJECT_REQUEST'
export const CONTINUE_BURN_RATE_PROJECT_SUCCESS = 'CONTINUE_BURN_RATE_PROJECT_SUCCESS'
export const CONTINUE_BURN_RATE_PROJECT_ERROR = 'CONTINUE_BURN_RATE_PROJECT_ERROR'

export const SEND_PROJECT_RESULTS_REQUEST = 'SEND_PROJECT_RESULTS_REQUEST'
export const SEND_PROJECT_RESULTS_SUCCESS = 'SEND_PROJECT_RESULTS_SUCCESS'
export const SEND_PROJECT_RESULTS_ERROR = 'SEND_PROJECT_RESULTS_ERROR'

export const DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST'
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS'
export const DELETE_PROJECT_ERROR = 'DELETE_PROJECT_ERROR'

//training projects
export const LOAD_TRAINING_PROJECTS_REQUEST = 'LOAD_TRAINING_PROJECTS_REQUEST'
export const LOAD_TRAINING_PROJECTS_SUCCESS = 'LOAD_TRAINING_PROJECTS_SUCCESS'
export const LOAD_TRAINING_PROJECTS_ERROR = 'LOAD_TRAINING_PROJECTS_ERROR'

export const CREATE_TRAINING_PROJECT_REQUEST = 'CREATE_TRAINING_PROJECT_REQUEST'
export const CREATE_TRAINING_PROJECT_SUCCESS = 'CREATE_TRAINING_PROJECT_SUCCESS'
export const CREATE_TRAINING_PROJECT_ERROR = 'CREATE_TRAINING_PROJECT_ERROR'

export const END_TRAINING_PROJECT_REQUEST = 'END_TRAINING_PROJECT_REQUEST'
export const END_TRAINING_PROJECT_SUCCESS = 'END_TRAINING_PROJECT_SUCCESS'
export const END_TRAINING_PROJECT_ERROR = 'END_TRAINING_PROJECT_ERROR'

//vacations
export const LOAD_VACATIONS_REQUEST = 'LOAD_VACATIONS_REQUEST'
export const LOAD_VACATIONS_SUCCESS = 'LOAD_VACATIONS_SUCCESS'
export const LOAD_VACATIONS_ERROR = 'LOAD_VACATIONS_ERROR'

export const LOAD_EMPLOYEE_VACATIONS_REQUEST = 'LOAD_EMPLOYEE_VACATIONS_REQUEST'
export const LOAD_EMPLOYEE_VACATIONS_SUCCESS = 'LOAD_EMPLOYEE_VACATIONS_SUCCESS'
export const LOAD_EMPLOYEE_VACATIONS_ERROR = 'LOAD_EMPLOYEE_VACATIONS_ERROR'

export const LOAD_TODAY_VACATIONS_REQUEST = 'LOAD_TODAY_VACATIONS_REQUEST'
export const LOAD_TODAY_VACATIONS_SUCCESS = 'LOAD_TODAY_VACATIONS_SUCCESS'
export const LOAD_TODAY_VACATIONS_ERROR = 'LOAD_TODAY_VACATIONS_ERROR'

export const CREATE_VACATION_REQUEST = 'CREATE_VACATION_REQUEST'
export const CREATE_VACATION_SUCCESS = 'CREATE_VACATION_SUCCESS'
export const CREATE_VACATION_ERROR = 'CREATE_VACATION_ERROR'

export const UPDATE_VACATION_REQUEST = 'UPDATE_VACATION_REQUEST'
export const UPDATE_VACATION_SUCCESS = 'UPDATE_VACATION_SUCCESS'
export const UPDATE_VACATION_ERROR = 'UPDATE_VACATION_ERROR'

export const DELETE_VACATION_REQUEST = 'DELETE_VACATION_REQUEST'
export const DELETE_VACATION_SUCCESS = 'DELETE_VACATION_SUCCESS'
export const DELETE_VACATION_ERROR = 'DELETE_VACATION_ERROR'

export const LOAD_VACATION_PERIODS_REQUEST = 'LOAD_VACATION_PERIOD_REQUEST'
export const LOAD_VACATION_PERIODS_SUCCESS = 'LOAD_VACATION_PERIOD_SUCCESS'
export const LOAD_VACATION_PERIODS_ERROR = 'LOAD_VACATION_PERIOD_ERROR'

export const CREATE_VACATION_PERIOD_REQUEST = 'CREATE_VACATION_PERIOD_REQUEST'
export const CREATE_VACATION_PERIOD_SUCCESS = 'CREATE_VACATION_PERIOD_SUCCESS'
export const CREATE_VACATION_PERIOD_ERROR = 'CREATE_VACATION_PERIOD_ERROR'

export const RESOLVE_CONFLICTED_VACATIONS_REQUEST = 'RESOLVE_CONFLICTED_VACATIONS_REQUEST'
export const RESOLVE_CONFLICTED_VACATIONS_SUCCESS = 'RESOLVE_CONFLICTED_VACATIONS_SUCCESS'
export const RESOLVE_CONFLICTED_VACATIONS_ERROR = 'RESOLVE_CONFLICTED_VACATIONS_ERROR'

export const DELETE_VACATION_PERIOD_REQUEST = 'DELETE_VACATION_PERIOD_REQUEST'
export const DELETE_VACATION_PERIOD_SUCCESS = 'DELETE_VACATION_PERIOD_SUCCESS'
export const DELETE_VACATION_PERIOD_ERROR = 'DELETE_VACATION_PERIOD_ERROR'

//Sicknesses
export const LOAD_SICKNESSES_REQUEST = 'LOAD_SICKNESSES_REQUEST'
export const LOAD_SICKNESSES_SUCCESS = 'LOAD_SICKNESSES_SUCCESS'
export const LOAD_SICKNESSES_ERROR = 'LOAD_SICKNESSES_ERROR'

export const LOAD_TODAY_SICKNESSES_REQUEST = 'LOAD_TODAY_SICKNESSES_REQUEST'
export const LOAD_TODAY_SICKNESSES_SUCCESS = 'LOAD_TODAY_SICKNESSES_SUCCESS'
export const LOAD_TODAY_SICKNESSES_ERROR = 'LOAD_TODAY_SICKNESSES_ERROR'

export const LOAD_EMPLOYEE_SICKNESSES_REQUEST = 'LOAD_EMPLOYEE_SICKNESSES_REQUEST'
export const LOAD_EMPLOYEE_SICKNESSES_SUCCESS = 'LOAD_EMPLOYEE_SICKNESSES_SUCCESS'
export const LOAD_EMPLOYEE_SICKNESSES_ERROR = 'LOAD_EMPLOYEE_SICKNESSES_ERROR'

export const CREATE_SICKNESS_REQUEST = 'CREATE_SICKNESS_REQUEST'
export const CREATE_SICKNESS_SUCCESS = 'CREATE_SICKNESS_SUCCESS'
export const CREATE_SICKNESS_ERROR = 'CREATE_SICKNESS_ERROR'

export const UPDATE_SICKNESS_REQUEST = 'UPDATE_SICKNESS_REQUEST'
export const UPDATE_SICKNESS_SUCCESS = 'UPDATE_SICKNESS_SUCCESS'
export const UPDATE_SICKNESS_ERROR = 'UPDATE_SICKNESS_ERROR'

export const UPLOAD_SICKNESS_FILE_REQUEST = 'UPLOAD_SICKNESS_FILE_REQUEST'
export const UPLOAD_SICKNESS_FILE_SUCCESS = 'UPLOAD_SICKNESS_FILE_SUCCESS'
export const UPLOAD_SICKNESS_FILE_ERROR = 'UPLOAD_SICKNESS_FILE_ERROR'

export const DELETE_SICKNESS_FILE_REQUEST = 'DELETE_SICKNESS_FILE_REQUEST'
export const DELETE_SICKNESS_FILE_SUCCESS = 'DELETE_SICKNESS_FILE_SUCCESS'
export const DELETE_SICKNESS_FILE_ERROR = 'DELETE_SICKNESS_FILE_ERROR'

export const DELETE_SICKNESS_REQUEST = 'DELETE_SICKNESS_REQUEST'
export const DELETE_SICKNESS_SUCCESS = 'DELETE_SICKNESS_SUCCESS'
export const DELETE_SICKNESS_ERROR = 'DELETE_SICKNESS_ERROR'

//invoices
export const LOAD_INVOICES_REQUEST = 'LOAD_INVOICES_REQUEST'
export const LOAD_INVOICES_SUCCESS = 'LOAD_INVOICES_SUCCESS'
export const LOAD_INVOICES_ERROR = 'LOAD_INVOICES_ERROR'

export const ADD_PROJECTS_TO_INVOICE_REQUEST = 'ADD_PROJECTS_TO_INVOICE_REQUEST'
export const ADD_PROJECTS_TO_INVOICE_SUCCESS = 'ADD_PROJECTS_TO_INVOICE_SUCCESS'
export const ADD_PROJECTS_TO_INVOICE_ERROR = 'ADD_PROJECTS_TO_INVOICE_ERROR'

export const CREATE_INVOICE_REQUEST = 'CREATE_INVOICE_REQUEST'
export const CREATE_INVOICE_SUCCESS = 'CREATE_INVOICE_SUCCESS'
export const CREATE_INVOICE_ERROR = 'CREATE_INVOICE_ERROR'

export const FORMAT_INVOICE_REQUEST = 'FORMAT_INVOICE_REQUEST'
export const FORMAT_INVOICE_SUCCESS = 'FORMAT_INVOICE_SUCCESS'
export const FORMAT_INVOICE_ERROR = 'FORMAT_INVOICE_ERROR'

export const SEND_INVOICE_REQUEST = 'SEND_INVOICE_REQUEST'
export const SEND_INVOICE_SUCCESS = 'SEND_INVOICE_SUCCESS'
export const SEND_INVOICE_ERROR = 'SEND_INVOICE_ERROR'

export const PAY_INVOICE_REQUEST = 'PAY_INVOICE_REQUEST'
export const PAY_INVOICE_SUCCESS = 'PAY_INVOICE_SUCCESS'
export const PAY_INVOICE_ERROR = 'PAY_INVOICE_ERROR'

export const DELETE_INVOICE_REQUEST = 'DELETE_INVOICE_REQUEST'
export const DELETE_INVOICE_SUCCESS = 'DELETE_INVOICE_SUCCESS'
export const DELETE_INVOICE_ERROR = 'DELETE_INVOICE_ERROR'

//invoice file
export const UPLOAD_INVOICE_FILE_REQUEST = 'UPLOAD_INVOICE_FILE_REQUEST'
export const UPLOAD_INVOICE_FILE_SUCCESS = 'UPLOAD_INVOICE_FILE_SUCCESS'
export const UPLOAD_INVOICE_FILE_ERROR = 'UPLOAD_INVOICE_FILE_ERROR'

export const DOWNLOAD_INVOICE_FILE_REQUEST = 'DOWNLOAD_INVOICE_FILE_REQUEST'
export const DOWNLOAD_INVOICE_FILE_SUCCESS = 'DOWNLOAD_INVOICE_FILE_SUCCESS'
export const DOWNLOAD_INVOICE_FILE_ERROR = 'DOWNLOAD_INVOICE_FILE_ERROR'

export const DELETE_INVOICE_FILE_REQUEST = 'DELETE_INVOICE_FILE_REQUEST'
export const DELETE_INVOICE_FILE_SUCCESS = 'DELETE_INVOICE_FILE_SUCCESS'
export const DELETE_INVOICE_FILE_ERROR = 'DELETE_INVOICE_FILE_ERROR'

//publishers
export const LOAD_PUBLISHERS_REQUEST = 'LOAD_PUBLISHERS_REQUEST'
export const LOAD_PUBLISHERS_SUCCESS = 'LOAD_PUBLISHERS_SUCCESS'
export const LOAD_PUBLISHERS_ERROR = 'LOAD_PUBLISHERS_ERROR'

export const GET_PUBLISHER_REQUEST = 'GET_PUBLISHER_REQUEST'
export const GET_PUBLISHER_SUCCESS = 'GET_PUBLISHER_SUCCESS'
export const GET_PUBLISHER_ERROR = 'GET_PUBLISHER_ERROR'

export const LOAD_SHORT_PUBLISHERS_REQUEST = 'LOAD_SHORT_PUBLISHERS_REQUEST'
export const LOAD_SHORT_PUBLISHERS_SUCCESS = 'LOAD_SHORT_PUBLISHERS_SUCCESS'
export const LOAD_SHORT_PUBLISHERS_ERROR = 'LOAD_SHORT_PUBLISHERS_ERROR'

export const ADD_PUBLISHER_REQUEST = 'ADD_PUBLISHER_REQUEST'
export const ADD_PUBLISHER_SUCCESS = 'ADD_PUBLISHER_SUCCESS'
export const ADD_PUBLISHER_ERROR = 'ADD_PUBLISHER_ERROR'

export const UPDATE_PUBLISHERS_EMPLOYEES_REQUEST = 'UPDATE_PUBLISHERS_EMPLOYEES_REQUEST'
export const UPDATE_PUBLISHERS_EMPLOYEES_SUCCESS = 'UPDATE_PUBLISHERS_EMPLOYEES_SUCCESS'
export const UPDATE_PUBLISHERS_EMPLOYEES_ERROR = 'UPDATE_PUBLISHERS_EMPLOYEES_ERROR'

export const ARCHIVE_PUBLISHER_REQUEST = 'ARCHIVE_PUBLISHER_REQUEST'
export const ARCHIVE_PUBLISHER_SUCCESS = 'ARCHIVE_PUBLISHER_SUCCESS'
export const ARCHIVE_PUBLISHER_ERROR = 'ARCHIVE_PUBLISHER_ERROR'

//Statistics
export const LOAD_EMPLOYEES_YEAR_STATISTIC_REQUEST = 'LOAD_EMPLOYEES_YEAR_STATISTIC_REQUEST'
export const LOAD_EMPLOYEES_YEAR_STATISTIC_SUCCESS = 'LOAD_EMPLOYEES_YEAR_STATISTIC_SUCCESS'
export const LOAD_EMPLOYEES_YEAR_STATISTIC_ERROR = 'LOAD_EMPLOYEES_YEAR_STATISTIC_ERROR'

export const LOAD_EMPLOYEE_YEAR_STATISTIC_REQUEST = 'LOAD_EMPLOYEE_YEAR_STATISTIC_REQUEST'
export const LOAD_EMPLOYEE_YEAR_STATISTIC_SUCCESS = 'LOAD_EMPLOYEE_YEAR_STATISTIC_SUCCESS'
export const LOAD_EMPLOYEE_YEAR_STATISTIC_ERROR = 'LOAD_EMPLOYEE_YEAR_STATISTIC_ERROR'

export const FORCE_COMPANY_STAT_REQUEST = 'FORCE_COMPANY_STAT_REQUEST'
export const FORCE_COMPANY_STAT_SUCCESS = 'FORCE_COMPANY_STAT_SUCCESS'
export const FORCE_COMPANY_STAT_ERROR = 'FORCE_COMPANY_STAT_ERROR'

export const FORCE_EMPLOYEE_STAT_REQUEST = 'FORCE_EMPLOYEE_STAT_REQUEST'
export const FORCE_EMPLOYEE_STAT_SUCCESS = 'FORCE_EMPLOYEE_STAT_SUCCESS'
export const FORCE_EMPLOYEE_STAT_ERROR = 'FORCE_EMPLOYEE_STAT_ERROR'

export const LOAD_COMPANY_STAT_REQUEST = 'LOAD_COMPANY_STAT_REQUEST'
export const LOAD_COMPANY_STAT_SUCCESS = 'LOAD_COMPANY_STAT_SUCCESS'
export const LOAD_COMPANY_STAT_ERROR = 'LOAD_COMPANY_STAT_ERROR'

export const LOAD_MENTORS_STAT_REQUEST = 'LOAD_MENTORS_STAT_REQUEST'
export const LOAD_MENTORS_STAT_SUCCESS = 'LOAD_MENTORS_STAT_SUCCESS'
export const LOAD_MENTORS_STAT_ERROR = 'LOAD_MENTORS_STAT_ERROR'

// Time tracking
export const LOAD_WORK_CHECKINS_REQUEST = 'LOAD_WORK_CHECKINS_REQUEST'
export const LOAD_WORK_CHECKINS_SUCCESS = 'LOAD_WORK_CHECKINS_SUCCESS'
export const LOAD_WORK_CHECKINS_ERROR = 'LOAD_WORK_CHECKINS_ERROR'

export const LOAD_MONTH_CHECKINS_REQUEST = 'LOAD_MONTH_CHECKINS_REQUEST'
export const LOAD_MONTH_CHECKINS_SUCCESS = 'LOAD_MONTH_CHECKINS_SUCCESS'
export const LOAD_MONTH_CHECKINS_ERROR = 'LOAD_MONTH_CHECKINS_ERROR'

export const LOAD_EMPLOYEE_WORK_CHECKINS_REQUEST = 'LOAD_EMPLOYEE_WORK_CHECKINS_REQUEST'
export const LOAD_EMPLOYEE_WORK_CHECKINS_SUCCESS = 'LOAD_EMPLOYEE_WORK_CHECKINS_SUCCESS'
export const LOAD_EMPLOYEE_WORK_CHECKINS_ERROR = 'LOAD_EMPLOYEE_WORK_CHECKINS_ERROR'

export const ADD_WORK_CHECKIN_REQUEST = 'ADD_WORK_CHECKIN_REQUEST'
export const ADD_WORK_CHECKIN_SUCCESS = 'ADD_WORK_CHECKIN_SUCCESS'
export const ADD_WORK_CHECKIN_ERROR = 'ADD_WORK_CHECKIN_ERROR'

export const ADD_END_DAY_WORK_CHECKIN_REQUEST = 'ADD_END_DAY_WORK_CHECKIN_REQUEST'
export const ADD_END_DAY_WORK_CHECKIN_SUCCESS = 'ADD_END_DAY_WORK_CHECKIN_SUCCESS'
export const ADD_END_DAY_WORK_CHECKIN_ERROR = 'ADD_END_DAY_WORK_CHECKIN_ERROR'

export const UPDATE_WORK_CHECKIN_REQUEST = 'UPDATE_WORK_CHECKIN_REQUEST'
export const UPDATE_WORK_CHECKIN_SUCCESS = 'UPDATE_WORK_CHECKIN_SUCCESS'
export const UPDATE_WORK_CHECKIN_ERROR = 'UPDATE_WORK_CHECKIN_ERROR'

//Visits
export const LOAD_VISITS_REQUEST = 'LOAD_VISITS_REQUEST'
export const LOAD_VISITS_SUCCESS = 'LOAD_VISITS_SUCCESS'
export const LOAD_VISITS_ERROR = 'LOAD_VISITS_ERROR'

export const CREATE_PROJECT_VISIT_CHECK_REQUEST = 'CREATE_PROJECT_VISIT_CHECK_REQUEST'
export const CREATE_PROJECT_VISIT_CHECK_SUCCESS = 'CREATE_PROJECT_VISIT_CHECK_SUCCESS'
export const CREATE_PROJECT_VISIT_CHECK_ERROR = 'CREATE_PROJECT_VISIT_CHECK_ERROR'

export const CREATE_CONCEPT_VISIT_CHECK_REQUEST = 'CREATE_CONCEPT_VISIT_CHECK_REQUEST'
export const CREATE_CONCEPT_VISIT_CHECK_SUCCESS = 'CREATE_CONCEPT_VISIT_CHECK_SUCCESS'
export const CREATE_CONCEPT_VISIT_CHECK_ERROR = 'CREATE_CONCEPT_VISIT_CHECK_ERROR'

export const FIX_PROJECT_VISITS_REQUEST = 'FIX_PROJECT_VISITS_REQUEST'
export const FIX_PROJECT_VISITS_SUCCESS = 'FIX_PROJECT_VISITS_SUCCESS'
export const FIX_PROJECT_VISITS_ERROR = 'FIX_PROJECT_VISITS_ERROR'

//Ideas
export const LOAD_IDEAS_REQUEST = 'LOAD_IDEAS_REQUEST'
export const LOAD_IDEAS_SUCCESS = 'LOAD_IDEAS_SUCCESS'
export const LOAD_IDEAS_ERROR = 'LOAD_IDEAS_ERROR'

export const LOAD_ARCHIVED_IDEAS_REQUEST = 'LOAD_ARCHIVED_IDEAS_REQUEST'
export const LOAD_ARCHIVED_IDEAS_SUCCESS = 'LOAD_ARCHIVED_IDEAS_SUCCESS'
export const LOAD_ARCHIVED_IDEAS_ERROR = 'LOAD_ARCHIVED_IDEAS_ERROR'

export const LOAD_EMPLOYEE_IDEAS_REQUEST = 'LOAD_EMPLOYEE_IDEAS_REQUEST'
export const LOAD_EMPLOYEE_IDEAS_SUCCESS = 'LOAD_EMPLOYEE_IDEAS_SUCCESS'
export const LOAD_EMPLOYEE_IDEAS_ERROR = 'LOAD_EMPLOYEE_IDEAS_ERROR'

export const CREATE_IDEA_REQUEST = 'CREATE_IDEA_REQUEST'
export const CREATE_IDEA_SUCCESS = 'CREATE_IDEA_SUCCESS'
export const CREATE_IDEA_ERROR = 'CREATE_IDEA_ERROR'

export const USE_IDEA_REQUEST = 'USE_IDEA_REQUEST'
export const USE_IDEA_SUCCESS = 'USE_IDEA_SUCCESS'
export const USE_IDEA_ERROR = 'USE_IDEA_ERROR'

export const ARCHIVE_IDEA_REQUEST = 'ARCHIVE_IDEA_REQUEST'
export const ARCHIVE_IDEA_SUCCESS = 'ARCHIVE_IDEA_SUCCESS'
export const ARCHIVE_IDEA_ERROR = 'ARCHIVE_IDEA_ERROR'

export const UNARCHIVE_IDEA_REQUEST = 'UNARCHIVE_IDEA_REQUEST'
export const UNARCHIVE_IDEA_SUCCESS = 'UNARCHIVE_IDEA_SUCCESS'
export const UNARCHIVE_IDEA_ERROR = 'UNARCHIVE_IDEA_ERROR'

export const DELETE_IDEA_REQUEST = 'DELETE_IDEA_REQUEST'
export const DELETE_IDEA_SUCCESS = 'DELETE_IDEA_SUCCESS'
export const DELETE_IDEA_ERROR = 'DELETE_IDEA_ERROR'

//Surveys
export const LOAD_PROJECT_SURVEY_REQUEST = 'LOAD_PROJECT_SURVEY_REQUEST'
export const LOAD_PROJECT_SURVEY_SUCCESS = 'LOAD_PROJECT_SURVEY_SUCCESS'
export const LOAD_PROJECT_SURVEY_ERROR = 'LOAD_PROJECT_SURVEY_ERROR'

export const LOAD_EMPLOYEE_PROJECT_SURVEYS_REQUEST = 'LOAD_EMPLOYEE_PROJECT_SURVEYS_REQUEST'
export const LOAD_EMPLOYEE_PROJECT_SURVEYS_SUCCESS = 'LOAD_EMPLOYEE_PROJECT_SURVEYS_SUCCESS'
export const LOAD_EMPLOYEE_PROJECT_SURVEYS_ERROR = 'LOAD_EMPLOYEE_PROJECT_SURVEYS_ERROR'

export const LOAD_WEEKLY_MONTHLY_SURVEYS_REQUEST = 'LOAD_WEEKLY_MONTHLY_SURVEYS_REQUEST'
export const LOAD_WEEKLY_MONTHLY_SURVEYS_SUCCESS = 'LOAD_WEEKLY_MONTHLY_SURVEYS_SUCCESS'
export const LOAD_WEEKLY_MONTHLY_SURVEYS_ERROR = 'LOAD_WEEKLY_MONTHLY_SURVEYS_ERROR'

export const LOAD_PROJECT_SURVEYS_REQUEST = 'LOAD_PROJECT_SURVEYS_REQUEST'
export const LOAD_PROJECT_SURVEYS_SUCCESS = 'LOAD_PROJECT_SURVEYS_SUCCESS'
export const LOAD_PROJECT_SURVEYS_ERROR = 'LOAD_PROJECT_SURVEYS_ERROR'

export const LOAD_PARTY_SURVEYS_REQUEST = 'LOAD_PARTY_SURVEYS_REQUEST'
export const LOAD_PARTY_SURVEYS_SUCCESS = 'LOAD_PARTY_SURVEYS_SUCCESS'
export const LOAD_PARTY_SURVEYS_ERROR = 'LOAD_PARTY_SURVEYS_ERROR'

//Parties
export const LOAD_PARTIES_REQUEST = 'LOAD_PARTIES_REQUEST'
export const LOAD_PARTIES_SUCCESS = 'LOAD_PARTIES_SUCCESS'
export const LOAD_PARTIES_ERROR = 'LOAD_PARTIES_ERROR'

export const LOAD_MEMBER_PARTIES_REQUEST = 'LOAD_MEMBER_PARTIES_REQUEST'
export const LOAD_MEMBER_PARTIES_SUCCESS = 'LOAD_MEMBER_PARTIES_SUCCESS'
export const LOAD_MEMBER_PARTIES_ERROR = 'LOAD_MEMBER_PARTIES_ERROR'

export const CREATE_PARTY_REQUEST = 'CREATE_PARTY_REQUEST'
export const CREATE_PARTY_SUCCESS = 'CREATE_PARTY_SUCCESS'
export const CREATE_PARTY_ERROR = 'CREATE_PARTY_ERROR'

export const CONFIRM_PARTY_REQUEST = 'CONFIRM_PARTY_REQUEST'
export const CONFIRM_PARTY_SUCCESS = 'CONFIRM_PARTY_SUCCESS'
export const CONFIRM_PARTY_ERROR = 'CONFIRM_PARTY_ERROR'

export const DONE_PARTY_REQUEST = 'DONE_PARTY_REQUEST'
export const DONE_PARTY_SUCCESS = 'DONE_PARTY_SUCCESS'
export const DONE_PARTY_ERROR = 'DONE_PARTY_ERROR'

export const UPDATE_PARTY_REQUEST = 'UPDATE_PARTY_REQUEST'
export const UPDATE_PARTY_SUCCESS = 'UPDATE_PARTY_SUCCESS'
export const UPDATE_PARTY_ERROR = 'UPDATE_PARTY_ERROR'

export const SEND_INVITATION_ANSWER_REQUEST = 'SEND_INVITATION_ANSWER_REQUEST'
export const SEND_INVITATION_ANSWER_SUCCESS = 'SEND_INVITATION_ANSWER_SUCCESS'
export const SEND_INVITATION_ANSWER_ERROR = 'SEND_INVITATION_ANSWER_ERROR'

export const DELETE_PARTY_SUCCESS = 'DELETE_PARTY_SUCCESS'
export const DELETE_PARTY_REQUEST = 'DELETE_PARTY_REQUEST'
export const DELETE_PARTY_ERROR = 'DELETE_PARTY_ERROR'

//Todolists
export const LOAD_TODOLISTS_REQUEST = 'LOAD_TODOLISTS_REQUEST'
export const LOAD_TODOLISTS_SUCCESS = 'LOAD_TODOLISTS_SUCCESS'
export const LOAD_TODOLISTS_ERROR = 'LOAD_TODOLISTS_ERROR'

export const GET_EMPLOYEE_TODOLIST_REQUEST = 'GET_EMPLOYEE_TODOLIST_REQUEST'
export const GET_EMPLOYEE_TODOLIST_SUCCESS = 'GET_EMPLOYEE_TODOLIST_SUCCESS'
export const GET_EMPLOYEE_TODOLIST_ERROR = 'GET_EMPLOYEE_TODOLIST_ERROR'

export const ADD_TODO_REQUEST = 'ADD_TODO_REQUEST'
export const ADD_TODO_SUCCESS = 'ADD_TODO_SUCCESS'
export const ADD_TODO_ERROR = 'ADD_TODO_ERROR'

export const NO_TODOS_REQUEST = 'NO_TODOS_REQUEST'
export const NO_TODOS_SUCCESS = 'NO_TODOS_SUCCESS'
export const NO_TODOS_ERROR = 'NO_TODOS_ERROR'

export const UPDATE_TODO_STATUS_REQUEST = 'UPDATE_TODO_STATUS_REQUEST'
export const UPDATE_TODO_STATUS_SUCCESS = 'UPDATE_TODO_STATUS_SUCCESS'
export const UPDATE_TODO_STATUS_ERROR = 'UPDATE_TODO_STATUS_ERROR'

//System settings
export const LOAD_SYSTEM_SETTINGS_REQUEST = 'LOAD_SYSTEM_SETTINGS_REQUEST'
export const LOAD_SYSTEM_SETTINGS_SUCCESS = 'LOAD_SYSTEM_SETTINGS_SUCCESS'
export const LOAD_SYSTEM_SETTINGS_ERROR = 'LOAD_SYSTEM_SETTINGTS_ERROR'

export const UPDATE_SYSTEM_SETTINGS_REQUEST = 'UPDATE_SYSTEM_SETTINGS_REQUEST'
export const UPDATE_SYSTEM_SETTINGS_SUCCESS = 'UPDATE_SYSTEM_SETTINGS_SUCCESS'
export const UPDATE_SYSTEM_SETTINGS_ERROR = 'UPDATE_SYSTEM_SETTINGTS_ERROR'

//KPI
export const LOAD_KPI_REQUEST = 'LOAD_KPI_REQUEST'
export const LOAD_KPI_SUCCESS = 'LOAD_KPI_SUCCESS'
export const LOAD_KPI_ERROR = 'LOAD_KPI_ERROR'

export const LOAD_EMPLOYEE_KPI_REQUEST = 'LOAD_EMPLOYEE_KPI_REQUEST'
export const LOAD_EMPLOYEE_KPI_SUCCESS = 'LOAD_EMPLOYEE_KPI_SUCCESS'
export const LOAD_EMPLOYEE_KPI_ERROR = 'LOAD_EMPLOYEE_KPI_ERROR'

export const LOAD_DEDLINED_KPI_REQUEST = 'LOAD_DEDLINED_KPI_REQUEST'
export const LOAD_DEDLINED_KPI_SUCCESS = 'LOAD_DEDLINED_KPI_SUCCESS'
export const LOAD_DEDLINED_KPI_ERROR = 'LOAD_DEDLINED_KPI_ERROR'

export const CREATE_KPI_REQUEST = 'CREATE_KPI_REQUEST'
export const CREATE_KPI_SUCCESS = 'CREATE_KPI_SUCCESS'
export const CREATE_KPI_ERROR = 'CREATE_KPI_ERROR'

export const CHECK_KPI_REQUEST = 'CHECK_KPI_REQUEST'
export const CHECK_KPI_SUCCESS = 'CHECK_KPI_SUCCESS'
export const CHECK_KPI_ERROR = 'CHECK_KPI_ERROR'

export const DONE_KPI_REQUEST = 'DONE_KPI_REQUEST'
export const DONE_KPI_SUCCESS = 'DONE_KPI_SUCCESS'
export const DONE_KPI_ERROR = 'DONE_KPI_ERROR'

export const DELETE_KPI_SUCCESS = 'DELETE_KPI_SUCCESS'
export const DELETE_KPI_REQUEST = 'DELETE_KPI_REQUEST'
export const DELETE_KPI_ERROR = 'DELETE_KPI_ERROR'

//clear state
export const SET_DEFAULT_STATE = 'SET_DEFAULT_STATE'

//events handling
export const EVENTS_DISABLE = 'EVENTS_DISABLE'
export const EVENTS_ENABLE = 'EVENTS_ENABLE'

//notifications
export const LOAD_EMPLOYEE_NOTIFICATIONS_REQUEST = 'LOAD_EMPLOYEE_NOTIFICATIONS_REQUEST'
export const LOAD_EMPLOYEE_NOTIFICATIONS_SUCCESS = 'LOAD_EMPLOYEE_NOTIFICATIONS_SUCCESS'
export const LOAD_EMPLOYEE_NOTIFICATIONS_ERROR = 'LOAD_EMPLOYEE_NOTIFICATIONS_ERROR'

export const UPDATE_NOTIFICATION_STATUS_REQUEST = 'UPDATE_NOTIFICATION_STATUS_REQUEST'
export const UPDATE_NOTIFICATION_STATUS_SUCCESS = 'UPDATE_NOTIFICATION_STATUS_SUCCESS'
export const UPDATE_NOTIFICATION_STATUS_ERROR = 'UPDATE_NOTIFICATION_STATUS_ERROR'