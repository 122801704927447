import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

import UniversalTable from 'components/UniversalTable';

import {
	useArchiveContractorMutation,
	useDeleteContractorMutation, useLoadContractorsQuery,
	useUnarchiveContractorMutation
} from '../../../../../redux/slices/financeDictionaries'
import { Button, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap'
import { FaArchive, FaEdit, FaTrashAlt } from 'react-icons/fa'
import { FaArrowRotateLeft } from 'react-icons/fa6'
import UpdateContractorModal from '../Modals/UpdateContractorModal'
import { RolesEnum } from '../../../../../types/roles'
import RoleWrapper from '../../../../../components/RoleWrapper'
import DeleteContractorModal from '../Modals/DeleteContractorModal'
import { Contractor } from '../../../../../types/finance/contractor'
import TableError from '../../../../../components/TableError'

const columns = [
	{
		dataField: 'name',
		text: 'ContractorsTable_name',
		sort: false,
		width: '20%',
		className: 'text-center',
	},
	{
		dataField: 'type',
		text: 'ContractorsTable_Type',
		sort: false,
		width: '10%',
		className: 'text-center',
	},
	{
		dataField: 'class',
		text: 'ContractorsTable_Class',
		sort: false,
		width: '10%',
		className: 'text-center',
	},
	{
		dataField: 'fullName',
		text: 'ContractorsTable_fullName',
		sort: false,
		width: '20%',
		className: 'text-center',
	},
	{
		dataField: 'taxCode',
		text: 'ContractorsTable_taxCode',
		sort: false,
		width: '10%',
		className: 'text-center',
	},
	{
		dataField: 'address',
		text: 'ContractorsTable_registrationAddress',
		sort: false,
		width: '20%',
		className: 'text-center',
	},
	{
		dataField: 'actions',
		text: 'actions',
		sort: false,
		width: '10%',
		className: 'text-center',
	}
];

interface ContractorRowProps {
	contractor: Contractor;
	handlers: any
}

function ContractorRow({ contractor, handlers }: ContractorRowProps) {
	const { t, i18n } = useTranslation();

	const [showUpdate, setShowUpdate] = useState(false)
	const [showDelete, setShowDelete] = useState(false)

	const [archiveContractor, unarchiveContractor, deleteContractor] = handlers

	const renderTooltip = (content: string) => (props: any) => (
		<Tooltip id={`tooltip-${contractor._id}`} className={'custom-tooltip top'} {...props}>
			{content}
		</Tooltip>
	);

	return (
		<tr>
			<td className="text-center">{contractor.name}</td>
			<td className="text-center">{(i18n.language === 'uk' ? contractor.type.ukName : contractor.type.enName)}</td>
			<td className="text-center">{(i18n.language === 'uk' ? contractor.class.ukName : contractor.class.enName)}</td>
			<td className="text-center">{contractor.fullName}</td>
			<td className="text-center">{contractor.taxCode}</td>
			<td className="text-center">{contractor.registrationAddress}</td>
			<td className="text-center">
				<div className='d-flex align-middle justify-content-center'>
					{
						!contractor.isArchived && (
							<>
								<UpdateContractorModal
									key={contractor.name + Math.random()}
									contractor={contractor}
									show={showUpdate}
									setShow={setShowUpdate}
								/>
								<OverlayTrigger
									placement="top"
									overlay={renderTooltip(t('edit'))}
								>
									<Button
										variant="outline-primary"
										size="sm"
										className="d-flex align-items-center justify-content-center p-0 mr-2"
										style={{ width: '28px', height: '28px', borderRadius: '4px' }}
										onClick={(e) => {
											e.stopPropagation()
											setShowUpdate(true)
										}}
										aria-label={t('edit')}
									>
										<FaEdit size={18} />
									</Button>
								</OverlayTrigger>
							</>
						)
					}
					{
						contractor.isArchived ? (
							<OverlayTrigger
								placement="top"
								overlay={renderTooltip(t('unarchive'))}
							>
								<Button
									variant="outline-success"
									size="sm"
									className="d-flex align-items-center justify-content-center p-0 mr-2"
									style={{ width: '28px', height: '28px', borderRadius: '4px' }}
									onClick={(e) => {
										e.stopPropagation()
										unarchiveContractor(contractor._id)
									}}
									aria-label={t('unarchive')}
								>
									<FaArrowRotateLeft size={18} />
								</Button>
							</OverlayTrigger>
						) : (
							<OverlayTrigger
								placement="top"
								overlay={renderTooltip(t('archive'))}
							>
								<Button
									variant="outline-danger"
									size="sm"
									className="d-flex align-items-center justify-content-center p-0"
									style={{ width: '28px', height: '28px', borderRadius: '4px' }}
									onClick={(e) => {
										e.stopPropagation()
										archiveContractor(contractor._id)
									}}
									aria-label={t('archive')}
								>
									<FaArchive size={18} />
								</Button>
							</OverlayTrigger>
						)
					}
					<RoleWrapper requared_role={[RolesEnum.admin, RolesEnum.finances]}>
						{
							contractor.isArchived && (
								<>
									<DeleteContractorModal
										show={showDelete}
										setShow={setShowDelete}
										onAccept={() => deleteContractor(contractor._id)}
									/>
									<OverlayTrigger
										placement="top"
										overlay={renderTooltip(t('delete'))}
									>
										<Button
											variant="outline-danger"
											size="sm"
											className="d-flex align-items-center justify-content-center p-0"
											style={{ width: '28px', height: '28px', borderRadius: '4px' }}
											onClick={(e) => {
												e.stopPropagation()
												setShowDelete(true)
											}}
											aria-label={t('delete')}
										>
											<FaTrashAlt size={18} />
										</Button>
									</OverlayTrigger>
								</>
							)
						}
					</RoleWrapper>
				</div>
			</td>
		</tr>
	);
}

function ContractorsTable() {
	const [archiveContractor] = useArchiveContractorMutation()
	const [unarchiveContractor] = useUnarchiveContractorMutation()
	const [deleteContractor] = useDeleteContractorMutation()

	const { data: contractorData, isLoading, isError } = useLoadContractorsQuery();

	if (isLoading) return <Spinner animation="grow" variant={'primary'} className="me-2" />;
	if (isError) return <TableError>Error loading data</TableError>;

	const unarchivedContractorData = (contractorData && contractorData.filter(entry => !entry.isArchived)) || []
	const archivedContractorData = (contractorData && contractorData.filter(entry => entry.isArchived)) || []

	const handlers = [archiveContractor, unarchiveContractor, deleteContractor]

	return <>
		{
			unarchivedContractorData && unarchivedContractorData.length > 0 ?
				<UniversalTable
					name={'ContractorsTable_tableName'}
					id={'contractorsTable ContractorsTable_tableName'}
					columns={columns}
					data={unarchivedContractorData}
					row={(contractor, i) => <ContractorRow handlers={handlers} key={i + 'contractorRow'} contractor={contractor} />}
					scrollable
					withPagination
				/> :
				<TableError>No contractors data.</TableError>
		}
		{
			archivedContractorData && archivedContractorData.length > 0 ?
				<UniversalTable
					name={'ContractorsTable_tableNameArchived'}
					id={'contractorsTable ContractorsTable_tableNameArchived'}
					columns={columns}
					data={archivedContractorData}
					row={(contractor, i) => <ContractorRow handlers={handlers} key={i + 'contractorRow'} contractor={contractor} />}
					scrollable
					withPagination
				/> : null
		}
	</>
}

export default ContractorsTable;
