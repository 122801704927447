import { ShortEmployee } from './employee'
import ProjectArtInfo from './project_art_info'
import { ShortPublisher } from './publisher'
import Visits from './visitCheck'

export enum TechApprovedEnum {
	not_checked = 'not_checked',
	approved = 'approved',
	disapproved = 'disapproved'
}

export enum ConceptStatusEnum {
	active = 'active',
	used = 'used',
	archived = 'archived',
	pinned = 'pinned'
}

export interface ConceptCreationData {
	name: string
	concept_link: string
	publishers_concept: boolean
	authors: string[]
	publisher?: string
	idea?: string
}

export interface ConceptPinnedInfo {
	_id: string
	developer?: ShortEmployee
	artist?: ShortEmployee
	producer?: ShortEmployee
	mentor?: ShortEmployee
	art_mentor?: ShortEmployee
}

export interface ConceptAuthor {
	_id: string
	employee: ShortEmployee
}

export default interface Concept {
	_id: string
	name: string
	tech_approv: {
		state: TechApprovedEnum
		date?: Date
		approver: string
	}
	authors: ConceptAuthor[]
	concept_link: string
	date: Date
	publishers_concept: boolean
	publisher: ShortPublisher
	status: ConceptStatusEnum
	art_info: ProjectArtInfo
	pinned_info?: ConceptPinnedInfo
	producer?: ShortEmployee
	visits?: Visits
	end_date: Date
}

export interface ShortConcept {
	_id: string
	name: string
	art_info: ProjectArtInfo
	publisher?: ShortPublisher
	pinned_info?: ConceptPinnedInfo
}

export interface PinnedConceptData {
	id: string
	publisher: string
	developer?: string
	artist?: string
	producer?: string
	mentor?: string
	art_mentor?: string
}
