import {
	addPropmotion,
	agreePromotionRequest,
	deletePropmotionAtId,
	getAllPromotions,
	getEmployeePromotions,
	rejectPromotionRequest,
	repeatPromotionRequest
} from 'api/request'

import { toast } from 'react-toastify'
import actions from 'redux/actions'
import { t } from 'i18next'
import Promotion, {
	AgreePromotionData,
	PromotionCreatingData,
	RejectPromotionData
} from 'types/promotion'

export const loadPromotionsFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.promotions.loadPromotionsRequest())
	try {
		const loadedPromotion: Promotion[] = await getAllPromotions()
		return dispatch(actions.dataActions.promotions.loadPromotionsSuccess(loadedPromotion))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.promotions.loadPromotionsError())
	}
}

export const loadEmployeePromotionsFunc = (id: string) => async (dispatch) => {
	dispatch(actions.dataActions.promotions.loadEmployeePromotionsRequest())
	try {
		const loadedPromotion: Promotion[] = await getEmployeePromotions(id)
		return dispatch(
			actions.dataActions.promotions.loadEmployeePromotionsSuccess(loadedPromotion)
		)
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.promotions.loadEmployeePromotionsError())
	}
}

export const createPromotionRequestFunc =
	(creationData: PromotionCreatingData) => async (dispatch) => {
		dispatch(actions.dataActions.promotions.sendRequestPromotionRequest())
		try {
			const createdPromotion: Promotion = await addPropmotion(creationData)
			toast.success(t('reduxToastSuccess_promotionRequest') as string)
			return dispatch(
				actions.dataActions.promotions.sendRequestPromotionSuccess(createdPromotion)
			)
		} catch (error: any) {
			toast.error(error.response.data.message)
			return dispatch(actions.dataActions.promotions.sendRequestPromotionError())
		}
	}

export const repeatPromotionRequestFunc = (id: string) => async (dispatch) => {
	dispatch(actions.dataActions.promotions.repeatPromotionRequest())
	try {
		const createdPromotionRequest: Promotion = await repeatPromotionRequest(id)
		toast.success(t('reduxToastSuccess_promotionRequest') as string)
		return dispatch(
			actions.dataActions.promotions.repeatPromotionSuccess(createdPromotionRequest)
		)
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.promotions.repeatPromotionError())
	}
}

export const agreePromotionRequestFunc = (agreeData: AgreePromotionData) => async (dispatch) => {
	dispatch(actions.dataActions.promotions.agreePromotionRequest())
	try {
		const agreedPromotion: Promotion = await agreePromotionRequest(agreeData)

		toast.success(t('reduxToastSuccess_agreePromotionRequest') as string)

		dispatch(actions.dataActions.employees.updateInfoEmployeeSuccess(agreedPromotion.user))

		return dispatch(actions.dataActions.promotions.agreePromotionSuccess(agreedPromotion))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.promotions.agreePromotionError())
	}
}

export const rejectPromotionRequestFunc = (rejectData: RejectPromotionData) => async (dispatch) => {
	dispatch(actions.dataActions.promotions.rejectPromotionRequest())
	try {
		const rejectedPromotion: Promotion = await rejectPromotionRequest(rejectData)

		toast.success(t('reduxToastSuccess_rejectPromotionRequest') as string)

		return dispatch(actions.dataActions.promotions.rejectPromotionSuccess(rejectedPromotion))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.promotions.rejectPromotionError())
	}
}

export const createPromotionFunc = (creationData: PromotionCreatingData) => async (dispatch) => {
	dispatch(actions.dataActions.promotions.createPromotionRequest())
	try {
		const createdPromotion: Promotion = await addPropmotion(creationData)
		toast.success(t('reduxToastSuccess_promotionCreated') as string)
		return dispatch(actions.dataActions.promotions.createPromotionSuccess(createdPromotion))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.promotions.createPromotionError())
	}
}

export const deletePromotionFunc = (id) => async (dispatch) => {
	dispatch(actions.dataActions.promotions.deletePromotionRequest())
	try {
		const deletedPromotion: Promotion = await deletePropmotionAtId(id)
		toast.success(t('reduxToastSuccess_promotionDeleted') as string)
		return dispatch(actions.dataActions.promotions.deletePromotionSuccess(deletedPromotion._id))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.promotions.deletePromotionError())
	}
}
