// SidebarContext.tsx
import React, { createContext, useState, useEffect, useCallback, ReactNode, useContext } from "react";

// Define the shape of the context
interface SidebarContextType {
	isSidebarOpen: boolean;
	toggleSidebar: () => void;
	setSidebar: (state: boolean) => void;
}

// Create Context with an appropriate type
const SidebarContext = createContext<SidebarContextType | undefined>(undefined);

// Props for SidebarProvider
interface SidebarProviderProps {
	children: ReactNode;
}

// Context Provider
export const SidebarProvider: React.FC<SidebarProviderProps> = ({ children }) => {
	const [isSidebarOpen, setSidebarOpen] = useState<boolean>(() => {
		const storedValue = localStorage.getItem("sidebarOpen");
		return storedValue ? JSON.parse(storedValue) : false;
	});

	const toggleSidebar = useCallback(() => {
		setSidebarOpen((prevState) => !prevState);
	}, []);

	const setSidebar = useCallback((state: boolean) => {
		setSidebarOpen((prevState) => !state);
	}, []);

	useEffect(() => {
		localStorage.setItem("sidebarOpen", JSON.stringify(isSidebarOpen));
	}, [isSidebarOpen]);

	return (
		<SidebarContext.Provider value={{ isSidebarOpen, toggleSidebar, setSidebar }}>
			{children}
		</SidebarContext.Provider>
	);
};

// Custom hook to safely access the SidebarContext
export const useSidebar = (): SidebarContextType => {
	const context = useContext(SidebarContext);
	if (!context) {
		throw new Error("useSidebar must be used within a SidebarProvider");
	}
	return context;
};
