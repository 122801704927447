import { addCheckin, addEndDayCheckin } from 'api/request'

import { toast } from 'react-toastify'
import actions from 'redux/actions'
import WorkCheckin from 'types/workCheckin'
import { t } from 'i18next'

export const addCheckinFunc = () => async (dispatch) => {
	dispatch(actions.userActions.addWorkCheckinRequest())
	try {
		const addedWorkChekin: WorkCheckin = await addCheckin()

		dispatch(actions.userActions.addWorkCheckinSuccess(addedWorkChekin))

		return true
	} catch (error: any) {
		toast.error(error.response.data.error)
		dispatch(actions.userActions.addWorkCheckinError())

		return false
	}
}

export const addEndDayCheckinFunc = (id: string) => async (dispatch) => {
	dispatch(actions.userActions.addEndDayWorkCheckinRequest())
	try {
		const addedEndDayWorkChekin: WorkCheckin = await addEndDayCheckin(id)
		toast.success(t('reduxToastSuccess_endDayCheckin') as string)
		return dispatch(actions.userActions.addEndDayWorkCheckinSuccess(addedEndDayWorkChekin))
	} catch (error: any) {
		toast.error(error.response.data.error)
		return dispatch(actions.userActions.addEndDayWorkCheckinError())
	}
}
