import {
	getAllSicknesses,
	createSickness,
	updateSickness,
	deleteSickness,
	getTodaySicknesses,
	getTwoMonthSicknesses,
	getEmployeeSicknesses,
	uploadSicknessFile,
	deleteSicknessFile
} from 'api/request'

import { toast } from 'react-toastify'
import actions from 'redux/actions'
import { t } from 'i18next'
import Sickness, {
	SicknessCreationData,
	SicknessFileDeleteDto,
	SicknessUpdateData
} from 'types/sickness'

export const loadSicknesses = () => async (dispatch) => {
	dispatch(actions.dataActions.sicknesses.loadSicknessesRequest())
	try {
		const sicknesses = await getAllSicknesses()
		dispatch(actions.dataActions.sicknesses.loadSicknessesSuccess(sicknesses))
	} catch (error: any) {
		toast.error(error.response.data.message)
		dispatch(actions.dataActions.sicknesses.loadSicknessesError())
	}
}

export const loadTwoMonthSicknesses = () => async (dispatch) => {
	dispatch(actions.dataActions.sicknesses.loadSicknessesRequest())
	try {
		const sicknesses = await getTwoMonthSicknesses()
		dispatch(actions.dataActions.sicknesses.loadSicknessesSuccess(sicknesses))
	} catch (error: any) {
		toast.error(error.response.data.message)
		dispatch(actions.dataActions.sicknesses.loadSicknessesError())
	}
}

export const loadEmployeeSicknessesFunc = (id: string) => async (dispatch) => {
	dispatch(actions.dataActions.sicknesses.loadEmployeeSicknessesRequest())
	try {
		const loadedSicknesses: Sickness[] = await getEmployeeSicknesses(id)
		return dispatch(
			actions.dataActions.sicknesses.loadEmployeeSicknessesSuccess(loadedSicknesses)
		)
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.sicknesses.loadEmployeeSicknessesError())
	}
}

export const loadTodaySicknesses = () => async (dispatch) => {
	dispatch(actions.dataActions.sicknesses.loadTodaySicknessesRequest())
	try {
		const sicknesses = await getTodaySicknesses()
		dispatch(actions.dataActions.sicknesses.loadTodaySicknessesSuccess(sicknesses))
	} catch (error: any) {
		toast.error(error.response.data.message)
		dispatch(actions.dataActions.sicknesses.loadTodaySicknessesError())
	}
}

export const createNewSickness = (creationData: SicknessCreationData) => async (dispatch) => {
	dispatch(actions.dataActions.sicknesses.createSicknessRequest())
	try {
		const newSickness = await createSickness(creationData)
		toast.error(t('reduxToastSuccess_sicknessCreated') as string)
		dispatch(actions.dataActions.sicknesses.createSicknessSuccess(newSickness))
	} catch (error: any) {
		toast.error(error.response.data.message)
		dispatch(actions.dataActions.sicknesses.createSicknessError())
	}
}

export const updateSicknessDate = (updateData: SicknessUpdateData) => async (dispatch) => {
	dispatch(actions.dataActions.sicknesses.updateSicknessRequest())
	try {
		const updatedSickness = await updateSickness(updateData)
		toast.success(t('reduxToastSuccess_sicknessStopped') as string)
		dispatch(actions.dataActions.sicknesses.updateSicknessSuccess(updatedSickness))
	} catch (error: any) {
		toast.error(error.response.data.message)
		dispatch(actions.dataActions.sicknesses.updateSicknessesError())
	}
}

export const deleteSicknesDocument = (id) => async (dispach) => {
	dispach(actions.dataActions.sicknesses.deleteSicknessRequest())
	try {
		const deletedSickness = await deleteSickness(id)
		toast.success(t('reduxToastSuccess_sicknessDeleted') as string)
		return dispach(actions.dataActions.sicknesses.deleteSicknessSuccess(deletedSickness))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispach(actions.dataActions.sicknesses.deleteSicknessError())
	}
}

//sickness file
export const uploadSicknessFileFunc = (sickness_id: string, file: File) => async (dispatch) => {
	dispatch(actions.dataActions.sicknesses.uploadSicknessFileRequest())
	try {
		const updatedSickness: Sickness = await uploadSicknessFile(sickness_id, file)
		toast.success(t('reduxToastSuccess_sicknessFileUploaded') as string)
		return dispatch(actions.dataActions.sicknesses.uploadSicknessFileSuccess(updatedSickness))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.sicknesses.uploadSicknessFileError())
	}
}

export const deleteSicknessFileFunc = (deleteInfo: SicknessFileDeleteDto) => async (dispatch) => {
	dispatch(actions.dataActions.sicknesses.deleteSicknessFileRequest())
	try {
		const updatedSickness: Sickness = await deleteSicknessFile(deleteInfo)
		toast.success(t('reduxToastSuccess_sicknessFileDeleted') as string)
		return dispatch(actions.dataActions.sicknesses.deleteSicknessFileSuccess(updatedSickness))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.sicknesses.deleteSicknessFileError())
	}
}
