import React, { lazy, Suspense, useMemo, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
	createBrowserRouter,
	Navigate,
	redirect,
	RouteObject,
	RouterProvider
} from 'react-router-dom'

import Loader from 'components/Loader'
import operations from 'redux/operations'
import selectors from 'redux/selectors'
import { RolesEnum } from 'types/roles'
import Dashboard from '../components/Layout/Dashboard'
import LoginErrorPage from '../pages/auth/LoginErrorPage'
import useSetPageTitle from '../hooks/useSetPageTitle'
import { RouteItem, routes } from './routes'

// Auth
const SignIn = lazy(() => import('../pages/auth/SignIn'))
const Page404 = lazy(() => import('../pages/auth/Page404'))

const useRouteCache = () => {
	const cache = React.useRef<Map<string, any>>(new Map())

	const getCachedData = useCallback((key: string) => cache.current.get(key), [])
	const setCachedData = useCallback((key: string, data: any) => cache.current.set(key, data), [])
	const clearCache = useCallback(() => cache.current.clear(), [])

	return { getCachedData, setCachedData, clearCache }
}

const RolesGuard: React.FC<{ routeItem: RouteItem; children: React.ReactNode }> = ({ children, routeItem }) => {
	const user = useSelector(selectors.getUserEmployee)

	const hasRequiredRole =
		!routeItem.requiredRoles || user.user_roles?.includes(RolesEnum.admin) ||
		routeItem.requiredRoles.some(role => user.user_roles?.includes(role))

	const isFounder = routeItem.requiredRoles?.includes(RolesEnum.founder) &&
		!user.user_roles.includes(RolesEnum.founder)

	if (!hasRequiredRole || isFounder) {
		return <Navigate to="/" />
	}

	return <>{children}</>
}

const RouteWrapper: React.FC<{ routeItem: RouteItem }> = ({ routeItem }) => {
	const { t, i18n } = useTranslation()
	const pageTitle = i18n.exists(routeItem.name) ? t(routeItem.name) : routeItem.name

	useSetPageTitle(pageTitle)

	return (
		<RolesGuard routeItem={routeItem}>
			<Suspense fallback={<Loader />}>
				{routeItem.component && (
					React.isValidElement(routeItem.component)
						? routeItem.component
						: <routeItem.component />
				)}
			</Suspense>
		</RolesGuard>
	)
}

const createRoutes = (items: RouteItem[]): RouteObject[] => {
	return items.map(item => ({
		path: item.path,
		element: <RouteWrapper routeItem={item} />,
		children: item.children ? createRoutes(item.children) : undefined,
		loader: item.loader,
	}))
}

export const useSignOut = () => {
	const dispatch = useDispatch()
	const { clearCache } = useRouteCache()

	return useCallback(async () => {
		const logoutSuccess = await dispatch(operations.userOperations.auth.logoutFunc())
		if (await logoutSuccess) {
			clearCache()
			// Force a full page reload and redirect to sign-in page
			window.location.href = '/sign_in'
		}
	}, [dispatch, clearCache])
}

const Router: React.FC = () => {
	const dispatch = useDispatch()
	const { getCachedData, setCachedData, clearCache } = useRouteCache()

	const fetchUser = useCallback(async () => {
		const cachedUser = getCachedData('user')
		if (cachedUser) return cachedUser

		const user = await dispatch(operations.userOperations.auth.fetchUserFunc())
		if (user) {
			setCachedData('user', user)
			await dispatch(operations.dataOperations.notifications.loadEmployeeNotificationsFunc())
		}
		return user
	}, [dispatch, getCachedData, setCachedData])

	const router = useMemo(() => createBrowserRouter([
		{
			path: '/',
			element: <Dashboard />,
			loader: async () => {
				const user = await fetchUser()
				if (!user) {
					clearCache()
					return redirect('/sign_in')
				}
				return user
			},
			errorElement: <Page404 />,
			children: createRoutes(routes)
		},
		{
			path: '/sign_in',
			element: <SignIn />,
			loader: async () => {
				const user = await fetchUser()
				return user ? redirect('/') : null
			}
		},
		{
			path: '/login_error',
			element: <LoginErrorPage />
		}
	]), [fetchUser, clearCache])

	return <RouterProvider router={router} fallbackElement={<Loader />} />
}

export default Router