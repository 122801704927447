import * as auth from './auth'
import * as checkin from './checkin'
import * as surveys from './surveys'

const userOperations = {
	auth,
	checkin,
	surveys
}

export default userOperations
