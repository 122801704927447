import {
	addTodo,
	loadAllTodolists,
	loadEmployeeTodolist,
	noTodos,
	updateTodoStatus
} from 'api/request'

import { toast } from 'react-toastify'
import actions from 'redux/actions'
//import { t } from 'i18next'
import Todolist, { AddTodoData, UpdateTodoStatusData } from 'types/todolist'

export const loadTodolistsFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.todolists.loadTodolistsRequest())
	try {
		const todolists: Todolist[] = await loadAllTodolists()

		return dispatch(actions.dataActions.todolists.loadTodolistsSuccess(todolists))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.todolists.loadTodolistsError())
	}
}

export const loadEmployeeTodolistFunc = (id: string) => async (dispatch) => {
	dispatch(actions.dataActions.todolists.getEmployeeTodolistRequest())
	try {
		const todolist: Todolist = await loadEmployeeTodolist(id)
		return dispatch(actions.dataActions.todolists.getEmployeeTodolistSuccess(todolist))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.todolists.getEmployeeTodolistError())
	}
}

export const addTodoFunc = (addTodoData: AddTodoData) => async (dispatch) => {
	dispatch(actions.dataActions.todolists.addTodoRequest())
	try {
		const todolist: Todolist = await addTodo(addTodoData)
		return dispatch(actions.dataActions.todolists.addTodoSuccess(todolist))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.todolists.addTodoError())
	}
}

export const noTodosFunc = (id: string) => async (dispatch) => {
	dispatch(actions.dataActions.todolists.noTodosRequest())
	try {
		const todolist: Todolist = await noTodos(id)
		//toast.success( )
		return dispatch(actions.dataActions.todolists.noTodosSuccess(todolist))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.todolists.noTodosError())
	}
}

export const updateTodoStatusFunc = (updatingData: UpdateTodoStatusData) => async (dispatch) => {
	dispatch(actions.dataActions.todolists.updateTodoStatusRequest())
	try {
		const todolist: Todolist = await updateTodoStatus(updatingData)
		return dispatch(actions.dataActions.todolists.updateTodoStatusSuccess(todolist))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.todolists.updateTodoStatusError())
	}
}
