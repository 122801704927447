import React from 'react';
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';

import Sidebar from '../Sidebar/Sidebar';
import Navbar from '../Navbar';
import Survey from 'pages/other/Survey';
import TimeTracking from 'pages/dashboards/HR/TimeTracking/TimeTracking';
import selectors from 'redux/selectors';

const Dashboard = () => {
	const user = useSelector(selectors.getUser);
	const isAuthenticated = useSelector(selectors.getIsAuthenticated);
	const todayEntered = useSelector(selectors.getTodayEntered);
	const workWithSystem = useSelector(selectors.getWorkWithSystem);
	const eventsDisabled = useSelector(selectors.getEventsDisabled);

	const wrapperStyle: React.CSSProperties = { position: 'relative', pointerEvents: eventsDisabled ? 'none' : 'auto' };
	const showTimeTracking =
		(isAuthenticated && !todayEntered && !workWithSystem) ||
		(isAuthenticated && !workWithSystem);
	const showSurvey = isAuthenticated && user.survey.needed && workWithSystem;

	return (
		<div className='wrapper' style={wrapperStyle}>
			{showTimeTracking && <TimeTracking />}
			{showSurvey && <Survey />}

			<Sidebar />
			<div className="main">
				<Navbar />
				<div className="content">
					<Container fluid className="p-0">
						<Outlet />
					</Container>
				</div>
			</div>
		</div>
	);
};

export default Dashboard;
