import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputFormGroup } from 'components/FormGroups';
import ModalView from 'components/ModalView';
import { FaPlus } from 'react-icons/fa'
import { Row, Col } from 'react-bootstrap';

import { RolesEnum } from '../../../../../types/roles';
import {
	useUpdateContractorClassMutation,
} from '../../../../../redux/slices/financeDictionaries'
import { ContractorClass } from '../../../../../types/finance/contractor'

interface UpdateContractorClassModalProps {
	contractorClass: ContractorClass;
	show: boolean;
	setShow: (state: boolean) => void
}

const UpdateContractorClassModal = (
	{ contractorClass, show = false, setShow }: UpdateContractorClassModalProps
) => {
	const { t } = useTranslation();

	const [enName, setEnName] = useState(contractorClass.enName || '');
	const [ukName, setUkName] = useState(contractorClass.ukName || '');

	const [enNameError, setEnNameError] = useState(false);
	const [ukNameError, setUkNameError] = useState(false);

	const [updateContractorClass] = useUpdateContractorClassMutation();

	const handleUpdateContractorType = async () => {
		let isValid = true;

		// Set error flags based on validation
		setUkNameError(!ukName);
		setEnNameError(!enName)

		if (!ukName || !enName) {
			isValid = false;
		}

		if (!isValid) return false;

		const changes = {
			enName,
			ukName
		};

		try {
			await updateContractorClass({id: contractorClass._id, changes: changes});
			clearForm();
			return true;
		} catch (error) {
			console.error("Error updating account class:", error);
			return false;
		}
	};

	const clearForm = () => {
		setUkName('');
		setEnName('');
	};

	return (
		<ModalView
			title={'UpdateContractorClass_title'}
			tooltipText={'UpdateContractorClassModal_tooltipText'}
			id="UpdateContractorClass"
			buttonIcon={FaPlus}
			onAccept={handleUpdateContractorType}
			clearFunction={clearForm}
			acceptButtonText={'update'}
			rejectButtonText={'cancelButton'}
			buttonText={''}
			show={show}
			setShow={setShow}
			noButton={true}
			requaredRoles={[RolesEnum.admin, RolesEnum.finances]}
		>
			<Row>
				<Col md={12}>
					<InputFormGroup
						name="name"
						label={t('ukName')}
						value={ukName}
						onChange={(e) => {
							setUkName(e.target.value);
							setUkNameError(false);
						}}
						error={ukNameError}
					/>
				</Col>
				<Col md={12}>
					<InputFormGroup
						name="name"
						label={t('enName')}
						value={enName}
						onChange={(e) => {
							setEnName(e.target.value);
							setEnNameError(false);
						}}
						error={enNameError}
					/>
				</Col>
			</Row>
		</ModalView>
	);
};

export default UpdateContractorClassModal;
