import { loadSystemSettings, updateSystemSettings } from 'api/request'

import { toast } from 'react-toastify'
import actions from 'redux/actions'
import { t } from 'i18next'
import { SystemSettings } from 'types/systemSettings'

export const loadSystemSettingsFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.systemSettings.loadSystemSettingsRequest())
	try {
		const systemSettings: SystemSettings = await loadSystemSettings()

		dispatch(actions.dataActions.systemSettings.loadSystemSettingsSuccess(systemSettings))
	} catch (error: any) {
		//const message = t('reduxToastError_loadFinanceCollection')
		toast.error(error.response.data.message)
		dispatch(actions.dataActions.systemSettings.loadSystemSettingsError())
	}
}

export const updateSystemSettingsFunc = (updatedData) => async (dispatch) => {
	dispatch(actions.dataActions.systemSettings.updateSystemSettingsRequest())
	try {
		const updatedSystemSettings: SystemSettings = await updateSystemSettings(updatedData)

		toast.success(t('reduxToastSuccess_systemSettingsUpdate') as string)
		dispatch(
			actions.dataActions.systemSettings.updateSystemSettingsSuccess(updatedSystemSettings)
		)
	} catch (error: any) {
		toast.error(error.response.data.message)
		dispatch(actions.dataActions.systemSettings.updateSystemSettingsError())
	}
}
