import { ShortEmployee } from './employee'
import { PositionsEnum } from './positions'

export enum SicknessTypeEnum {
	worksRemotely = 'Sicknesses_typeWorksRemotely',
	doesntWork = 'Sicknesses_typeDoesntWork',
	sickWork = 'Sicknesses_typeSickWork',
	sick = 'Sicknesses_typeSick'
}

export default interface Sickness {
	_id: string
	employee: ShortEmployee
	position: PositionsEnum
	type: SicknessTypeEnum
	start_date: Date
	end_date: Date | null
	days: number | null
	file_id?: string
}

export interface SicknessCreationData {
	employee: string
	position: PositionsEnum
	type: SicknessTypeEnum
	start_date: Date
	end_date: Date | null
	days: number | null
}

export interface SicknessUpdateData {
	_id: string
	end_date: Date
	days: number
}

export interface SicknessFileDeleteDto {
	sickness_id: string
	file_id: string
}
