import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

import UniversalTable from 'components/UniversalTable';

import { Account } from '../../../../../types/finance/account';
import {
	useArchiveAccountMutation,
	useDeleteAccountMutation, useLoadAccountsQuery, useSetDefaultAccountMutation,
	useUnarchiveAccountMutation
} from '../../../../../redux/slices/financeDictionaries'
import { Button, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap'
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'
import { FaArchive, FaEdit, FaTrashAlt } from 'react-icons/fa'
import { FaArrowRotateLeft } from 'react-icons/fa6'
import UpdateAccountModal from '../Modals/UpdateAccountModal'
import { RolesEnum } from '../../../../../types/roles'
import RoleWrapper from '../../../../../components/RoleWrapper'
import DeleteAccountModal from '../Modals/DeleteAccountModal'
import TableError from '../../../../../components/TableError'

const columns = [
	{
		dataField: 'organization',
		text: 'AccountsTable_Organization',
		sort: false,
		width: '15%',
		className: 'text-center',
	},
	{
		dataField: 'type',
		text: 'AccountsTable_Type',
		sort: false,
		width: '5%',
		className: 'text-center',
	},
	{
		dataField: 'currency',
		text: 'AccountsTable_Currency',
		sort: false,
		width: '5%',
		className: 'text-center',
	},
	{
		dataField: 'name',
		text: 'AccountsTable_Name',
		sort: false,
		width: '15%',
		className: 'text-center',
	},
	{
		dataField: 'beneficiaryName',
		text: 'AccountsTable_BeneficiaryName',
		sort: false,
		width: '10%',
		className: 'text-center',
	},
	{
		dataField: 'IBAN',
		text: 'AccountsTable_IBAN',
		sort: false,
		width: '10%',
		className: 'text-center',
	},
	{
		dataField: 'bankName',
		text: 'AccountsTable_BankName',
		sort: false,
		width: '15%',
		className: 'text-center',
	},
	{
		dataField: 'swiftCode',
		text: 'AccountsTable_SwiftCode',
		sort: false,
		width: '10%',
		className: 'text-center',
	},
	{
		dataField: 'actions',
		text: 'AccountsTable_Actions',
		sort: false,
		width: '10%',
		className: 'text-center',
	}
];

interface AccountRowProps {
	account: Account;
	handlers: any
}

function AccountRow({ account, handlers }: AccountRowProps) {
	const { t } = useTranslation();

	const [showUpdate, setShowUpdate] = useState(false)
	const [showDelete, setShowDelete] = useState(false)

	const [archiveAccount, unarchiveAccount, deleteAccount, setDefaultAccount] = handlers

	const renderTooltip = (content: string) => (props: any) => (
		<Tooltip id={`tooltip-${account._id}`} className={'custom-tooltip top'} {...props}>
			{content}
		</Tooltip>
	);

	return (
		<tr>
			<td className="text-center">{account.organization.name}</td>
			<td className="text-center">{t(account.type.name)}</td>
			<td className="text-center">{account.currency.name}</td>
			<td className="text-center">{account.name}</td>
			<td className="text-center">{account.beneficiaryName || '-'}</td>
			<td className="text-center">{account.IBAN || '-'}</td>
			<td className="text-center">{account.bankName || '-'}</td>
			<td className="text-center">{account.swiftCode || '-'}</td>
			<td className="text-center">
				<div className='d-flex align-middle justify-content-center'>
					{
						!account.isArchived && (
							<>
								<UpdateAccountModal
									key={account.name + Math.random()}
									account={account}
									show={showUpdate}
									setShow={setShowUpdate}
								/>
								<OverlayTrigger
									placement="top"
									overlay={renderTooltip(t('edit'))}
								>
									<Button
										variant="outline-primary"
										size="sm"
										className="d-flex align-items-center justify-content-center p-0 mr-2"
										style={{ width: "28px", height: "28px", borderRadius: "4px" }}
										onClick={(e) => {
											e.stopPropagation();
											setShowUpdate(true)
										}}
										aria-label={t('edit')}
									>
										<FaEdit size={18} />
									</Button>
								</OverlayTrigger>
								{
									account.isDefault ? (
										<Button
											variant="outline-success"
											size="sm"
											className="d-flex align-items-center justify-content-center p-0 mr-2"
											style={{ width: "28px", height: "28px", borderRadius: "4px" }}
											disabled={true}
											aria-label={t('set_default')}
										>
											<MdCheckBox size={28} />
										</Button>
									) : (
										<OverlayTrigger
											placement="top"
											overlay={renderTooltip(t('set_default'))}
										>
											<Button
												variant="outline-primary"
												size="sm"
												className="d-flex align-items-center justify-content-center p-0 mr-2"
												style={{ width: "28px", height: "28px", borderRadius: "4px" }}
												onClick={(e) => {
													e.stopPropagation();
													setDefaultAccount(account._id)
												}}
												aria-label={t('set_default')}
											>
												<MdCheckBoxOutlineBlank size={28} />
											</Button>
										</OverlayTrigger>
									)
								}
							</>
						)
					}
					{
						account.isArchived ? (
							<OverlayTrigger
								placement="top"
								overlay={renderTooltip(t('unarchive'))}
							>
								<Button
									variant="outline-success"
									size="sm"
									className="d-flex align-items-center justify-content-center p-0 mr-2"
									style={{ width: "28px", height: "28px", borderRadius: "4px" }}
									onClick={(e) => {
										e.stopPropagation();
										unarchiveAccount(account._id)
									}}
									aria-label={t('unarchive')}
								>
									<FaArrowRotateLeft size={18} />
								</Button>
							</OverlayTrigger>
						) : (
							<OverlayTrigger
								placement="top"
								overlay={renderTooltip(t('archive'))}
							>
								<Button
									variant="outline-danger"
									size="sm"
									className="d-flex align-items-center justify-content-center p-0"
									style={{ width: "28px", height: "28px", borderRadius: "4px" }}
									onClick={(e) => {
										e.stopPropagation();
										archiveAccount(account._id)
									}}
									aria-label={t('archive')}
								>
									<FaArchive size={18} />
								</Button>
							</OverlayTrigger>
						)
					}
					<RoleWrapper requared_role={[RolesEnum.admin, RolesEnum.finances]}>
						{
							account.isArchived && (
								<>
									<DeleteAccountModal
										show={showDelete}
										setShow={setShowDelete}
										onAccept={() => deleteAccount(account._id)}
									/>
									<OverlayTrigger
										placement="top"
										overlay={renderTooltip(t('delete'))}
									>
										<Button
											variant="outline-danger"
											size="sm"
											className="d-flex align-items-center justify-content-center p-0"
											style={{ width: "28px", height: "28px", borderRadius: "4px" }}
											onClick={(e) => {
												e.stopPropagation();
												setShowDelete(true)
											}}
											aria-label={t('delete')}
										>
											<FaTrashAlt size={18} />
										</Button>
									</OverlayTrigger>
								</>
							)
						}
					</RoleWrapper>
				</div>
			</td>
		</tr>
	);
}

function AccountsTable() {
	const [archiveAccount] = useArchiveAccountMutation()
	const [unarchiveAccount] = useUnarchiveAccountMutation()
	const [deleteAccount] = useDeleteAccountMutation()
	const [setDefaultAccount] = useSetDefaultAccountMutation()

	const { data, isLoading, isError } = useLoadAccountsQuery();

	if (isLoading) return <Spinner animation="grow" variant={'primary'} className="me-2" />;
	if (isError) return <TableError>Error loading data</TableError>;

	const unarchivedData = (data && data.filter(entry => !entry.isArchived)) || []
	const archivedData = (data && data.filter(entry => entry.isArchived)) || []

	const handlers = [archiveAccount, unarchiveAccount, deleteAccount, setDefaultAccount]

	return <>
		{
			unarchivedData && unarchivedData.length > 0 ?
				<UniversalTable
					name={'AccountsTable_tableName'}
					id={'accountsTable AccountsTable_tableName'}
					columns={columns}
					data={unarchivedData}
					row={(account, i) => <AccountRow handlers={handlers} key={i + 'accountRow'} account={account} />}
					scrollable
				/> :
				<TableError>No accounts data.</TableError>
		}
		{
			archivedData && archivedData.length > 0 ?
				<UniversalTable
					name={'AccountsTable_tableNameArchived'}
					id={'accountsTable AccountsTable_tableNameArchived'}
					columns={columns}
					data={archivedData}
					row={(account, i) => <AccountRow handlers={handlers} key={i + 'accountRow'} account={account} />}
					scrollable
				/> :
				<TableError>No accounts data.</TableError>
		}
	</>
}

export default AccountsTable;
