import React from 'react'
import { Badge } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { ProjectTypeEnum, ShortProject } from 'types/project'
import ProjectTypeBadge from './ProjectTypeBadge'

const ProjectNameBadge = (props: { project: ShortProject; offType?: boolean }) => {
	if (!props.project) return null

	return (
		<Link to={`/project/${props.project._id}`}>
			{props.project.name}
			{props.project.project_type !== ProjectTypeEnum.prototype && !props.offType && (
				<ProjectTypeBadge project={props.project} className="ml-1" />
			)}
			{props.project.iteration_number > 0 && (
				<Badge
					bg="primary"
					className="ml-1 badge-blue"
					style={{ transform: 'translate(0, -6%)', paddingBottom: '0.25em' }}
				>
					i{props.project.iteration_number}
				</Badge>
			)}
		</Link>
	)
}

export default ProjectNameBadge
