import { isRejectedWithValue, Middleware, MiddlewareAPI } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'

export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) =>
	(next) => (action) => {
		if (isRejectedWithValue(action)) {
			const isErrorWithMessage = (
				error: unknown
			): error is { data: { message: string } } =>
				typeof error === 'object' &&
				error !== null &&
				'data' in error &&
				typeof (error as any).data.message === 'string';

			const errorMessage = isErrorWithMessage(action.payload)
				? action.payload.data.message
				: action.error?.message || 'An unknown error occurred';

			toast.error(errorMessage);
		}

		return next(action);
	};