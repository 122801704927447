import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

const LoginErrorPage = () => {
	let navigate = useNavigate()
	const location = useLocation()
	const { t } = useTranslation()

	useEffect(() => {
		setTimeout(() => navigate('/sign_in'), 2500)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div
			className="text-center d-flex align-items-center justify-content-center"
			style={{ height: '100vh' }}
		>
			<div>
				<h1 className="display-1 font-weight-bold">{location.hash.slice(1)}</h1>
				<p className="h1">{t(`LoginErrorPage_${location.hash}`)}</p>
				{/* <p className="h2 font-weight-normal mt-3 mb-4">No such user in the system</p> */}
			</div>
		</div>
	)
}
/* Такого користувача не знайдено Такой пользователь не найден*/

export default LoginErrorPage
