import { createApi } from '@reduxjs/toolkit/query/react'
import { axiosBaseQuery } from '../../api/axios.queryBuilder'
import Publisher, {
	PublisherCreationData,
	PublishersEmployeesUpdatingData,
	PublisherWithNotifications
} from '../../types/publisher'


export const publishersApi = createApi({
	reducerPath: 'publishersApi',
	baseQuery: axiosBaseQuery({
		baseUrl: 'publishers'
	}),
	tagTypes: [
		'PublishersList',
		'Publisher',
		'ShortPublishers',
	],
	endpoints: (builder) => ({
		getPublisher: builder.query<Publisher, string>({
			query: (id) => ({
				url: `/${id}`,
				method: 'GET',
			}),
			providesTags: ['Publisher'],
		}),
		getPublishers: builder.query<Publisher[], void>({
			query: () => ({
				url: '/all',
				method: 'GET',
			}),
			providesTags: ['PublishersList'],
		}),
		getShortPublishers: builder.query<Publisher[], void>({
			query: () => ({
				url: '/all_short',
				method: 'GET',
			}),
			providesTags: ['ShortPublishers'],
		}),
		createPublisher: builder.mutation<Publisher, PublisherCreationData>({
			query: (creationData) => ({
				url: '/create',
				method: 'POST',
				data: creationData,
			}),
			invalidatesTags: ['PublishersList'],
		}),
		updatePublishersEmployees: builder.mutation<PublisherWithNotifications, PublishersEmployeesUpdatingData>({
			query: (updatingData) => ({
				url: '/update_employees',
				method: 'PUT',
				data: updatingData,
			}),
			invalidatesTags: ['PublishersList', 'Publisher'],
		}),
		archivePublisher: builder.mutation<Publisher, string>({
			query: (publisher_id) => ({
				url: `/${publisher_id}/archive`,
				method: 'PUT',
			}),
			invalidatesTags: ['PublishersList', 'Publisher'],
		}),
	}),
});

export const {
	useGetPublisherQuery,
	useGetPublishersQuery,
	useGetShortPublishersQuery,
	useCreatePublisherMutation,
	useUpdatePublishersEmployeesMutation,
	useArchivePublisherMutation,
} = publishersApi;