import React, { useState, useMemo, useCallback, useEffect } from 'react'
import { Table, Card, Button, Spinner, Collapse, Pagination } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'
import { FaChevronRight } from 'react-icons/fa'
import Select from 'react-select'
import Loader from 'components/Loader'
import ProjectsByMonthsSelector, { Tables } from './ProjectsByMonthsSelector'
import LoadAchivedButton, { ArchivedEntitiesEnum } from './LoadArchivedButton'
import { DataFieldsToSortEnum, SortDirectionEnum } from 'types/enums'
import {
	sortByCreationDate,
	sortByDate,
	sortByCpi,
	sortByRetention,
	sortByMarginality,
	sortByBalanceType,
	sortByReleaseDate,
	sortByPromotion,
	sortByOperationDate,
	sortByDocumentDate,
	sortByOperationType,
	sortBySum
} from 'utils/sort'

interface ColumnsRowProps {
	column: {
		dataField: string;
		text: string;
		sort: boolean;
		className?: string;
		width?: string;
	};
	onSort: (dataField: string, sortDirection: SortDirectionEnum) => void;
	id: number;
	sortedField: string | null;
	sortDirection: SortDirectionEnum | null;
}

interface UniversalTableProps {
	data: any[] | null
	columns: any[]
	row: (data: any, i: number) => any
	id: string
	name: string
	defaultOpen?: boolean
	hideText?: string | undefined
	footer?: React.ReactNode
	fixedItemsCountNumber?: number
	scrollable?: boolean
	withMonthSelect?: Tables
	loadButton?: ArchivedEntitiesEnum
	loading?: boolean

	withPagination?: boolean
	externalCurrentPage?: number
	onCurrentPageChange?: any
	externalItemsPerPage?: number
	onItemsPerPageChange?: any
	totalItems?: number

	persistOpen?: boolean
}

interface PaginationComponentProps {
	currentPage: number;
	totalPages: number;
	itemsPerPage: number;
	onPageChange: (page: number) => void;
	onItemsPerPageChange: (itemsPerPage: number) => void;
	dataLength: number;
}

const scrolable: React.CSSProperties = {
	overflow: 'auto',
	overflowY: 'hidden'
}

const itemsPerPageOptions = [
	{ value: 5, label: '5' },
	{ value: 10, label: '10' },
	{ value: 20, label: '20' },
	{ value: 50, label: '50' },
]

// Utility function to get and set local storage state
const getPersistedState = (id) => {
	const savedState = localStorage.getItem(`table-${id}`);
	return savedState ? JSON.parse(savedState) : null;
};

const setPersistedState = (id, state) => {
	localStorage.setItem(`table-${id}`, JSON.stringify(state));
};

function ColumnsRow({ column, onSort, id, sortedField, sortDirection }: ColumnsRowProps): JSX.Element {
	const { t, i18n } = useTranslation()

	const handleSort = useCallback(() => {
		if (!column.sort) return

		const newDirection =
			sortedField !== column.dataField ? SortDirectionEnum.asc :
				sortDirection === SortDirectionEnum.asc ? SortDirectionEnum.desc :
					SortDirectionEnum.asc

		onSort(column.dataField, newDirection)
	}, [column, sortedField, sortDirection, onSort])

	return (
		<th
			className={`${column.className || ''} ${
				column.sort ? 'sortable' : 'unsortable'
			} ${
				column.dataField === 'visitDate'
					? 'border-left border-right border-top-0'
					: 'border-top-0'
			}`}
			aria-label={
				column.sort
					? sortDirection
						? sortDirection === SortDirectionEnum.asc
							? 'asc'
							: 'desc'
						: ''
					: 'unsortable'
			}
			id={id.toString()}
			style={{ width: column.width }}
			onClick={handleSort}
		>
			{column.dataField !== 'noTranslate' && column.dataField !== 'visitDate' &&
				(i18n.exists(column.text) ? t(column.text) : column.text)}
			{column.dataField === 'noTranslate' && column.text}
			{column.dataField === 'visitDate' && (
				<Moment format="dd DD" locale={t('locales')} date={column.text} />
			)}
			{column.sort && <span className={column.sort ? 'arrows' : ''}></span>}
		</th>
	)
}

function PaginationComponent({
								 currentPage,
								 totalPages,
								 itemsPerPage,
								 onPageChange,
								 onItemsPerPageChange,
								 dataLength
							 }: PaginationComponentProps) {
	const displayPages = () => {
		const pages: JSX.Element[] = []
		const addPageItem = (i: number) => (
			<Pagination.Item key={i} active={i === currentPage} onClick={() => onPageChange(i)}>
				{i}
			</Pagination.Item>
		)

		if (totalPages > 3) pages.push(<Pagination.First key="first" onClick={() => onPageChange(1)} disabled={currentPage === 1} />)
		if (totalPages > 1) pages.push(<Pagination.Prev key="prev" onClick={() => onPageChange(currentPage - 1)} disabled={currentPage === 1} />)

		if (totalPages <= 3) {
			for (let i = 1; i <= totalPages; i++) pages.push(addPageItem(i))
		} else {
			if (currentPage > 2) pages.push(<Pagination.Ellipsis key="start-ellipsis" disabled />)
			const startPage = Math.max(1, currentPage - 1)
			const endPage = Math.min(totalPages, currentPage + 1)
			for (let i = startPage; i <= endPage; i++) pages.push(addPageItem(i))
			if (currentPage < totalPages - 1) pages.push(<Pagination.Ellipsis key="end-ellipsis" disabled />)
		}

		if (totalPages > 1) pages.push(<Pagination.Next key="next" onClick={() => onPageChange(currentPage + 1)} disabled={currentPage === totalPages} />)
		if (totalPages > 3) pages.push(<Pagination.Last key="last" onClick={() => onPageChange(totalPages)} disabled={currentPage === totalPages} />)

		return pages
	}

	const filteredOptions = itemsPerPageOptions.filter(option => option.value <= dataLength)

	return (
		<div className="d-flex justify-content-center align-items-center">
			{filteredOptions.length > 1 && (
				<div className="mx-3" style={{ width: 80 }}>
					<Select
						options={filteredOptions}
						value={itemsPerPageOptions.find(option => option.value === itemsPerPage)}
						onChange={(selectedOption: any) => onItemsPerPageChange(selectedOption.value)}
						isSearchable={false}
						placeholder="Items"
						menuPlacement="top"
						styles={{
							control: (provided) => ({
								...provided,
								minHeight: '38px',
								height: '38px'
							})
						}}
					/>
				</div>
			)}
			{totalPages > 1 && (
				<Pagination size='lg' className="mb-0 justify-content-center align-items-center">
					{displayPages()}
				</Pagination>
			)}
		</div>
	)
}

function UniversalTable({
							data,
							columns,
							row,
							id,
							name,
							defaultOpen = true,
							hideText,
							fixedItemsCountNumber,
							scrollable,
							withMonthSelect,
							loadButton,
							loading,
							withPagination = false,
							externalCurrentPage,
							onCurrentPageChange,
							externalItemsPerPage,
							onItemsPerPageChange,
							totalItems,
							persistOpen = false
						}: UniversalTableProps) {
	const { t } = useTranslation()

	const [sortedField, setSortedField] = useState<string | null>(null)
	const [sortDirection, setSortDirection] = useState<SortDirectionEnum | null>(null)

	const [isOpen, setIsOpen] = useState(() => {
		if (persistOpen) {
			const persistedState = getPersistedState(id);
			return persistedState !== null ? persistedState : defaultOpen;
		}
		return defaultOpen;
	});

	const [internalCurrentPage, setInternalCurrentPage] = useState<number>(1)
	const currentPage = externalCurrentPage ?? internalCurrentPage
	const setCurrentPage = onCurrentPageChange ?? setInternalCurrentPage

	const [internalItemsPerPage, setInternalItemsPerPage] = useState<number>(10)
	const itemsPerPage = externalItemsPerPage ?? internalItemsPerPage
	const setItemsPerPage = onItemsPerPageChange ?? setInternalItemsPerPage

	// Toggle function
	const toggleFolding = () => {
		const newState = !isOpen;
		setIsOpen(newState);

		// Save state to local storage if persistOpen is enabled
		if (persistOpen) {
			setPersistedState(id, newState);
		}
	};

	// If persistOpen is enabled, apply the persisted state on component mount
	useEffect(() => {
		if (persistOpen) {
			const persistedState = getPersistedState(id);
			if (persistedState !== null) {
				setIsOpen(persistedState);
			}
		}
	}, [id, persistOpen]);

	const sortHandler = useCallback((dataField: string, newDirection: SortDirectionEnum) => {
		setSortedField(dataField)
		setSortDirection(newDirection)
		setCurrentPage(1)
	}, [setCurrentPage])

	const applySort = useCallback((dataToSort: any[] | null) => {
		if (!sortedField || !sortDirection) return dataToSort

		switch (sortedField) {
			case DataFieldsToSortEnum.releaseDate:
				return sortByReleaseDate(dataToSort, sortDirection)
			case DataFieldsToSortEnum.date:
				return sortByDate(dataToSort, sortDirection)
			case DataFieldsToSortEnum.cpi:
				return sortByCpi(dataToSort, sortDirection)
			case DataFieldsToSortEnum.retention:
				return sortByRetention(dataToSort, sortDirection)
			case DataFieldsToSortEnum.balance:
				return sortByBalanceType(dataToSort, sortDirection, id)
			case DataFieldsToSortEnum.marginality:
				return sortByMarginality(dataToSort, sortDirection)
			case DataFieldsToSortEnum.promotion:
				return sortByPromotion(dataToSort, sortDirection)
			case DataFieldsToSortEnum.creationDate:
				return sortByCreationDate(dataToSort, sortDirection)
			case DataFieldsToSortEnum.documentDate:
				return sortByDocumentDate(dataToSort, sortDirection)
			case DataFieldsToSortEnum.operationDate:
				return sortByOperationDate(dataToSort, sortDirection)
			case DataFieldsToSortEnum.operationType:
				return sortByOperationType(dataToSort, sortDirection)
			case DataFieldsToSortEnum.sum:
				return sortBySum(dataToSort, sortDirection)
			default:
				return dataToSort
		}
	}, [sortedField, sortDirection, id])

	const applyPagination = useCallback((dataToPaginate: any[]) => {
		if (!withPagination || externalCurrentPage || externalItemsPerPage) return dataToPaginate
		const startIndex = (currentPage - 1) * itemsPerPage
		return dataToPaginate.slice(startIndex, startIndex + itemsPerPage)
	}, [withPagination, externalCurrentPage, externalItemsPerPage, currentPage, itemsPerPage])

	const processedData = useMemo(() => {
		let result = data || []
		result = applySort(result)            // Step 2: Sort
		return applyPagination(result)        // Step 3: Paginate
	}, [data, applySort, applyPagination])

	const totalPages = useMemo(() => {
		const totalItemsCount = totalItems !== undefined
			? totalItems
			: applySort(data).length;

		return Math.max(1, Math.ceil(totalItemsCount / itemsPerPage));
	}, [data, applySort, itemsPerPage, totalItems]);

	useEffect(() => {
		if (currentPage > totalPages) setCurrentPage(1)
	}, [currentPage, setCurrentPage, totalPages])

	const handleItemsPerPageChange = useCallback((newItemsPerPage) => {
		setItemsPerPage(newItemsPerPage)
		setCurrentPage(1)
	}, [setCurrentPage, setItemsPerPage])

	if (!data) {
		return (
			<Card>
				<Loader />
			</Card>
		)
	}

	return (
		<Card style={scrollable ? scrolable : {}}>
			<Card.Header className="d-flex justify-content-between">
				<Card.Title as="h4" className="mb-0" id={`collapse${id}`}>
					<Button
						variant="link"
						onClick={toggleFolding}
						id={'collapseOne' + id}
						aria-controls={`collapse${id}`}
						aria-expanded={isOpen}
						className="p-0 mr-2 align-content-center justify-content-center"
					>
						<FaChevronRight size={10} style={{ transform: isOpen ? 'rotate(90deg)' : 'none' }} className='mr-2' />
						{t(name)}
					</Button>
				</Card.Title>

				<div className="d-flex align-items-center">
					{loading && (
						<Spinner animation="grow" variant="primary" className="me-2" />
					)}

					{withMonthSelect && (
						<ProjectsByMonthsSelector table={withMonthSelect} />
					)}

					{loadButton && !data.length && (
						<LoadAchivedButton entities={loadButton} />
					)}

					{!(loadButton && !data.length) && (
						<Button variant="primary" size="sm" className="btn-pill ml-2">
							{/*???*/}
							{
								externalCurrentPage && totalItems ?
									(fixedItemsCountNumber ? totalItems - fixedItemsCountNumber : totalItems) :
									(fixedItemsCountNumber ? data.length - fixedItemsCountNumber : data.length)
							}
						</Button>
					)}
				</div>
			</Card.Header>

			<Collapse in={isOpen}>
				<div>
					{hideText ? (
						<h5 className="text-center text-muted pb-3">{t(hideText)}</h5>
					) : (
						<Table striped>
							<thead>
								<tr>
									{columns.map((column: any, index: number) => (
										<ColumnsRow
											key={`columnsrow${index}`}
											id={index}
											column={column}
											onSort={sortHandler}
											sortedField={sortedField}
											sortDirection={sortDirection}
										/>
									))}
								</tr>
							</thead>
							<tbody className="m-5 p-5">
								{processedData.map((data: any, idx) => row(data, idx))}
							</tbody>
						</Table>
					)}
					{withPagination && (
						<Card.Footer>
							<PaginationComponent
								currentPage={currentPage}
								totalPages={totalPages}
								itemsPerPage={itemsPerPage}
								onPageChange={setCurrentPage}
								onItemsPerPageChange={handleItemsPerPageChange}
								dataLength={totalItems ?? applySort(data).length}
							/>
						</Card.Footer>
					)}
				</div>
			</Collapse>
		</Card>
	)
}

export default UniversalTable