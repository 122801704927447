import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

import UniversalTable from 'components/UniversalTable';

import {
	useArchiveContractsMutation,
	useDeleteContractsMutation,
	useLoadContractsQuery,
	useUnarchiveContractsMutation
} from '../../../../../redux/slices/financeDictionaries'
import { Button, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap'
import { FaArchive, FaEdit, FaTrashAlt } from 'react-icons/fa'
import { FaArrowRotateLeft } from 'react-icons/fa6'
import UpdateContractModal from '../Modals/UpdateContractModal'
import { RolesEnum } from '../../../../../types/roles'
import RoleWrapper from '../../../../../components/RoleWrapper'
import DeleteContractModal from '../Modals/DeleteContractModal'
import { Contract } from '../../../../../types/finance/contract'
import TableError from '../../../../../components/TableError'
import Moment from 'react-moment'

const columns = [
	{
		dataField: 'order_number',
		text: '№',
		sort: false,
		width: '5%',
		className: 'text-center'
	},
	{
		dataField: 'organization',
		text: 'ContractsTable_organization',
		sort: false,
		width: '20%',
		className: 'text-center',
	},
	{
		dataField: 'contractor',
		text: 'ContractsTable_contractor',
		sort: false,
		width: '10%',
		className: 'text-center',
	},
	{
		dataField: 'type',
		text: 'ContractsTable_Type',
		sort: false,
		width: '10%',
		className: 'text-center',
	},
	{
		dataField: 'name',
		text: 'ContractsTable_name',
		sort: false,
		width: '15%',
		className: 'text-center',
	},
	{
		dataField: 'creationDate',
		text: 'ContractsTable_creationDate',
		sort: true,
		width: '15%',
		className: 'text-center',
	},
	{
		dataField: 'conditions',
		text: 'ContractsTable_conditions',
		sort: false,
		width: '10%',
		className: 'text-center',
	},
	{
		dataField: 'postponement',
		text: 'ContractsTable_postponement',
		sort: false,
		width: '10%',
		className: 'text-center',
	},
	{
		dataField: 'actions',
		text: 'actions',
		sort: false,
		width: '5%',
		className: 'text-center',
	}
];

interface ContractRowProps {
	contract: Contract;
	handlers: any;
	index: number;
}

function ContractRow({ contract, handlers, index }: ContractRowProps) {
	const { t, i18n } = useTranslation();

	const [showUpdate, setShowUpdate] = useState(false)
	const [showDelete, setShowDelete] = useState(false)

	const [archiveContract, unarchiveContract, deleteContract] = handlers

	const renderTooltip = (content: string) => (props: any) => (
		<Tooltip id={`tooltip-${contract._id}`} className={'custom-tooltip top'} {...props}>
			{content}
		</Tooltip>
	);

	return (
		<tr>
			<td className="text-center">{index + 1}</td>
			<td className="text-center">{contract.organization.name}</td>
			<td className="text-center">{contract.contractor.name}</td>
			<td className="text-center">{(i18n.language === 'uk' ? contract.type.ukName : contract.type.enName)}</td>
			<td className="text-center">{contract.name}</td>
			<td className="text-center">
				<Moment format="DD.MM.YYYY" locale={t('locales')} date={contract.creationDate} />
			</td>
			<td className="text-center">{t(contract.conditions || '-')}</td>
			<td className="text-center">{contract.postponement}</td>
			<td className="text-center">
				<div className='d-flex align-middle justify-content-center'>
					{
						!contract.isArchived && (
							<>
								<UpdateContractModal
									key={contract.name + Math.random()}
									contract={contract}
									show={showUpdate}
									setShow={setShowUpdate}
								/>
								<OverlayTrigger
									placement="top"
									overlay={renderTooltip(t('edit'))}
								>
									<Button
										variant="outline-primary"
										size="sm"
										className="d-flex align-items-center justify-content-center p-0 mr-2"
										style={{ width: '28px', height: '28px', borderRadius: '4px' }}
										onClick={(e) => {
											e.stopPropagation()
											setShowUpdate(true)
										}}
										aria-label={t('edit')}
									>
										<FaEdit size={18} />
									</Button>
								</OverlayTrigger>
							</>
						)
					}
					{
						contract.isArchived ? (
							<OverlayTrigger
								placement="top"
								overlay={renderTooltip(t('unarchive'))}
							>
								<Button
									variant="outline-success"
									size="sm"
									className="d-flex align-items-center justify-content-center p-0 mr-2"
									style={{ width: '28px', height: '28px', borderRadius: '4px' }}
									onClick={(e) => {
										e.stopPropagation()
										unarchiveContract(contract._id)
									}}
									aria-label={t('unarchive')}
								>
									<FaArrowRotateLeft size={18} />
								</Button>
							</OverlayTrigger>
						) : (
							<OverlayTrigger
								placement="top"
								overlay={renderTooltip(t('archive'))}
							>
								<Button
									variant="outline-danger"
									size="sm"
									className="d-flex align-items-center justify-content-center p-0"
									style={{ width: '28px', height: '28px', borderRadius: '4px' }}
									onClick={(e) => {
										e.stopPropagation()
										archiveContract(contract._id)
									}}
									aria-label={t('archive')}
								>
									<FaArchive size={18} />
								</Button>
							</OverlayTrigger>
						)
					}
					<RoleWrapper requared_role={[RolesEnum.admin, RolesEnum.finances]}>
						{
							contract.isArchived && (
								<>
									<DeleteContractModal
										show={showDelete}
										setShow={setShowDelete}
										onAccept={() => deleteContract(contract._id)}
									/>
									<OverlayTrigger
										placement="top"
										overlay={renderTooltip(t('delete'))}
									>
										<Button
											variant="outline-danger"
											size="sm"
											className="d-flex align-items-center justify-content-center p-0"
											style={{ width: '28px', height: '28px', borderRadius: '4px' }}
											onClick={(e) => {
												e.stopPropagation()
												setShowDelete(true)
											}}
											aria-label={t('delete')}
										>
											<FaTrashAlt size={18} />
										</Button>
									</OverlayTrigger>
								</>
							)
						}
					</RoleWrapper>
				</div>
			</td>
		</tr>
	);
}

function ContractsTable() {
	const [archiveContract] = useArchiveContractsMutation()
	const [unarchiveContract] = useUnarchiveContractsMutation()
	const [deleteContract] = useDeleteContractsMutation()

	const { data: contractData, isLoading, isError } = useLoadContractsQuery();

	if (isLoading) return <Spinner animation="grow" variant={'primary'} className="me-2" />;
	if (isError) return <TableError>Error loading data</TableError>;

	const unarchivedContractData = (contractData && contractData.filter(entry => !entry.isArchived)) || []
	const archivedContractData = (contractData && contractData.filter(entry => entry.isArchived)) || []

	const handlers = [archiveContract, unarchiveContract, deleteContract]

	const sortedData = unarchivedContractData.sort(
		(a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime()
	)

	return <>
		{
			unarchivedContractData && unarchivedContractData.length > 0 ?
				<UniversalTable
					name={'ContractsTable_tableName'}
					id={'contractsTable ContractsTable_tableName'}
					columns={columns}
					data={sortedData}
					row={(contract, i) => <ContractRow handlers={handlers} key={i + 'contractRow'} contract={contract} index={i} />}
					scrollable
					withPagination
				/> :
				<TableError>No contracts data.</TableError>
		}
		{
			archivedContractData && archivedContractData.length > 0 ?
				<UniversalTable
					name={'ContractsTable_tableNameArchived'}
					id={'contractsTable ContractsTable_tableNameArchived'}
					columns={columns}
					data={archivedContractData}
					row={(contract, i) => <ContractRow handlers={handlers} key={i + 'contractRow'} contract={contract} index={i} />}
					scrollable
					withPagination
				/> : null
		}
	</>
}

export default ContractsTable;
