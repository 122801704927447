import { createAction } from '@reduxjs/toolkit'
import { Party } from 'types/party'
import * as types from '../../constants'

export const loadPartiesRequest = createAction(types.LOAD_PARTIES_REQUEST)
export const loadPartiesSuccess = createAction<Party[]>(types.LOAD_PARTIES_SUCCESS)
export const loadPartiesError = createAction(types.LOAD_PARTIES_ERROR)

export const loadMemberPartiesRequest = createAction(types.LOAD_MEMBER_PARTIES_REQUEST)
export const loadMemberPartiesSuccess = createAction<Party[]>(types.LOAD_MEMBER_PARTIES_SUCCESS)
export const loadMemberPartiesError = createAction(types.LOAD_MEMBER_PARTIES_ERROR)

export const createPartyRequest = createAction(types.CREATE_PARTY_REQUEST)
export const createPartySuccess = createAction<Party>(types.CREATE_PARTY_SUCCESS)
export const createPartyError = createAction(types.CREATE_PARTY_ERROR)

export const confirmPartyRequest = createAction(types.CONFIRM_PARTY_REQUEST)
export const confirmPartySuccess = createAction<Party>(types.CONFIRM_PARTY_SUCCESS)
export const confirmPartyError = createAction(types.CONFIRM_PARTY_ERROR)

export const donePartyRequest = createAction(types.DONE_PARTY_REQUEST)
export const donePartySuccess = createAction<Party>(types.DONE_PARTY_SUCCESS)
export const donePartyError = createAction(types.DONE_PARTY_ERROR)

export const updatePartyRequest = createAction(types.UPDATE_PARTY_REQUEST)
export const updatePartySuccess = createAction<Party>(types.UPDATE_PARTY_SUCCESS)
export const updatePartyError = createAction(types.UPDATE_PARTY_ERROR)

export const sendInvitationAnswerRequest = createAction(types.SEND_INVITATION_ANSWER_REQUEST)
export const sendInvitationAnswerSuccess = createAction<Party>(types.SEND_INVITATION_ANSWER_SUCCESS)
export const sendInvitationAnswerError = createAction(types.SEND_INVITATION_ANSWER_ERROR)

export const deletePartyRequest = createAction(types.DELETE_PARTY_REQUEST)
export const deletePartySuccess = createAction<Party>(types.DELETE_PARTY_SUCCESS)
export const deletePartyError = createAction(types.DELETE_PARTY_ERROR)
