import {
	archivePublisher,
	createPublisher,
	getPublisher,
	getPublishers,
	getShortPublishers,
	updatePublishersEmployees
} from 'api/request'

import { toast } from 'react-toastify'
import actions from 'redux/actions'
import { t } from 'i18next'
import Publisher, {
	PublisherCreationData,
	PublisherWithNotifications,
	PublishersEmployeesUpdatingData
} from 'types/publisher'

export const loadPublishersFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.publishers.loadPublishersRequest())
	try {
		const loadedPublishers: Publisher[] = await getPublishers()
		return dispatch(actions.dataActions.publishers.loadPublishersSuccess(loadedPublishers))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.publishers.loadPublishersError())
	}
}

export const getPublisherFunc = (id: string) => async (dispatch) => {
	dispatch(actions.dataActions.publishers.getPublisherRequest())
	try {
		const loadedPublisher: Publisher = await getPublisher(id)
		return dispatch(actions.dataActions.publishers.getPublisherSuccess(loadedPublisher))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.publishers.getPublisherError())
	}
}

export const loadShortPublishersFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.publishers.loadShortPublishersRequest())
	try {
		const loadedPublishers: Publisher[] = await getShortPublishers()
		return dispatch(actions.dataActions.publishers.loadShortPublishersSuccess(loadedPublishers))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.publishers.loadShortPublishersError())
	}
}

export const addPublisherFunc = (creationData: PublisherCreationData) => async (dispatch) => {
	dispatch(actions.dataActions.publishers.addPublisherRequest())
	try {
		const addedPublisher: Publisher = await createPublisher(creationData)
		toast.success(t('reduxToastSuccess_publisherCreated', { name: addedPublisher.name }) as string)
		return dispatch(actions.dataActions.publishers.addPublisherSuccess(addedPublisher))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.publishers.addPublisherError())
	}
}

export const updatePublishersEmployeesFunc =
	(updatingData: PublishersEmployeesUpdatingData) => async (dispatch) => {
		dispatch(actions.dataActions.publishers.updatePublishersEmployeesRequest())
		try {
			const updatedPublisher: PublisherWithNotifications = await updatePublishersEmployees(
				updatingData
			)

			toast.success(t('reduxToastSuccess_publisherEmployeesUpdated') as string)

			if (updatedPublisher.notifications.length > 0) {
				toast.warning(t('reduxToastWarning_publisherEmployeesUpdatedWithConflicts') as string)
			}

			return dispatch(
				actions.dataActions.publishers.updatePublishersEmployeesSuccess(updatedPublisher)
			)
		} catch (error: any) {
			toast.error(error.response.data.message)
			return dispatch(actions.dataActions.publishers.updatePublishersEmployeesError())
		}
	}

export const archivePublisherFunc = (publisher_id) => async (dispatch) => {
	dispatch(actions.dataActions.publishers.archivePublisherRequest())
	try {
		const archivedPublisher: Publisher = await archivePublisher(publisher_id)

		toast.success(t('reduxToastSuccess_publisherArchived') as string)

		return dispatch(actions.dataActions.publishers.archivePublisherSuccess(archivedPublisher))
	} catch (error: any) {
		toast.error(error)
		return dispatch(actions.dataActions.publishers.archivePublisherError())
	}
}
