import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaUserCircle } from 'react-icons/fa';
import type Employee from '../../types/employee';
import useTransliteration from '../../hooks/useTransliteration'

type UserProfileProps = {
  employee: Employee;
};

export const UserProfile: React.FC<UserProfileProps> = React.memo(({ employee }) => {
  const { t } = useTranslation();
  const { transliterate } = useTransliteration();

  const transliteratedName = useMemo(() => {
    return transliterate(`${employee.first_name} ${employee.last_name}`)
  }, [employee.first_name, employee.last_name, transliterate]);

  return (
    <div className="sidebar-bottom d-none d-lg-block">
      <div className="media">
        <FaUserCircle className="h1 text-light rounded-circle mr-3" />
        <div className="media-body">
          <div>
            <a href="/" style={{ textDecoration: 'none' }}>
              <h5 className="mb-1">{transliteratedName}</h5>
              <h5 className="text-muted m-0">{t('sideMenu_profile')}</h5>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
});

