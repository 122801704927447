import { createAction } from '@reduxjs/toolkit'
import Todolist from 'types/todolist'
import * as types from '../../constants'

export const loadTodolistsRequest = createAction(types.LOAD_TODOLISTS_REQUEST)
export const loadTodolistsSuccess = createAction<Todolist[]>(types.LOAD_TODOLISTS_SUCCESS)
export const loadTodolistsError = createAction(types.LOAD_TODOLISTS_ERROR)

export const getEmployeeTodolistRequest = createAction(types.GET_EMPLOYEE_TODOLIST_REQUEST)
export const getEmployeeTodolistSuccess = createAction<Todolist>(
	types.GET_EMPLOYEE_TODOLIST_SUCCESS
)
export const getEmployeeTodolistError = createAction(types.GET_EMPLOYEE_TODOLIST_ERROR)

export const addTodoRequest = createAction(types.ADD_TODO_REQUEST)
export const addTodoSuccess = createAction<Todolist>(types.ADD_TODO_SUCCESS)
export const addTodoError = createAction(types.ADD_TODO_ERROR)

export const noTodosRequest = createAction(types.NO_TODOS_REQUEST)
export const noTodosSuccess = createAction<Todolist>(types.NO_TODOS_SUCCESS)
export const noTodosError = createAction(types.NO_TODOS_ERROR)

export const updateTodoStatusRequest = createAction(types.UPDATE_TODO_STATUS_REQUEST)
export const updateTodoStatusSuccess = createAction<Todolist>(types.UPDATE_TODO_STATUS_SUCCESS)
export const updateTodoStatusError = createAction(types.UPDATE_TODO_STATUS_ERROR)
