import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { axiosBaseQuery } from '../../api/axios.queryBuilder'

import Project, {
	PauseProjectData,
	ProjectCount, ProjectCreationData,
	ProjectEmployees,
	ProjectResultData, ProjectUpdatingData,
	ReleasedProject, ShortProject,
	TechSetupData, UpdatedProjectSummData
} from '../../types/project'

export const projectsApi = createApi({
	reducerPath: 'projectsApi',
	baseQuery: axiosBaseQuery({
		baseUrl: `projects`
	}),
	tagTypes: [
		'ProjectsList',
		'Project',
		'MainProjects',
		'AllProjects',
		'GanttChartProjects',
		'ShortProjects',
		'PublisherProjects',
		'EmployeeProjects',
		'TestingProjects',
		'TestedProjects',
		'DevelopersProjectCount',
		'ArtistsProjectCount',
		'ProjectsEmployees',
	],
	endpoints: (builder) => ({
		loadProjectsList: builder.query<{ projects: ShortProject[]; total: number }, { page: number; limit: number }>({
			query: ({ page, limit }) => ({
				url: `/projects_list?page=${page}&limit=${limit}`,
				method: 'GET'
			}),
			providesTags: ['ProjectsList'],
		}),
		getProject: builder.query<Project, string>({
			query: (id) => ({
				url: `/${id}`,
				method: 'GET'
			}),
			providesTags: ['Project'],
		}),
		getMainProjects: builder.query<Project[], void>({
			query: () => ({
				url: '/all_main',
				method: 'GET'
			}),
			providesTags: ['MainProjects'],
		}),
		getAllProjects: builder.query<Project[], void>({
			query: () => ({
				url: '/all_projects',
				method: 'GET'
			}),
			providesTags: ['AllProjects'],
		}),
		getGanttChartProjects: builder.query<Project[], void>({
			query: () => ({
				url: '/all_gant_chart',
				method: 'GET'
			}),
			providesTags: ['GanttChartProjects'],
		}),
		getShortProjects: builder.query<Project[], void>({
			query: () => ({
				url: '/all_short_projects',
				method: 'GET'
			}),
			providesTags: ['ShortProjects'],
		}),
		getPublisherProjects: builder.query<Project[], string>({
			query: (id) => ({
				url: `/${id}/publisher_projects`,
				method: 'GET'
			}),
			providesTags: ['PublisherProjects'],
		}),
		getEmployeeProjects: builder.query<Project[], string>({
			query: (id) => ({
				url: `/${id}/all`,
				method: 'GET'
			}),
			providesTags: ['EmployeeProjects'],
		}),
		getInTestingProjects: builder.query<Project[], number>({
			query: (months) => ({
				url: `/${months}/in_testing`,
				method: 'GET'
			}),
			providesTags: ['TestingProjects'],
		}),
		getTestedProjects: builder.query<Project[], number>({
			query: (months) => ({
				url: `/${months}/tested`,
				method: 'GET'
			}),
			providesTags: ['TestedProjects'],
		}),
		getDevelopersProjectCount: builder.query<ProjectCount[], void>({
			query: () => ({
				url: '/developers_project_count',
				method: 'GET'
			}),
			providesTags: ['DevelopersProjectCount'],
		}),
		getArtistsProjectCount: builder.query<ProjectCount[], void>({
			query: () => ({
				url: '/artists_project_count',
				method: 'GET'
			}),
			providesTags: ['ArtistsProjectCount'],
		}),
		getProjectsEmployees: builder.query<ProjectEmployees[], void>({
			query: () => ({
				url: '/projects_employees',
				method: 'GET'
			}),
			providesTags: ['ProjectsEmployees'],
		}),
		techSetup: builder.mutation<Project, TechSetupData>({
			query: (techSetupData) => ({
				url: '/tech_setup',
				method: 'PUT',
				data: techSetupData,
			}),
			invalidatesTags: ['Project'],
		}),
		addGdd: builder.mutation<Project, { id: string; link: string }>({
			query: ({ id, link }) => ({
				url: '/add_gdd',
				method: 'PUT',
				data: { id, link },
			}),
			invalidatesTags: ['Project'],
		}),
		qualityApprove: builder.mutation<Project, string>({
			query: (id) => ({
				url: `/${id}/quality_approve`,
				method: 'PUT'
			}),
			invalidatesTags: ['Project'],
		}),
		mvpDone: builder.mutation<Project, { id: string; date: Date }>({
			query: ({ id, date }) => ({
				url: '/mvp_done',
				method: 'PUT',
				data: { id, date },
			}),
			invalidatesTags: ['Project'],
		}),
		releaseProject: builder.mutation<ReleasedProject, { id: string; date: Date }>({
			query: ({ id, date }) => ({
				url: '/release',
				method: 'POST',
				data: { id, date },
			}),
			invalidatesTags: ['Project'],
		}),
		sendResults: builder.mutation<Project, ProjectResultData>({
			query: (resultsData) => ({
				url: '/send_results',
				method: 'POST',
				data: resultsData,
			}),
			invalidatesTags: ['Project'],
		}),
		createProject: builder.mutation<Project, ProjectCreationData>({
			query: (creationData) => ({
				url: '/create',
				method: 'POST',
				data: creationData,
			}),
			invalidatesTags: ['ProjectsList'],
		}),
		updateProject: builder.mutation<Project, ProjectUpdatingData>({
			query: (updatingData) => ({
				url: '/update',
				method: 'PUT',
				data: updatingData,
			}),
			invalidatesTags: ['Project'],
		}),
		updateProjectSumm: builder.mutation<Project, UpdatedProjectSummData>({
			query: (updateData) => ({
				url: '/update/summ',
				method: 'PUT',
				data: updateData,
			}),
			invalidatesTags: ['Project'],
		}),
		pauseBurnRateProject: builder.mutation<Project, PauseProjectData>({
			query: (data) => ({
				url: '/pause',
				method: 'PUT',
				data,
			}),
			invalidatesTags: ['Project'],
		}),
		continueBurnRateProject: builder.mutation<Project, { id: string; date: Date }>({
			query: ({ id, date }) => ({
				url: '/continue',
				method: 'PUT',
				data: { id, date },
			}),
			invalidatesTags: ['Project'],
		}),
		deleteProject: builder.mutation<Project, string>({
			query: (id) => ({
				url: `/delete/${id}`,
				method: 'DELETE'
			}),
			invalidatesTags: ['ProjectsList', 'Project'],
		}),
	})
});

export const {
	useLoadProjectsListQuery,
	useGetProjectQuery,
	useGetMainProjectsQuery,
	useGetAllProjectsQuery,
	useGetGanttChartProjectsQuery,
	useGetShortProjectsQuery,
	useGetPublisherProjectsQuery,
	useGetEmployeeProjectsQuery,
	useGetInTestingProjectsQuery,
	useGetTestedProjectsQuery,
	useGetDevelopersProjectCountQuery,
	useGetArtistsProjectCountQuery,
	useGetProjectsEmployeesQuery,
	useTechSetupMutation,
	useAddGddMutation,
	useQualityApproveMutation,
	useMvpDoneMutation,
	useReleaseProjectMutation,
	useSendResultsMutation,
	useCreateProjectMutation,
	useUpdateProjectMutation,
	useUpdateProjectSummMutation,
	usePauseBurnRateProjectMutation,
	useContinueBurnRateProjectMutation,
	useDeleteProjectMutation
} = projectsApi;