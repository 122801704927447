import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

import UniversalTable from 'components/UniversalTable';

import {
	useArchiveContractorTypeMutation, useGetContractorTypesQuery,
	useUnarchiveContractorTypeMutation
} from '../../../../../redux/slices/financeDictionaries'
import { Button, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap'
import { FaArchive, FaEdit } from 'react-icons/fa'
import { FaArrowRotateLeft } from 'react-icons/fa6'
import { ContractorType } from '../../../../../types/finance/contractor'
import UpdateContractorTypeModal from '../Modals/UpdateContractorTypeModal'
import TableError from '../../../../../components/TableError'

const columns = [
	{
		dataField: 'order_number',
		text: '№',
		sort: false,
		width: '10%',
		className: 'text-center'
	},
	{
		dataField: 'name',
		text: 'name',
		sort: false,
		width: '70%',
		className: 'text-left',
	},
	{
		dataField: 'actions',
		text: 'actions',
		sort: false,
		width: '20%',
		className: 'text-center',
	}
];

interface ContractorTypeRowProps {
	contractorType: ContractorType;
	handlers: any;
	index: number;
}

function ContractorTypeRow({ contractorType, handlers, index }: ContractorTypeRowProps) {
	const { t, i18n } = useTranslation();

	const [showUpdate, setShowUpdate] = useState(false)

	const [archiveContractorType, unarchiveContractorType] = handlers

	const renderTooltip = (content: string) => (props: any) => (
		<Tooltip id={`tooltip-${contractorType._id}`} className={'custom-tooltip top'} {...props}>
			{content}
		</Tooltip>
	);

	return (
		<tr>
			<td className="text-center">{index + 1}</td>
			<td className="text-left">{(i18n.language === 'uk' ? contractorType.ukName : contractorType.enName)}</td>
			<td className="text-center">
				<div className="d-flex align-middle justify-content-center">
					{
						!contractorType.isArchived && (
							<>
								<UpdateContractorTypeModal
									key={contractorType.enName + Math.random()}
									contractorType={contractorType}
									show={showUpdate}
									setShow={setShowUpdate}
								/>
								<OverlayTrigger
									placement="top"
									overlay={renderTooltip(t('edit'))}
								>
									<Button
										variant="outline-primary"
										size="sm"
										className="d-flex align-items-center justify-content-center p-0 mr-2"
										style={{ width: '28px', height: '28px', borderRadius: '4px' }}
										onClick={(e) => {
											e.stopPropagation()
											setShowUpdate(true)
										}}
										aria-label={t('edit')}
									>
										<FaEdit size={18} />
									</Button>
								</OverlayTrigger>
							</>
						)
					}
					{
						contractorType.isArchived ? (
							<OverlayTrigger
								placement="top"
								overlay={renderTooltip(t('unarchive'))}
							>
								<Button
									variant="outline-success"
									size="sm"
									className="d-flex align-items-center justify-content-center p-0"
									style={{ width: '28px', height: '28px', borderRadius: '4px' }}
									onClick={(e) => {
										e.stopPropagation()
										unarchiveContractorType(contractorType._id)
									}}
									aria-label={t('unarchive')}
								>
									<FaArrowRotateLeft size={18} />
								</Button>
							</OverlayTrigger>
						) : (
							<OverlayTrigger
								placement="top"
								overlay={renderTooltip(t('archive'))}
							>
								<Button
									variant="outline-danger"
									size="sm"
									className="d-flex align-items-center justify-content-center p-0"
									style={{ width: '28px', height: '28px', borderRadius: '4px' }}
									onClick={(e) => {
										e.stopPropagation()
										archiveContractorType(contractorType._id)
									}}
									aria-label={t('archive')}
								>
									<FaArchive size={18} />
								</Button>
							</OverlayTrigger>
						)
					}
				</div>
			</td>
		</tr>
	);
}

function ContractorTypesTable() {
	const [archiveContractorType] = useArchiveContractorTypeMutation()
	const [unarchiveContractorType] = useUnarchiveContractorTypeMutation()

	const { data: contractorTypesData, isLoading, isError } = useGetContractorTypesQuery();

	if (isLoading) return <Spinner animation="grow" variant={'primary'} className="me-2" />;
	if (isError) return <TableError>Error loading data</TableError>;

	const unarchivedContractorTypesData = (contractorTypesData && contractorTypesData.filter(entry => !entry.isArchived)) || []
	const archivedContractorTypesData = (contractorTypesData && contractorTypesData.filter(entry => entry.isArchived)) || []

	const handlers = [archiveContractorType, unarchiveContractorType]

	return <>
		{
			unarchivedContractorTypesData && unarchivedContractorTypesData.length > 0 ?
				<UniversalTable
					name={'ContractorTypesTable_tableName'}
					id={'contractorsTable ContractorTypesTable_tableName'}
					columns={columns}
					data={unarchivedContractorTypesData}
					row={
						(contractorType, i) =>
							<ContractorTypeRow handlers={handlers} key={i + 'contractorTypeRow'} contractorType={contractorType} index={i}/>
					}
					scrollable
					defaultOpen={false}
				/> :
				<TableError>No contractor types data.</TableError>
		}
		{
			archivedContractorTypesData && archivedContractorTypesData.length > 0 ?
				<UniversalTable
					name={'ContractorTypesTable_tableNameArchived'}
					id={'contractorsTable ContractorTypesTable_tableNameArchived'}
					columns={columns}
					data={archivedContractorTypesData}
					row={
						(contractorType, i) =>
							<ContractorTypeRow handlers={handlers} key={i + 'contractorTypeRow'} contractorType={contractorType} index={i}/>
					}
					scrollable
					defaultOpen={false}
				/> :
				null
		}
	</>
}

export default ContractorTypesTable;
