import React, { useEffect, useState } from 'react'
import { CashFlowType } from '../../../../types/finance/cashFlowType'
import { ListItem } from '../../../../components/NestedList'
import { useLoadCashFlowTypesQuery, useUpdateCashFlowTypesMutation } from '../../../../redux/slices/financeDictionaries';
import {Spinner} from "react-bootstrap";
import TableError from '../../../../components/TableError'
import { toast } from 'react-toastify'
import NestedList2 from '../../../../components/NestedList2'
import { useTranslation } from 'react-i18next'

const parseToFlatFormat = (nestedData: ListItem[]): CashFlowType[] => {
	const flatData: CashFlowType[] = []

	const flatten = (items: ListItem[], parentId: string | null = null) => {
		items.forEach(item => {
			flatData.push({
				_id: item._id,
				parentType: parentId,
				ukName: item.ukName || '',
				enName: item.enName || '',
				isArchived: item.isArchived || false,
			})

			if (item.children && item.children.length > 0) {
				flatten(item.children, item._id)
			}
		})
	}

	flatten(nestedData)
	return flatData
}

function CashFlowTypes() {
	const { t } = useTranslation()
	const [formatedData, setFormatedData] = useState<ListItem[]>([])

	const { data: loadedCashFlowTypes, isLoading, isError } = useLoadCashFlowTypesQuery();

	const [updateCashFlowTypes] = useUpdateCashFlowTypesMutation();

	useEffect(() => {
		if (loadedCashFlowTypes) {
            setFormatedData(loadedCashFlowTypes as ListItem[]);
		}
	}, [loadedCashFlowTypes]);

	const onChange = async (data: ListItem[]) => {
		const formattedData = parseToFlatFormat(data);
		try {
			await updateCashFlowTypes(formattedData).unwrap()
			toast.success(t('cashFlow_update_success') as string)
		} catch (error) {
			toast.error('Error updating cash flow types');
		}
	};

	if (isLoading || formatedData.length === 0) return <Spinner animation="grow" variant={'primary'} className="me-2" />;
	if (isError) return <TableError>Error loading data.</TableError>;

	return <NestedList2
		key={formatedData.toString()}
		initialData={formatedData}
		onDataChange={onChange}
		useTranslations={true}
		useArchived={true}
		colorSchema={3}
	/>
}

export default CashFlowTypes