import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../api/axios.queryBuilder'
import Invoice, { AddProjectsData, InvoiceCreationData, PayInvoiceData, SendInvoiceData } from '../../types/invoice'

export const invoicesApi = createApi({
	reducerPath: 'invoicesApi',
	baseQuery: axiosBaseQuery({
		baseUrl: '/invoices',
	}),
	tagTypes: ['Invoices', 'InvoiceDetails'],
	endpoints: (builder) => ({
		loadInvoices: builder.query<Invoice[], void>({
			query: () => ({
				url: '/all',
				method: 'GET',
			}),
			providesTags: ['Invoices'],
		}),
		addInvoice: builder.mutation<Invoice, InvoiceCreationData>({
			query: (creationData) => ({
				url: '/add',
				method: 'POST',
				data: creationData,
			}),
			invalidatesTags: ['Invoices'],
		}),
		addProjectsToInvoice: builder.mutation<Invoice, AddProjectsData>({
			query: (updatedData) => ({
				url: '/add_projects',
				method: 'POST',
				data: updatedData,
			}),
			invalidatesTags: ['Invoices', 'InvoiceDetails'],
		}),
		formatInvoice: builder.mutation<Invoice, string>({
			query: (id) => ({
				url: `/${id}/format`,
				method: 'PUT',
			}),
			invalidatesTags: ['Invoices', 'InvoiceDetails'],
		}),
		payInvoice: builder.mutation<Invoice, PayInvoiceData>({
			query: (payData) => ({
				url: '/pay',
				method: 'PUT',
				data: payData,
			}),
			invalidatesTags: ['Invoices', 'InvoiceDetails'],
		}),
		sendInvoice: builder.mutation<Invoice, SendInvoiceData>({
			query: (sendInvoiceData) => ({
				url: '/send',
				method: 'PUT',
				data: sendInvoiceData,
			}),
			invalidatesTags: ['Invoices', 'InvoiceDetails'],
		}),
		deleteInvoice: builder.mutation<Invoice, { invoice_id: string; file_id: string }>({
			query: ({ invoice_id, file_id }) => ({
				url: '/delete_invoice',
				method: 'DELETE',
				data: { invoice_id, file_id },
			}),
			invalidatesTags: ['Invoices'],
		}),
		uploadInvoiceFile: builder.mutation<Invoice, { invoice_id: string; file: File }>({
			query: ({ invoice_id, file }) => {
				const formData = new FormData();
				formData.append('file', file, file.name);

				return {
					url: `/upload_invoice/${invoice_id}`,
					method: 'POST',
					data: formData,
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				};
			},
			invalidatesTags: ['Invoices', 'InvoiceDetails'],
		}),
		downloadInvoiceFile: builder.query<Blob, string>({
			query: (file_id) => ({
				url: `/download/${file_id}`,
				method: 'GET',
				responseType: 'blob', // Ensure Axios is configured for binary file responses
			}),
		}),
		deleteInvoiceFile: builder.mutation<void, { invoice_id: string; file_id?: string }>({
			query: ({ invoice_id, file_id }) => ({
				url: '/delete_invoice_file',
				method: 'DELETE',
				data: { invoice_id, file_id },
			}),
			invalidatesTags: ['Invoices', 'InvoiceDetails'],
		}),
	}),
});

export const {
	useLoadInvoicesQuery,
	useAddInvoiceMutation,
	useAddProjectsToInvoiceMutation,
	useFormatInvoiceMutation,
	usePayInvoiceMutation,
	useSendInvoiceMutation,
	useDeleteInvoiceMutation,
	useUploadInvoiceFileMutation,
	useDownloadInvoiceFileQuery,
	useDeleteInvoiceFileMutation,
} = invoicesApi;
