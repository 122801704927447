import {
	archiveIdea,
	createIdea,
	deleteIdea,
	loadAllArchivedIdeas,
	loadEmployeeIdeas,
	loadIdeas,
	unarchiveIdea
} from 'api/request'
import { toast } from 'react-toastify'
import actions from 'redux/actions'
import { t } from 'i18next'
import Idea, { IdeaCreationData } from 'types/idea'

export const loadIdeasFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.ideas.loadIdeasRequest())
	try {
		const loadedIdeas: Idea[] = await loadIdeas()
		return dispatch(actions.dataActions.ideas.loadIdeasSuccess(loadedIdeas))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.ideas.loadIdeasError())
	}
}

export const loadArchivedIdeasFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.ideas.loadArchivedIdeasRequest())
	try {
		const loadedIdeas: Idea[] = await loadAllArchivedIdeas()
		return dispatch(actions.dataActions.ideas.loadArchivedIdeasSuccess(loadedIdeas))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.ideas.loadArchivedIdeasError())
	}
}

export const loadEmployeeIdeasFunc = (id: string) => async (dispatch) => {
	dispatch(actions.dataActions.ideas.loadEmployeeIdeasRequest())
	try {
		const loadedIdeas: Idea[] = await loadEmployeeIdeas(id)
		return dispatch(actions.dataActions.ideas.loadEmployeeIdeasSuccess(loadedIdeas))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.ideas.loadEmployeeIdeasError())
	}
}

export const createIdeaFunc = (creationData: IdeaCreationData) => async (dispatch) => {
	dispatch(actions.dataActions.ideas.createIdeaRequest())
	try {
		const createdIdea: Idea = await createIdea(creationData)

		toast.success(
			t(
				creationData.is_idea
					? 'reduxToastSuccess_createIdea'
					: 'reduxToastSuccess_createNoIdea'
			) as string
		)

		return dispatch(actions.dataActions.ideas.createIdeaSuccess(createdIdea))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.ideas.createIdeaError())
	}
}

export const useIdeaFunc = (id) => async (dispatch) => {
	dispatch(actions.dataActions.ideas.useIdeaRequest())
	try {
		const usedIdea: Idea = await archiveIdea(id)
		toast.success(t('reduxToastSuccess_archiveIdea') as string)
		return dispatch(actions.dataActions.ideas.useIdeaSuccess(usedIdea))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.ideas.useIdeaError())
	}
}

export const archiveIdeaFunc = (id) => async (dispatch) => {
	dispatch(actions.dataActions.ideas.archiveIdeaRequest())
	try {
		const archivedIdea: Idea = await archiveIdea(id)
		toast.success(t('reduxToastSuccess_archiveIdea') as string)
		return dispatch(actions.dataActions.ideas.archiveIdeaSuccess(archivedIdea))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.ideas.archiveIdeaError())
	}
}

export const unarchiveIdeaFunc = (id) => async (dispatch) => {
	dispatch(actions.dataActions.ideas.unarchiveIdeaRequest())
	try {
		const unarchivedIdea: Idea = await unarchiveIdea(id)
		toast.success(t('reduxToastSuccess_unarchiveIdea') as string)
		return dispatch(actions.dataActions.ideas.unarchiveIdeaSuccess(unarchivedIdea))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.ideas.unarchiveIdeaError())
	}
}

export const deleteIdeaFunc = (id) => async (dispatch) => {
	dispatch(actions.dataActions.ideas.deleteIdeaRequest())
	try {
		const deletedIdea: Idea = await deleteIdea(id)
		toast.success(t('reduxToastSuccess_deleteIdea') as string)
		return dispatch(actions.dataActions.ideas.deleteIdeaSuccess(deletedIdea))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.ideas.deleteIdeaError())
	}
}
