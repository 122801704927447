import React from 'react'
import { useTranslation } from 'react-i18next'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Routes from './routes'
import useSetPageTitle from './hooks/useSetPageTitle'
import { SidebarProvider } from './components/Contexts/SidebarContext'

const App = () => {
	const { t } = useTranslation()

	useSetPageTitle(t('ASYA'))

	return (
		<SidebarProvider>
			<Routes />
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={true}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				pauseOnHover
				theme="colored"
			/>
		</SidebarProvider>
	)
}

export default App
