import { Card, Col, Row, Spinner } from 'react-bootstrap'
import React from 'react'
import CurrencyRatesTable from './Tables/CurrencyRatesTable'
import { useLoadCurrenciesForTodayQuery } from '../../../../redux/slices/financeDictionaries';
import { useTranslation } from 'react-i18next'
import Moment from 'react-moment'

interface CurrencyCardProps {
	rate: string,
	name: string,
}

function CurrencyCard({rate, name}: CurrencyCardProps) {
	return (
		<Col>
			<Card className="flex-fill">
				<Card.Body className="py-4">
					<Col xs="auto">
						<h3 className="mb-2">
							{name}
						</h3>
					</Col>
					<Col xs="auto">
						<div className="mb-0">{rate}</div>
					</Col>
				</Card.Body>
			</Card>
		</Col>
	)
}

function CurrencyRates() {
	const { t } = useTranslation()

	const { data: currencyRatesForToday = [], isLoading: isTodayLoading, isError: isTodayError } = useLoadCurrenciesForTodayQuery();

	if (isTodayLoading) {
		return <Spinner animation="grow" variant={'primary'} className="me-2" />;
	}

	if (isTodayError) {
		return <div className='m-4'>Error loading data</div>;
	}

	return (
		<>
			<Row>
				<Col className='d-flex'>
					{
						currencyRatesForToday && currencyRatesForToday.length > 0 ? currencyRatesForToday.map(currency => (
							<CurrencyCard key={currency.name} rate={currency.rate.toFixed(4)} name={currency.name} />
						)) : <div className='m-4'>No currency data.</div>
					}
				</Col>
				<Col className='d-flex justify-content-end'>
					<div className="mb-2 text-end mr-2">
						{t('currency_rates_for_today')}{': '}
						<Moment format="DD.MM.YYYY" locale={t('locales')} date={new Date()} />
					</div>
				</Col>
			</Row>
			<CurrencyRatesTable />
		</>
	)
}

export default CurrencyRates