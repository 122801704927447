import React, { useEffect } from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import { uk, ru, enUS as en } from 'date-fns/locale'

import 'react-datepicker/dist/react-datepicker.css'
import { useTranslation } from 'react-i18next'

const localizationSettings = { en: { ...en, options: { ...en.options, weekStartsOn: 1 } }, uk, ru }

export default function RangeCalendar(props: {
	label?: string
	startDate: Date
	endDate?: Date
	showTimeSelect?: boolean
	withSelect?: boolean
	withFullWidth?: boolean
	onChange: ((date: [Date | null, Date | null], event?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement> | undefined) => void) | undefined
	validDate?: ((selectedDate: Date) => boolean) | undefined
}) {
	const { t, i18n } = useTranslation()

	useEffect(() => {
		registerLocale(i18n.language.toString(), localizationSettings[i18n.language])
	}, [i18n.language])

	const customClass = props.withFullWidth ? 'full-width' : ''

	return (
		<div className={`text-center w-100 ${customClass}`}>
			{props.label && <label>{t(props.label)} </label>}
			<DatePicker
				className='range-calendar text-center'
				locale={i18n.language}
				selected={props.startDate}
				onChange={props.onChange}
				startDate={props.startDate}
				endDate={props.endDate}
				excludeDates={[]}
				showTimeSelect={props.showTimeSelect}
				filterDate={props.validDate}
				selectsRange
				inline={!props.withSelect}
				dateFormat="dd.MM.yyyy"
			 	showMonthYearDropdown={undefined}
			/>
		</div>
	)
}
