import React from 'react'
import { Badge } from 'react-bootstrap'
import { connect } from 'react-redux'
import { StoreType } from 'redux/store'
import { ProjectTypeEnum, ShortProject } from 'types/project'

const ProjectTypeBadge = (props: { project: ShortProject; className?: string }) => {
	if (!props.project) return null

	const className = `badge-pill mr-1 ${props.className ?? ''}`

	switch (props.project.project_type) {
		case ProjectTypeEnum.ctr:
			return <Badge color="primary" className={className} children={ProjectTypeEnum.ctr} />
		case ProjectTypeEnum.prototype:
			return (
				<Badge
					bg="success"
					pill
					className={className}
					children={ProjectTypeEnum.prototype}
				/>
			)
		case ProjectTypeEnum.playable:
			return (
				<Badge
					bg="warning"
					pill
					className={className}
					children={ProjectTypeEnum.playable}
				/>
			)

		case ProjectTypeEnum.subtask:
			return <Badge color="info" className={className} children={ProjectTypeEnum.subtask} />
		default:
			return null
	}
}

export default connect((store: StoreType) => ({
	data: store.data
}))(ProjectTypeBadge)
