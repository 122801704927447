import { toast } from 'react-toastify'
import actions from 'redux/actions'
import { getCurrentSurvey, sendAnswers } from 'api/request'
import { t } from 'i18next'
import {
	NoSurvey,
	PartySurvey,
	ProjectSurvey,
	SendAnswersData,
	Survey,
	SurveyState,
	SurveyTypeEnum
} from 'types/survey'

export const loadSurveyFunc = () => async (dispatch) => {
	dispatch(actions.userActions.loadSurveyRequest())
	try {
		const survey: SurveyState | NoSurvey = await getCurrentSurvey()
		return dispatch(actions.userActions.loadSurveySuccess(survey))
	} catch (error: any) {
		console.log(error)
		toast.error('Помилка завантаження опитування')
		return dispatch(actions.userActions.loadSurveyError())
	}
}

export const sendAnswerFunc = (answersData: SendAnswersData) => async (dispatch) => {
	dispatch(actions.userActions.sendAnswerRequest())
	try {
		const survey: Survey | PartySurvey | ProjectSurvey = await sendAnswers(answersData)
		toast.success(t('SurveyPage_gratitude') as string)
		if (survey.type === SurveyTypeEnum.projects) {
			dispatch(actions.dataActions.surveys.loadProjectSurveySuccess(survey as ProjectSurvey))
		}
		return dispatch(actions.userActions.sendAnswerSuccess())
	} catch (error: any) {
		console.log(error)
		toast.error('Помилка надсилання відповідей')
		return dispatch(actions.userActions.sendAnswerError())
	}
}
