import { createAction } from '@reduxjs/toolkit'
import { DoneSurveysPayload, PartySurvey, ProjectSurvey, Survey } from 'types/survey'
import * as types from '../../constants'

export const loadProjectSurveyRequest = createAction(types.LOAD_PROJECT_SURVEY_REQUEST)
export const loadProjectSurveySuccess = createAction<ProjectSurvey>(
	types.LOAD_PROJECT_SURVEY_SUCCESS
)
export const loadProjectSurveyError = createAction(types.LOAD_PROJECT_SURVEY_ERROR)

export const loadProjectSurveysRequest = createAction(types.LOAD_PROJECT_SURVEYS_REQUEST)
export const loadProjectSurveysSuccess = createAction<DoneSurveysPayload>(
	types.LOAD_PROJECT_SURVEYS_SUCCESS
)
export const loadProjectSurveysError = createAction(types.LOAD_PROJECT_SURVEYS_ERROR)

export const loadEmployeeProjectSurveysRequest = createAction(
	types.LOAD_EMPLOYEE_PROJECT_SURVEYS_REQUEST
)
export const loadEmployeeProjectSurveysSuccess = createAction<ProjectSurvey[]>(
	types.LOAD_EMPLOYEE_PROJECT_SURVEYS_SUCCESS
)
export const loadEmployeeProjectSurveysError = createAction(
	types.LOAD_EMPLOYEE_PROJECT_SURVEYS_ERROR
)

export const loadWeeklyMonthlySurveysRequest = createAction(
	types.LOAD_WEEKLY_MONTHLY_SURVEYS_REQUEST
)
export const loadWeeklyMonthlySurveysSuccess = createAction<Survey[]>(
	types.LOAD_WEEKLY_MONTHLY_SURVEYS_SUCCESS
)
export const loadWeeklyMonthlySurveysError = createAction(types.LOAD_WEEKLY_MONTHLY_SURVEYS_ERROR)

export const loadPartySurveysRequest = createAction(types.LOAD_PARTY_SURVEYS_REQUEST)
export const loadPartySurveysSuccess = createAction<PartySurvey[]>(types.LOAD_PARTY_SURVEYS_SUCCESS)
export const loadPartySurveysError = createAction(types.LOAD_PARTY_SURVEYS_ERROR)
