import React from 'react'
import { useTranslation } from 'react-i18next'
import DateTime from 'react-datetime'
import moment from 'moment'
import './styles.css'

const Calendar = (props: {
	label?: string
	initialValue?: Date
	value?: Date
	with_time?: boolean
	validDate?: ((currentDate: any, selectedDate: any) => boolean) | undefined
	error?: boolean
	format?: string
	onChange?: (date: Date) => void
	position?: 'bottom' | 'center' | 'top'
	horizontalPosition?: 'left' | 'right' | 'default'
}) => {
	const { t } = useTranslation()

	const setDate = (m) => {
		if (props.onChange !== undefined && typeof m !== 'string') props.onChange(m.toDate())
	}

	const styleAtPosition = (position: 'bottom' | 'center' | 'top') => {
		switch (position) {
			case 'top':
				return {
					bottom: 0
				}
			case 'bottom':
				return {
					top: '3em'
				}
			case 'center':
				return {
					transform: 'translate(50%, 0)'
				}
		}
	}

	const styleAtHorizontalPosition = (position: 'left' | 'right' | 'default') => {
		switch (position) {
			case 'left':
				return {
					left: '-4.7em'
				}
			case 'right':
				return {
					right: '4.7em'
				}
			case 'default':
				return
		}
	}

	return (
		<>
			{props.label && <label>{t(props.label)}</label>}
			<DateTime
				initialValue={props.initialValue ? props.initialValue : new Date()}
				locale={moment.locale(t('locales'))}
				value={props.value}
				{...(props.validDate && { isValidDate: props.validDate })}
				dateFormat={props.format ?? 'D MMM YYYY'}
				timeFormat={props.with_time ? 'hh:mm' : false}
				onChange={setDate}
				closeOnSelect={true}
				renderView={(vm, rc) => {
					return (
						<div
							style={{
								...styleAtPosition(props.position ?? 'bottom'),
								...styleAtHorizontalPosition(props.horizontalPosition ?? 'default'),
								boxShadow: '5px 10px 15pt 2pt #00000040',
								borderRadius: 5,
								position: 'absolute',
								backgroundColor: 'white',
								minWidth: '200px'
							}}
						>
							{rc()}
						</div>
					)
				}}
			/>
		</>
	)
}

export default Calendar
