import {
	createVacation,
	deleteVacation,
	createVacationPeriod,
	deleteVacationPeriod,
	getAllVacations,
	getAllVacationsPeriods,
	updateVacation,
	rejectVacation,
	getTodayVacations,
	getEmployeeVacations,
	getTwoMonthVacations,
	resolveConflictedVacations
} from 'api/request'

import { toast } from 'react-toastify'
import actions from 'redux/actions'
import { t } from 'i18next'
import Vacation, {
	VacationCreationData,
	VacationLocedPeriodCreationData,
	VacationPeriodInfo,
	VacationRejectingData,
	VacationStatusEnum,
	VacationUpdatingData
} from 'types/vacation'
import VacationLockedPeriods from 'types/vacation.locked.periods'

export const loadVacationsFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.vacations.loadVacationsRequest())
	try {
		const loadedVacations: Vacation[] = await getAllVacations()
		return dispatch(actions.dataActions.vacations.loadVacationsSuccess(loadedVacations))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.vacations.loadVacationsError())
	}
}

export const loadEmployeeVacationsFunc = (id: string) => async (dispatch) => {
	dispatch(actions.dataActions.vacations.loadEmployeeVacationsRequest())
	try {
		const loadedVacations: Vacation[] = await getEmployeeVacations(id)
		return dispatch(actions.dataActions.vacations.loadEmployeeVacationsSuccess(loadedVacations))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.vacations.loadEmployeeVacationsError())
	}
}

export const loadTwoMonthVacationsFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.vacations.loadVacationsRequest())
	try {
		const loadedVacations: Vacation[] = await getTwoMonthVacations()
		return dispatch(actions.dataActions.vacations.loadVacationsSuccess(loadedVacations))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.vacations.loadVacationsError())
	}
}

export const loadTodayVacationsFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.vacations.loadTodayVacationsRequest())
	try {
		const loadedVacations: Vacation[] = await getTodayVacations()
		return dispatch(actions.dataActions.vacations.loadTodayVacationsSuccess(loadedVacations))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.vacations.loadTodayVacationsError())
	}
}

export const createVacationFunc = (creationData: VacationCreationData) => async (dispatch) => {
	dispatch(actions.dataActions.vacations.createVacationRequest())
	try {
		const createdVacation: Vacation = await createVacation(creationData)

		if (createdVacation.status === VacationStatusEnum.requested) {
			toast.success(t('reduxToastSuccess_vacationRequestCreated') as string)
		} else {
			toast.success(t('reduxToastSuccess_vacationCreated') as string)
		}

		return dispatch(actions.dataActions.vacations.createVacationSuccess(createdVacation))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.vacations.createVacationError())
	}
}

export const resolveConflictedVacationsFunc =
	(vacation_info: VacationPeriodInfo) => async (dispatch) => {
		dispatch(actions.dataActions.vacations.resolveConflictedVacationsRequest())
		try {
			const resolvedVacations: Vacation[] = await resolveConflictedVacations(vacation_info)

			toast.success(t('reduxToastSuccess_vacationConflictResolved') as string)

			return dispatch(
				actions.dataActions.vacations.resolveConflictedVacationsSuccess(resolvedVacations)
			)
		} catch (error: any) {
			toast.error(error.response.data.message)
			return dispatch(actions.dataActions.vacations.resolveConflictedVacationsError())
		}
	}

export const updateVacationFunc = (updatingData: VacationUpdatingData) => async (dispatch) => {
	dispatch(actions.dataActions.vacations.updateVacationRequest())
	try {
		const updatedVacation: Vacation = await updateVacation(updatingData)

		if (updatedVacation.status === VacationStatusEnum.agreed) {
			toast.success(t('reduxToastSuccess_vacationAgreed') as string)
		} else if (updatedVacation.status === VacationStatusEnum.rejected) {
			toast.success(t('reduxToastSuccess_vacationRejected') as string)
		} else {
			toast.success(t('reduxToastSuccess_vacationRequestCreated') as string)
		}

		return dispatch(actions.dataActions.vacations.updateVacationSuccess(updatedVacation))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.vacations.updateVacationError())
	}
}

export const rejectVacationFunc = (rejectingData: VacationRejectingData) => async (dispatch) => {
	dispatch(actions.dataActions.vacations.updateVacationRequest())
	try {
		const updatedVacation: Vacation = await rejectVacation(rejectingData)

		toast.success(t('reduxToastSuccess_vacationRequestRejected') as string)

		return dispatch(actions.dataActions.vacations.updateVacationSuccess(updatedVacation))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.vacations.updateVacationError())
	}
}

export const deleteVacationFunc = (id: string) => async (dispatch) => {
	dispatch(actions.dataActions.vacations.deleteVacationRequest())
	try {
		const deletedVacation: Vacation = await deleteVacation(id)
		toast.success(t('reduxToastSuccess_vacationDeleted') as string)
		return dispatch(actions.dataActions.vacations.deleteVacationSuccess(deletedVacation))
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.vacations.deleteVacationError())
	}
}

export const loadVacationPeriodsFunc = () => async (dispatch) => {
	dispatch(actions.dataActions.vacations.loadVacationPeriodsRequest())
	try {
		const loadedVacationPeriod: VacationLockedPeriods[] = await getAllVacationsPeriods()
		return dispatch(
			actions.dataActions.vacations.loadVacationPeriodsSuccess(loadedVacationPeriod)
		)
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.vacations.loadVacationPeriodsError())
	}
}

export const createVacationPeriodFunc =
	(creationData: VacationLocedPeriodCreationData) => async (dispatch) => {
		dispatch(actions.dataActions.vacations.createVacationPeriodRequest())
		try {
			const createdVacationPeriod: VacationLockedPeriods = await createVacationPeriod(
				creationData
			)

			toast.success(t('reduxToastSuccess_vacationLockPeriodCreated') as string)
			return dispatch(
				actions.dataActions.vacations.createVacationPeriodSuccess(createdVacationPeriod)
			)
		} catch (error: any) {
			toast.error(error.response.data.message)
			return dispatch(actions.dataActions.vacations.createVacationPeriodError())
		}
	}

export const deleteVacationPeriodFunc = (id) => async (dispatch) => {
	dispatch(actions.dataActions.vacations.deleteVacationPeriodRequest())
	try {
		const deletedVacationPeriod: VacationLockedPeriods = await deleteVacationPeriod(id)
		toast.success(t('reduxToastSuccess_vacationLockPeriodDeleted') as string)
		return dispatch(
			actions.dataActions.vacations.deleteVacationPeriodSuccess(deletedVacationPeriod)
		)
	} catch (error: any) {
		toast.error(error.response.data.message)
		return dispatch(actions.dataActions.vacations.deleteVacationPeriodError())
	}
}
