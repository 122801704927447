import { updateProjectSumm, getFinanceByMonth, recalculateFinance } from 'api/request'

import { toast } from 'react-toastify'
import actions from 'redux/actions'
import { t } from 'i18next'
import Project, { UpdatedProjectSummData } from 'types/project'
import FinanceCollection from 'types/finance'

export const loadFinanceCollection = (month: string) => async (dispatch) => {
	dispatch(actions.dataActions.finance.getFinanceCollectionRequest())
	try {
		const financeCollection: FinanceCollection = await getFinanceByMonth(month)
		dispatch(actions.dataActions.finance.getFinanceCollectionSuccess(financeCollection))
	} catch (error: any) {
		//const message = t('reduxToastError_loadFinanceCollection')
		toast.error(error.response.data.message)
		dispatch(actions.dataActions.finance.getFinanceCollectionError())
	}
}

export const updateStoreProjectsSumm =
	(newProjectData: UpdatedProjectSummData, name: string) => async (dispatch) => {
		dispatch(actions.dataActions.finance.updateProjectSummRequest())

		try {
			const updatedProject: Project = await updateProjectSumm(newProjectData)
			const message = t('reduxToastSuccess_updateStoreProjectsSumm', { name: name })
			toast.success( message)
			return dispatch(actions.dataActions.finance.updateProjectSummSuccess(updatedProject))
		} catch (error) {
			const message = t('reduxToastError_updateStoreProjectsSumm', { name: name })
			toast.error(message)
			return dispatch(actions.dataActions.finance.updateProjectSummError())
		}
	}

export const recalculateFinanceCollections = () => async (dispatch) => {
	dispatch(actions.dataActions.finance.recalculateFinanceRequest())
	try {
		const financeCollections: FinanceCollection = await recalculateFinance()
		toast.warning('Результат перерахунку буде доступний приблизно через 3-5 хвилин після перезавантаження сторінки')
		dispatch(actions.dataActions.finance.recalculateFinanceSuccess(financeCollections))
	} catch (error: any) {
		console.log(error)
		toast.error('Помилка у перерахунку окупності')
		dispatch(actions.dataActions.finance.recalculateFinanceError())
	}
}
