import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface SidebarItemProps {
    name: string;
    icon?: React.ComponentType<{ size: number; className: string }>;
    to: string;
    parentPath?: string;
    external_link?: boolean;
    isActive: boolean;
    onClick?: () => void;
}

export const SidebarItem: React.FC<SidebarItemProps> = React.memo(({
                                                                       name,
                                                                       icon: Icon,
                                                                       to,
                                                                       parentPath,
                                                                       external_link,
                                                                       isActive,
                                                                       onClick
                                                                   }) => {
    const { t } = useTranslation();

    const handleClick = (e: React.MouseEvent) => {
        if (external_link) {
            e.preventDefault();
        }
        onClick?.();
    };

    if (external_link) {
        return (
            <li className="sidebar-item">
                <a href={to} className="sidebar-link" target="_blank" rel="noopener noreferrer">
                    {Icon && <Icon size={18} className="align-middle mr-3" />}
                    {t(name)}
                </a>
            </li>
        );
    }

    return (
        <li className={`sidebar-item ${isActive ? 'active' : ''}`}>
            <NavLink
                to={parentPath ? `${parentPath}${to === '/' ? '' : `/${to}`}` : to}
                className={({ isActive }) => `sidebar-link ${isActive ? 'active' : ''}`}
                onClick={handleClick}
            >
                {Icon && <Icon size={18} className="align-middle mr-3" />}
                <span>{t(name)}</span>
            </NavLink>
        </li>
    );
});

