import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import selectors from 'redux/selectors'
import Sickness, { SicknessTypeEnum } from 'types/sickness'
import Vacation, { VacationStatusEnum, VacationTypeEnum } from 'types/vacation'
import moment from 'moment'
import { FaHome, FaThermometerThreeQuarters, FaUmbrellaBeach, FaUserTimes } from 'react-icons/fa'
import { MdHomeWork } from 'react-icons/md'

const UserAvailabilityStatus = (props: { employee_id?: string; today?: boolean }) => {
	const [availabilityType, setAvailabilityType] = useState<string | null>(null)

	const sicknesses: Sickness[] = useSelector(
		props.today ? selectors.getTodaySicknesses : selectors.getSicknesses
	)
	const vacations: Vacation[] = useSelector(
		props.today ? selectors.getTodayVacations : selectors.getVacations
	)

	useEffect(() => {
		if (!props.employee_id) return

		if (sicknesses) {
			const sickness = sicknesses.find(
				(sickness: Sickness) =>
					sickness.employee._id === props.employee_id &&
					moment(new Date()).isBetween(
						sickness.start_date,
						sickness.end_date ?? new Date(),
						'days',
						'[]'
					)
			)

			if (sickness) {
				setAvailabilityType(sickness.type)
			}
		}

		if (vacations) {
			const vacation = vacations.find(
				(vacation: Vacation) =>
					vacation.employee._id === props.employee_id &&
					vacation.status === VacationStatusEnum.agreed &&
					!vacation.refund &&
					moment(new Date()).isBetween(
						vacation.start_date,
						vacation.end_date,
						'days',
						'[]'
					)
			)

			if (vacation) {
				setAvailabilityType(VacationTypeEnum.inVacation)
			}
		}
	}, [props.employee_id, sicknesses, vacations])

	switch (availabilityType) {
		case SicknessTypeEnum.worksRemotely:
			return <FaHome className="text-primary mr-1" />
		case SicknessTypeEnum.doesntWork:
			return <FaUserTimes className="text-danger mr-1" />
		case SicknessTypeEnum.sickWork:
			return <MdHomeWork className="text-warning mr-1" />
		case SicknessTypeEnum.sick:
			return <FaThermometerThreeQuarters className="text-danger mr-1" />
		case VacationTypeEnum.inVacation:
			return <FaUmbrellaBeach className="text-primary mr-1" />

		default:
			return <span></span>
	}
}

export default UserAvailabilityStatus
